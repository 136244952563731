import { Box, Modal as MUIModal } from '@material-ui/core';
import React, { useCallback } from 'react';
import { BadgeDetailed } from 'src/entity/badge';
import CheckEligibilitiesModal from './check-eligibilities-modal';
import CheckEligibilityModal from './check-eligibility-modal';
import DownloadAssetModal from './download-asset-modal';
import MigrateModal from './migrate-modal';
import NotEligibleModal from './not-eligible-modal';
import SignUpSuccessAlertModal from './sign-up-success-alert-modal';
import TxErrorModal from './tx-error-modal';
import TxFeeErrorModal from './tx-fee-error-modal';
import TxSucceedModal from './tx-succeed-modal';
import UpgradeModal from './upgrade-modal';

export enum ALERT_MODAL_TYPE {
  SignUpSuccess,
  CheckEligibility,
  CheckEligibilities,
  NotEligible,
  TxSucceed,
  TxFeeError,
  TxError,
  Migrate,
  Upgrade,
  DownloadAsset,
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'var(--P900)',
  boxShadow: 24,
  p: 4,
  borderRadius: 32,
  padding: 40,
  outline: 'none',
  maxWidth: 520,
};

const AlertModal: React.FC<{
  modalType: ALERT_MODAL_TYPE;
  params: unknown;
  isOpen: boolean;
  handleClose: any;
  handleSubmit: any;
}> = ({ isOpen, params, modalType, handleClose, handleSubmit }) => {
  const renderStyle = useCallback((modalType: ALERT_MODAL_TYPE) => {
    switch (modalType) {
      case ALERT_MODAL_TYPE.SignUpSuccess:
      case ALERT_MODAL_TYPE.CheckEligibility:
      case ALERT_MODAL_TYPE.CheckEligibilities:
      case ALERT_MODAL_TYPE.NotEligible:
      case ALERT_MODAL_TYPE.TxFeeError:
      case ALERT_MODAL_TYPE.TxError:
      case ALERT_MODAL_TYPE.Migrate:
      case ALERT_MODAL_TYPE.Upgrade:
      case ALERT_MODAL_TYPE.DownloadAsset:
        return style;
      case ALERT_MODAL_TYPE.TxSucceed:
        return {
          ...style,
          maxWidth: 571,
        };
    }
  }, []);

  const renderBody = useCallback(
    (modalType: ALERT_MODAL_TYPE) => {
      switch (modalType) {
        case ALERT_MODAL_TYPE.SignUpSuccess: {
          return (
            <SignUpSuccessAlertModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.CheckEligibility: {
          const badge = (params as { badge: BadgeDetailed }).badge;
          return (
            <CheckEligibilityModal
              badge={badge}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.CheckEligibilities: {
          return (
            <CheckEligibilitiesModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.NotEligible: {
          return (
            <NotEligibleModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.TxSucceed: {
          return (
            <TxSucceedModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.TxFeeError: {
          return (
            <TxFeeErrorModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.TxError: {
          const _params = params as {
            errorMessage: string;
          };
          return (
            <TxErrorModal
              errorMessage={_params.errorMessage}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.Migrate: {
          const badge = (params as { badge: BadgeDetailed }).badge;
          return (
            <MigrateModal
              badge={badge}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.Upgrade: {
          const badge = (params as { badge: BadgeDetailed }).badge;
          return (
            <UpgradeModal
              badge={badge}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case ALERT_MODAL_TYPE.DownloadAsset: {
          return (
            <DownloadAssetModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
      }
    },
    [params, handleSubmit, handleClose],
  );
  return (
    <>
      <MUIModal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={renderStyle(modalType) as any}>{renderBody(modalType)}</Box>
      </MUIModal>
      <style jsx>{`
        div {
        }
      `}</style>
    </>
  );
};

export default AlertModal;
