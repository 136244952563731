import React, { useContext } from 'react';
import SubmitSection from 'src/components/user-badge/create/submit-section';
import {
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
  PageType,
} from 'src/hooks/use-create-badge-state';
import CloseIconButton from '../close-icon-button';
import InputV2, { defaultInputError, InputError } from '../input-v2';

const ContactModal: React.FC<{
  handleClose: () => void;
  handleSubmit: (contract: string, tokenId: string) => void;
}> = ({ handleClose = () => {}, handleSubmit }) => {
  const [state, setState, error, setError, , , pageType] = useContext(
    CreateBadgeStateContext,
  );

  const isViewPage = pageType === PageType.VIEW;

  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h7-medium P200">Contact</p>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <p className="desc b2-regular G25">
          Please share your email address and Discord handle. Make sure to join
          Noox Discord for better communication. We will reach out if we have
          any questions.
        </p>
        <div className="body scroll">
          <div className="input">
            <InputV2
              name="Discord Handle"
              value={state.discordHandle || ''}
              setValue={(val) => {
                setState((state) => ({
                  ...state,
                  discordHandle: val,
                }));
              }}
              error={error.discordHandle as InputError}
              setError={(val: string) => {
                setError((state) => ({
                  ...state,
                  title: val
                    ? (CreateBadgeStateValidation.discordHandle as any)[val]
                    : defaultInputError,
                }));
              }}
              isRequired={true}
              placeholder="nooxboy#0000"
              errorArr={CreateBadgeStateValidation.discordHandle}
              disabled={isViewPage}
            />
          </div>
          <div className="input">
            <InputV2
              name="E-mail"
              value={state.email || ''}
              setValue={(val) => {
                setState((state) => ({
                  ...state,
                  email: val,
                }));
              }}
              placeholder="nooxboy@noox.world"
              disabled={isViewPage}
            />
          </div>
        </div>
        {/* TODO: Default Value isEdit에 넘겨주기 */}
        <SubmitSection isEdit={pageType === PageType.EDIT} />
      </div>
      <style jsx>{`
        .container {
          width: 720px;
          background: var(--G900);
        }
        .header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 25px;
        }
        div.body {
          max-height: 520px;
        }

        p.desc {
          margin-bottom: 20px;
        }

        .input {
          margin-bottom: 20px;
        }
      `}</style>
    </>
  );
};

export default ContactModal;
