import React from 'react';
import { UsersAPI } from 'src/apis/users-api';
import useModal from 'src/hooks/use-modal';
import { mutate } from 'swr';
import { Interest as InterestComponent } from '..';
import { Interest } from '../../entity/interest';
import Chip, { ChipSize, ChipStyle } from '../common/chip';
import { MODAL_TYPE } from '../common/modal/modal';

interface Props {
  interestList: Interest[];
  isMine: boolean;
}

const InterestList: React.FC<Props> = ({
  interestList = [],
  isMine = false,
}) => {
  const [renderModal, handleOpen] = useModal(
    MODAL_TYPE.SelectInterest,
    async (interests: number[]) => {
      await new UsersAPI().updateProfile({
        interests,
      });
      await mutate(UsersAPI.SWR_PATH.GET_PROFILE());
    },
  );

  return (
    <>
      {renderModal}
      <div className="interest-list">
        {interestList.length === 0 ? (
          <div>
            {isMine && (
              <Chip
                label={'+ Add your interest'}
                chipStyle={ChipStyle.DASH_LITE}
                chipSize={ChipSize.S}
                handleClick={() => {
                  handleOpen(null);
                }}
              />
            )}
          </div>
        ) : (
          <div className="body">
            {interestList.map((item) => {
              return (
                <div key={item.dataId} className="item">
                  <InterestComponent interest={item} />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <style jsx>{`
        .interest-list {
          width: 100%;
        }
        .interest-list > .body {
          display: flex;
          gap: 8px 8px;
          flex-wrap: wrap;
        }
      `}</style>
    </>
  );
};

export default InterestList;
