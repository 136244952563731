import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { ROUTE } from 'src/apis/const';
import useConnectWallet from 'src/hooks/use-connect-wallet';
import useUser from 'src/hooks/use-user';
import Logo from '../../../public/image/logo.png';
import Button, { ButtonSize, ButtonStyle } from './button';
import NavbarProfileTab from './navbar-profile-tab';

const Navbar: React.FC = () => {
  const router = useRouter();
  const [user] = useUser();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const [renderModal, handleConnectWalletOpen, loading] = useConnectWallet();
  return (
    <>
      {renderModal()}
      <div className="wrap">
        <div style={{ cursor: 'pointer' }} className="left">
          <div
            onClick={() => {
              router.push(ROUTE.HOME);
            }}
          >
            <Image
              src={Logo}
              alt="Noox logo"
              width={500 / 5}
              height={128 / 5}
              className="logo"
            />
          </div>
        </div>
        <div className="right">
          <ul>
            <>
              {/* <li
                className={`b1-medium nav ${
                  router.pathname.startsWith(ROUTE.PROJECTS) ? 'WT100' : 'WT30'
                }`}
                onClick={() => {
                  router.push(ROUTE.PROJECTS);
                }}
              >
                Projects
                <span className="new"></span>
              </li> */}

              <li
                className={`b1-medium nav ${
                  router.pathname.startsWith(ROUTE.BADGE) ||
                  router.pathname.startsWith(ROUTE.COLLECTION)
                    ? 'WT100'
                    : 'WT30'
                }`}
                onClick={() => {
                  router.push(ROUTE.BADGE);
                }}
              >
                Explore
              </li>
              {/* <li
                className={`b1-medium nav ${
                  router.pathname.startsWith(ROUTE.BROADCASTERS)
                    ? 'WT100'
                    : 'WT30'
                }`}
                onClick={() => {
                  router.push(ROUTE.BROADCASTERS);
                }}
              >
                <span className="badge">Alpha</span>
                Broadcaster
              </li> */}
              {/* <li
                className={`b1-medium nav ${
                  router.pathname.startsWith(ROUTE.CLAIM) ? 'WT100' : 'WT30'
                }`}
                onClick={() => {
                  router.push(ROUTE.CLAIM);
                }}
              >
                Claim
              </li> */}
              {/* <li
                className={`b1-medium nav create-badge ${
                  router.pathname.startsWith(ROUTE.USER_BADGE)
                    ? 'WT100 active'
                    : 'WT30'
                }`}
                onClick={() => {
                  router.push(ROUTE.USER_BADGE);
                }}
              >
                <span>alpha</span>
                Create Badge
              </li> */}
            </>

            <li>
              {user ? (
                <NavbarProfileTab
                  user={user}
                  logOut={() => {
                    router.reload();
                  }}
                />
              ) : (
                <Button
                  label="Connect Wallet"
                  buttonStyle={ButtonStyle.NEUTRAL}
                  buttonSize={ButtonSize.S}
                  handleClick={() => handleConnectWalletOpen(null)}
                  style={{
                    lineHeight: '2.0rem!important',
                    width: 171,
                  }}
                  loading={loading}
                />
              )}
            </li>
          </ul>
        </div>
      </div>
      <style jsx>
        {`
          * {
            box-sizing: content-box;
          }
          .logo {
            height: auto;
            cursor: pointer;
          }
          .wrap {
            width: calc(100vw - 120px);
            padding: 0px 60px 40px;
            display: flex;
            justify-content: space-between;
            height: 80px;
            align-items: center;
            margin: 0 auto;
          }
          .left {
          }
          .right > ul {
            display: flex;
          }
          .right > ul > li {
            margin-left: 28px;
            display: flex;
            align-items: center;
            cursor: pointer;
            z-index: 1;
            position: relative;
          }
          .right > ul > li.nav.create-badge {
            background: none;
            padding: 4.5px 20px;
            border: 1px solid var(--NEW-P500);
            border-radius: 30px;
          }
          .right > ul > li.nav.create-badge.active {
            background: var(--G900);
          }
          .right > ul > li.nav.create-badge:hover {
            background: var(--NEW-P700);
            color: var(--WT100);
          }

          .right > ul > li.nav:hover {
            color: var(--P500);
          }
          .right > ul > li:last-of-type {
            margin-left: 28px;
          }
          .right > ul > li > span.new {
            width: 4px;
            height: 4px;
            background: var(--R);
            border-radius: 100%;
            position: absolute;
            right: -4px;
            top: 7px;
          }
          .right > ul > li.nav span.badge {
            position: absolute;
            font-size: 1rem;
            line-height: 1.1;
            top: -5px;
            right: -11px;
            background: var(--NEW-P500);
            border-radius: 20px;
            padding: 1px 4px;
            color: var(--WT100);
          }
          .not-allowed {
            cursor: not-allowed;
          }

          @media (max-width: 850px) {
            .wrap {
              width: 100vw;
              padding: 0 0 40px;
              margin: 0;
              justify-content: center;
            }
            .right {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default Navbar;
