import React from 'react';
import Button, { ButtonSize, ButtonStyle } from '../button';

const NotEligibleModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose }) => {
  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h6-bold">Sorry, you’re not eligible</p>
        </div>
        <div className="body">
          <p className="b1-regular">
            You do not fulfil all the criteria for this badge.
            <br />
            Please check the criteria again.
          </p>
          <ul>
            <li>
              <Button
                label="OK"
                buttonStyle={ButtonStyle.PRIMARY}
                buttonSize={ButtonSize.L}
                handleClick={handleClose}
              />
            </li>
          </ul>
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 520px;
          text-align: center;
        }
        p {
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
};

export default NotEligibleModal;
