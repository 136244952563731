import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useInitClaimStatus } from 'src/hooks/use-claim-badge';
import useUser from 'src/hooks/use-user';

const GlobalLogicLayer: React.FC = () => {
  const [user] = useUser();
  const router = useRouter();
  const [checkClaimStatus] = useInitClaimStatus(
    user,
    () => {
      console.debug('GLOBAL_LAYER_INIT');
    },
    () => {
      console.debug('GLOBAL_LAYER_SUCCESS');
    },
    () => {
      console.debug('GLOBAL_LAYER_FAIL');
    },
    () => {
      console.debug('GLOBAL_LAYER_CLAIM');
    },
    () => {
      console.debug('GLOBAL_LAYER_MIGRATE');
    },
    () => {
      console.debug('GLOBAL_LAYER_UPGRADE');
    },
  );

  useEffect(() => {
    if (user) {
      checkClaimStatus();
    }
  }, [user, checkClaimStatus, router.pathname]);

  return <></>;
};

export default GlobalLogicLayer;
