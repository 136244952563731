import { CSSProperties } from '@material-ui/styles';
import { useRouter } from 'next/router';
import React from 'react';
import { ROUTE } from 'src/apis/const';
import { UpdateVisibleForProfileParam, UsersAPI } from 'src/apis/users-api';
import useModal from 'src/hooks/use-modal';
import { mutate } from 'swr';
import { Badge as BadgeComponent } from '..';
import { Badge } from '../../entity/badge';
import Button, { ButtonSize, ButtonStyle } from '../common/button';
import Icon from '../common/icon';
import { MODAL_TYPE } from '../common/modal/modal';

interface Props {
  badges: Badge[];
  isMine: boolean;
  address: string;
  title: string;
  isProfilePage?: boolean;
  align?: 'flex-start' | 'center';
  handleBadgeClick?: (tokenId: string) => void;
  showIcon?: boolean;
  style?: CSSProperties;
  lastElementRef?: (node: any) => void;
}

const BadgeList: React.FC<Props> = ({
  badges: _badges,
  isMine,
  title,
  isProfilePage = false,
  handleBadgeClick,
  align = 'flex-start',
  showIcon = false,
  style = {},
  lastElementRef = null,
}) => {
  const router = useRouter();
  const [renderHideBadge, handleOpenHideBadge] = useModal(
    MODAL_TYPE.HideBadge,
    async (arr: UpdateVisibleForProfileParam[]) => {
      await new UsersAPI().updateVisibleForProfile([...arr]);
      mutate(UsersAPI.SWR_PATH.GET_MY_PROFILE_BADGES());
    },
  );

  const isBadgeEmpty = _badges.length === 0;
  const badges = [..._badges];

  return (
    <>
      <div className="container" style={style}>
        {title && (
          <div className="title">
            <div>
              <p className={title.length > 15 ? 'h6-bold' : 'h5-bold'}>
                {title}
              </p>
            </div>
            <div>
              {isMine && isProfilePage && !isBadgeEmpty && (
                <Button
                  label="Show/Hide"
                  buttonStyle={ButtonStyle.LINEAR}
                  buttonSize={ButtonSize.S}
                  handleClick={() => {
                    handleOpenHideBadge(null);
                  }}
                  icon={<Icon iconKey="eye" />}
                  iconWidth={19}
                />
              )}
            </div>
          </div>
        )}

        <div className="content">
          {isBadgeEmpty ? (
            isProfilePage ? (
              isMine ? (
                <div className="empty-bg">
                  <div className="empty-wrap">
                    <p className="h7-bold">
                      You don’t have any badges yet. <br />
                      Collect badges and build your reputation
                    </p>
                    <Button
                      label="Discover Badges"
                      buttonStyle={ButtonStyle.GRADIENT}
                      buttonSize={ButtonSize.M}
                      handleClick={() => {
                        router.push(ROUTE.BADGE);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="empty-bg">
                  <div className="empty-wrap">
                    <p className="h7-bold">This user has 0 Badges</p>
                  </div>
                </div>
              )
            ) : (
              <div className="empty-bg">
                <div className="empty-wrap">
                  <p className="h7-bold">There is no badge</p>
                </div>
              </div>
            )
          ) : (
            <div className="badge-wrap">
              {badges.map((item, i) => {
                const isLastItem = i === badges.length - 1;
                return (
                  <div
                    key={item._id}
                    className="item"
                    ref={isLastItem ? lastElementRef : null}
                  >
                    {showIcon && (
                      <div
                        className={`icon ${
                          item.isClaimed ? 'claimed' : 'unclaimable'
                        }`}
                      ></div>
                    )}
                    <div>
                      <BadgeComponent
                        badge={item}
                        handleClick={() => {
                          if (handleBadgeClick) {
                            handleBadgeClick(item.id);
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {renderHideBadge}
      <style jsx>{`
        .container {
          padding: 40px;
        }
        .update-date {
          color: var(--WT30);
        }
        .container > .title {
          display: flex;
          justify-content: space-between;
          margin-bottom: 32px;
        }
        .container > .content {
        }
        .empty-bg {
          width: 1120px;
          height: 472.13px;
          background: url(/image/empty-card.png) center center no-repeat;
          background-size: 1120px 472.13px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .empty-wrap {
          text-align: center;
        }
        .empty-wrap p {
          margin-bottom: 20px;
        }
        .badge-wrap {
          display: flex;
          gap: 28px 21px;
          flex-wrap: wrap;
          padding: 0px 0px;
          justify-content: ${align};
          align-items: end;
        }
        .badge-wrap .item {
          position: relative;
        }
        .badge-wrap .icon {
          width: 53.3px;
          height: 53.3px;
          left: 106px;
          top: 0px;
          position: absolute;
          z-index: 1;
        }
        .badge-wrap .icon.claimed {
          background: url('/image/series-claimed-big.png') center center
            no-repeat;
          background-size: 53.3px 53.3px;
        }
        .badge-wrap .icon.unclaimable {
          background: url('/image/series-unclaimable-big.png') center center
            no-repeat;
          background-size: 53.3px 53.3px;
        }
        .badge-wrap .icon + div {
          margin-top: 22px;
        }
      `}</style>
    </>
  );
};

export default BadgeList;
