import { NextRouter } from 'next/router';
import {
  Slide,
  toast,
  ToastContainer as _ToastContainer,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROUTE } from 'src/apis/const';
import Button, { ButtonSize, ButtonStyle } from './button';

const defaultSettings: any = {
  position: 'bottom-center',
  autoClose: 8000,
  hideProgressBar: true,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  draggableDirection: 'y',
};

const noPfpExistsToast = (): React.ReactText =>
  toast.error('Sorry, there is no NFT that can be set as a profile picture.', {
    ...defaultSettings,
    toastId: 'noPfpExistsToast',
  });

const noProfileBadgesToast = (): React.ReactText =>
  toast.error('Sorry, there is no badges that can be set as a primary badge.', {
    ...defaultSettings,
    toastId: 'noProfileBadgesToast',
  });

const handleExistsToast = (): React.ReactText =>
  toast.error('The handle already exists.', {
    ...defaultSettings,
    toastId: 'HandleExistsToast',
  });

const copiedToast = (msg: string): React.ReactText =>
  toast.success(msg || 'Copied!', {
    ...defaultSettings,
    toastId: `${msg}CopiedToast`,
  });

const oneBadgeShouldExist = (): React.ReactText =>
  toast.success(`You have to select minimum one badge.`, {
    ...defaultSettings,
    toastId: `oneBadgeShouldExist`,
  });

const noBadgesForPrimaryToast = (): React.ReactText =>
  toast.success(`Sorry, there is no badge that can be set as a primary badge`, {
    ...defaultSettings,
    toastId: `noBadgesForPrimaryToast`,
  });

const TxSucceedToast = (
  address: string,
  router: NextRouter,
  msg?: string,
): React.ReactText => {
  return toast(
    ({ closeToast }) => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <p className="h7-regular" style={{ marginBottom: 20 }}>
          {msg || 'Congratulations! You collected a new badge.'}
        </p>
        <Button
          label="Go to profile"
          buttonStyle={ButtonStyle.GRADIENT}
          buttonSize={ButtonSize.M}
          handleClick={() => {
            router.push(`${ROUTE.PROFILE}/${address}`);
            if (closeToast) {
              closeToast();
            }
          }}
        />
      </div>
    ),
    {
      ...defaultSettings,
      toastId: `TxSucceedToast`,
    },
  );
};

const CreateBadgeSuccessToast = (): React.ReactText =>
  toast.success('The form was submitted successfully.', {
    ...defaultSettings,
    toastId: `createBadgeSuccessToast`,
  });

const CheckEligibilitiesSucceedToast = (): React.ReactText => {
  return toast(
    ({ closeToast }) => (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <p style={{ marginBottom: 12 }}>
          Congratulations! Eligibility check completed.
        </p>
        <Button
          label="OK"
          buttonStyle={ButtonStyle.GRADIENT}
          buttonSize={ButtonSize.S}
          handleClick={() => {
            if (closeToast) {
              closeToast();
            }
          }}
        />
      </div>
    ),
    {
      ...defaultSettings,
      toastId: `CheckEligibilitiesSucceed`,
    },
  );
};

const errorToast = (msg: string): React.ReactText =>
  toast.error(msg, {
    ...defaultSettings,
    toastId: `error`,
  });

const savedToast = (): React.ReactText =>
  toast.success('Saved!', {
    ...defaultSettings,
    toastId: `saved`,
  });

const ToastContainer = (
  <_ToastContainer className="b2-medium" transition={Slide} />
);

export {
  ToastContainer,
  noPfpExistsToast,
  noProfileBadgesToast,
  handleExistsToast,
  copiedToast,
  oneBadgeShouldExist,
  noBadgesForPrimaryToast,
  TxSucceedToast,
  CreateBadgeSuccessToast,
  CheckEligibilitiesSucceedToast,
  errorToast,
  savedToast,
};
