import React, { useState } from 'react';
import { getShortAddress, truncate } from 'src/utils';

const useDisplayName = (
  username: string | undefined,
  address: string | undefined,
  useFullUsername?: boolean,
): [string | undefined] => {
  const [displayName, setDisplayName] = useState<string | undefined>('');

  React.useEffect(() => {
    if (username && username !== address?.toLowerCase()) {
      setDisplayName(useFullUsername ? username : truncate(username, 22));
    } else {
      setDisplayName(getShortAddress(address || '', 4));
    }
  }, [username, address]);

  return [displayName];
};

export default useDisplayName;
