import React, { useCallback, useContext, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import CopyDom from 'src/components/common/copy-dom';
import Icon from 'src/components/common/icon';
import { MODAL_TYPE } from 'src/components/common/modal/modal';
import { errorToast } from 'src/components/common/toast';
import { ABI } from 'src/hooks/use-create-badge-state/abi-state';
import { ActionType } from 'src/hooks/use-create-badge-state/action';
import { ActionAggregationType } from 'src/hooks/use-create-badge-state/action-aggregation';
import {
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
  defaultActionInputError,
  PageType,
} from 'src/hooks/use-create-badge-state/index';
import useModal from 'src/hooks/use-modal';
import { useOnClickOutside } from 'src/hooks/use-onclick-outside';
import { getShortAddress } from 'src/utils';
import ActionSelector from './action-selector';
import AcionTypeSelector from './action-type-selector';

import CriteriaSelector from './criteria-selector';

const Action: React.FC<{ index: number }> = (props) => {
  const { index } = props;
  const [state, setState, error, setError, , , pageType] = useContext(
    CreateBadgeStateContext,
  );
  const eligibilityRule = state?.eligibilityRule || [];
  const currentAction = (state?.eligibilityRule.actionUnits)[index];
  const toolRef = useRef<HTMLDivElement>(null);
  const [showTool, toggleTool] = useState<boolean>(false);

  const isViewPage = pageType === PageType.VIEW;

  useOnClickOutside(toolRef, () => toggleTool(false));

  const validateAndSave = useCallback(() => {
    let isErrorExist = false;
    // Validate
    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.eligibilityRule.actionUnits.contract,
    )) {
      if (
        !errValue.validate(state.eligibilityRule.actionUnits[index].contract)
      ) {
        setError((error) => {
          const newActionRuleError = [
            ...(error.eligibilityRule as any).actionUnits,
          ];
          newActionRuleError[index].contract = (
            CreateBadgeStateValidation.eligibilityRule.actionUnits
              .contract as any
          )[errorKey];
          return {
            ...error,
            eligibilityRule: {
              ...error.eligibilityRule,
              actionRule: newActionRuleError,
            },
          };
        });
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.eligibilityRule.actionUnits.action,
    )) {
      if (
        !errValue.validate(state.eligibilityRule.actionUnits[index].action.name)
      ) {
        setError((error) => {
          const newActionRuleError = [
            ...(error.eligibilityRule as any).actionUnits,
          ];
          newActionRuleError[index].action = (
            CreateBadgeStateValidation.eligibilityRule.actionUnits.action as any
          )[errorKey];
          return {
            ...error,
            eligibilityRule: {
              ...error.eligibilityRule,
              actionRule: newActionRuleError,
            },
          };
        });
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.eligibilityRule.actionUnits.userEOAField,
    )) {
      if (
        !errValue.validate(
          state.eligibilityRule.actionUnits[index].userEOAField,
        )
      ) {
        setError((error) => {
          const newActionRuleError = [
            ...(error.eligibilityRule as any).actionUnits,
          ];
          newActionRuleError[index].userEOAField = (
            CreateBadgeStateValidation.eligibilityRule.actionUnits
              .userEOAField as any
          )[errorKey];
          return {
            ...error,
            eligibilityRule: {
              ...error.eligibilityRule,
              actionRule: newActionRuleError,
            },
          };
        });
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.eligibilityRule.actionUnits.aggregations,
    )) {
      const aggr = state.eligibilityRule.actionUnits[index].aggregations[0];
      if (
        !errValue.validate(
          aggr?.type === ActionAggregationType.SUM ? aggr.field : aggr?.type,
        )
      ) {
        setError((error) => {
          const newActionRuleError = [
            ...(error.eligibilityRule as any).actionUnits,
          ];
          newActionRuleError[index].aggregations = (
            CreateBadgeStateValidation.eligibilityRule.actionUnits
              .aggregations as any
          )[errorKey];
          return {
            ...error,
            eligibilityRule: {
              ...error.eligibilityRule,
              actionRule: newActionRuleError,
            },
          };
        });
        isErrorExist = true;
        break;
      }
    }

    if (isErrorExist) {
      throw new Error("Required field can't be blank.");
    }

    // Confirm
    setError((error) => {
      const newError = [...error?.eligibilityRule.actionUnits];
      newError[index] = {
        ...defaultActionInputError,
      };
      return {
        ...error,
        eligibilityRule: {
          ...error.eligibilityRule,
          actionUnits: newError,
        },
      };
    });
    setState((state) => {
      const newEligibilityRulesActionUnits = [
        ...state?.eligibilityRule.actionUnits,
      ];
      newEligibilityRulesActionUnits[index]._confirmedOnce = true;
      newEligibilityRulesActionUnits[index]._isEditing = false;
      return {
        ...state,
        eligibilityRule: {
          ...state.eligibilityRule,
          actionUnits: newEligibilityRulesActionUnits,
        },
      };
    });
  }, [setError, setState, state.eligibilityRule.actionUnits, index]);

  return (
    <>
      <div className={`wrap ${!currentAction._isEditing && 'confirmed'}`}>
        <div className="header">
          <div className="flex space-between">
            <p className="title">
              Action<span>{index + 1}</span>
            </p>
            {!isViewPage && (
              <div className="header-tool flex cap1-bold">
                <div
                  className="tool"
                  onClick={() => {
                    toggleTool(true);
                  }}
                >
                  <Icon iconKey="ellipsisVertical" />
                </div>
                {showTool && (
                  <div ref={toolRef} className="tool-modal">
                    <ul>
                      <li
                        onClick={() => {
                          if (eligibilityRule.actionUnits.length <= 1) {
                            errorToast('At least one action is required.');
                            return;
                          }

                          toggleTool(false);
                          setError((error) => {
                            const newActionUnitsError = [
                              ...error?.eligibilityRule.actionUnits,
                            ];
                            newActionUnitsError.splice(index, 1);
                            return {
                              ...error,
                              eligibilityRule: {
                                ...error.eligibilityRule,
                                actionUnits: newActionUnitsError,
                              },
                            };
                          });
                          setState((state) => {
                            const newEligibilityRulesActionUnits = [
                              ...state?.eligibilityRule.actionUnits,
                            ];
                            newEligibilityRulesActionUnits.splice(index, 1);
                            return {
                              ...state,
                              eligibilityRule: {
                                ...state.eligibilityRule,
                                actionUnits: newEligibilityRulesActionUnits,
                              },
                            };
                          });
                        }}
                        className="cap1-medium"
                      >
                        Delete
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="body no-scroll">
          {!currentAction._isEditing ? (
            <ActionConfirmedView {...props} />
          ) : (
            <ActionEditView {...props} />
          )}
        </div>
        {!isViewPage && (
          <div className="footer">
            <div className="footer-tool flex cap1-bold">
              {!currentAction._isEditing ? (
                <div
                  className="button"
                  onClick={() => {
                    setState((state) => {
                      const newEligibilityRulesActionUnits = [
                        ...state?.eligibilityRule.actionUnits,
                      ];
                      newEligibilityRulesActionUnits[index]._isEditing = true;
                      return {
                        ...state,
                        eligibilityRule: {
                          ...state.eligibilityRule,
                          actionUnits: newEligibilityRulesActionUnits,
                        },
                      };
                    });
                  }}
                >
                  <span className="check">
                    <Icon iconKey="pencil" />
                  </span>
                  <span>Edit</span>
                </div>
              ) : (
                <div
                  className="button"
                  onClick={() => {
                    try {
                      validateAndSave();
                    } catch (e: any) {
                      errorToast(e.message);
                    }
                  }}
                >
                  <span className="check">
                    <Icon iconKey="check" />
                  </span>
                  <span>Confirm</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <style jsx>{`
        .flex {
          display: flex;
        }
        .flex.space-between {
          justify-content: space-between;
        }
        .wrap {
          background: var(--BT80);
          width: calc(100% - 0px);
          padding: 16px 0px 0px;
          border-radius: 24px;
        }
        .header {
          min-height: 30px;
          padding: 0px 20px 16px;
          border-bottom: 1px solid var(--G700);
          margin-bottom: 16px;
        }

        .header .title {
          color: var(--G25);
          font-size: 2rem;
          line-height: 3rem;
          font-weight: bold;
        }
        .header .title span {
          display: inline-block;
          margin-left: 8px;
          background: rgba(67, 44, 148, 1);
          padding: 3px 9.5px;
          border-radius: 100%;
          font-size: 1.2rem;
          line-height: 1.8rem;
          vertical-align: text-bottom;
          margin-bottom: 2px;
        }

        .header-tool {
          position: relative;
        }

        .header-tool .tool {
          width: 24px;
          color: var(--G200);
          padding-left: 4px;
          cursor: pointer;
        }

        .body {
          width: calc(100% - 40px);
          ${!currentAction._isEditing ? 'overflow: auto;' : ''}
          padding: 0px 20px 22px;
        }
        .tool-modal {
          background: var(--G700);
          border-radius: 8px;
          padding: 8px 12px;
          width: 40px;
          position: absolute;
          right: -70px;
        }
        .tool-modal li {
          width: 100%;
          cursor: pointer;
        }
        .footer {
          border-top: 1px solid var(--G700);
          min-height: 30px;
          padding: 12px 20px 12px;
        }
        .footer-tool {
          display: flex;
          position: relative;
          justify-content: flex-end;
        }
        .footer .button {
          background: var(--P500);
          border-radius: 100px;
          align-items: center;
          display: flex;
          gap: 2px;
          height: 18px;
          cursor: pointer;
          padding: 3px 16px 3px 10px;
        }
        .wrap.confirmed .footer-tool .button {
          background: var(--WT100);
          color: var(--P500);
        }
        .footer .button span.check {
          width: 18px;
          display: flex;
          color: var(--P200);
        }
      `}</style>
    </>
  );
};

const ActionEditView: React.FC<{ index: number }> = (props) => {
  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const [state, setState, error, setError] = useContext(
    CreateBadgeStateContext,
  );

  const action = (state?.eligibilityRule.actionUnits)[props.index];
  const isActionSelectorDone = !!action.contract && !!action.action.name;
  const isCriteriaSelectorDone = isActionSelectorDone && !!action.userEOAField;
  const isActionTypeSelectorDone =
    isActionSelectorDone &&
    isCriteriaSelectorDone &&
    action.aggregations.length > 0;

  return (
    <>
      <div className="flex">
        <div className={`block ${isActionSelectorDone && 'done'}`}>
          <div className="checker P300 b2-regular">
            <span className="check">
              <Icon iconKey="check" />
            </span>
            <span>Add Contract Address and Select Action</span>
          </div>
          <ActionSelector {...props} />
        </div>
        <div className="divider">
          <div></div>
        </div>
        <div
          className={`block ${!isActionSelectorDone && 'hide'} ${
            isCriteriaSelectorDone && 'done'
          }`}
        >
          <div className="checker P300 b2-regular">
            <span className="check">
              <Icon iconKey="check" />
            </span>
            <span>Set criteria for a valid action</span>
            <span
              data-tip={`
                  Select which parameter is to be verified when checking for
                  eligibility.<br /><br /> For example, the USDT contract's 'Transfer' Event Log has
                  parameters like 'address from' and 'address to'. Selecting
                  'address from' will verify the sender and selecting 'address
                  to' will verify the receiver.
                `}
              className="tooltip-icon"
            ></span>
          </div>
          <CriteriaSelector {...props} isDisabled={!isActionSelectorDone} />
        </div>
        <div className="divider">
          <div></div>
        </div>
        <div
          className={`block ${!isCriteriaSelectorDone && 'hide'} ${
            isActionTypeSelectorDone && 'done'
          }`}
        >
          <div className="checker P300 b2-regular">
            <span className="check">
              <Icon iconKey="check" />
            </span>
            Select the type for the action
            <span
              data-tip="Select whether you want to count the action (e.g. # of transfers) or measure specific value in the action (e.g. amount of tokens)"
              className="tooltip-icon"
            ></span>
          </div>
          <AcionTypeSelector {...props} isDisabled={!isCriteriaSelectorDone} />
        </div>
      </div>
      <style jsx>
        {`
          .flex {
            display: flex;
          }
          .flex.space-between {
            justify-content: space-between;
          }
          .divider {
            display: flex;
            align-items: center;
            width: 20px;
          }
          .divider div {
            width: 20px;
            height: 3px;
            background: var(--G400);
            margin-bottom: -20px;
          }
          .checker {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            margin-right: 11px;
          }
          .checker span.check {
            width: 16px;
            display: inline-block;
            height: 18px;
            margin-right: 6px;
          }
          .block {
            width: 373.33px;
          }
          .block.done .checker span.check {
            color: var(--LG);
          }
          .block.hide {
            opacity: 0.5;
          }
          .tooltip-icon {
            display: inline-block;
            width: 20.5px;
            height: 23.5px;
            margin-right: 5.8px;
            background: url(/image/purple-info-icon.png) left 3px no-repeat;
            background-size: 18.5px 18.5px;
            margin-top: -3px;
            margin-left: 5px;
          }
        `}
      </style>
    </>
  );
};

const ActionConfirmedView: React.FC<{
  index: number;
}> = (props) => {
  const [state, setState, error, setError, , , pageType] = useContext(
    CreateBadgeStateContext,
  );
  const [renderAdvancedCriteria, handleOpenAdvancedCriteria] = useModal(
    MODAL_TYPE.AdvancedCriteria,
    async () => {},
  );

  const action = (state?.eligibilityRule.actionUnits)[props.index];
  const isViewPage = pageType === PageType.VIEW;

  return (
    <>
      {renderAdvancedCriteria}
      <div className="wrap flex">
        <div className="block">
          <div className="label contract-address">
            <CopyDom text={action.contract}>
              {getShortAddress(action.contract, 4)}
            </CopyDom>
          </div>
          <div className="label action">
            {ABI.getActionLabelComp(action.action)}
          </div>
        </div>
        <div className="divider">
          <div></div>
        </div>
        <div className="block">
          <div className="label eoa">
            {action.type === ActionType.TX ? (
              <>
                <span className="P200">address from</span>
                <span
                  className="cap1-bold G100"
                  style={{
                    padding: '4px 8px',
                    background: 'var(--WT15)',
                    borderRadius: 8,
                    marginLeft: 4,
                  }}
                >
                  TX Object
                </span>
              </>
            ) : (
              ABI.getParamLabelComp(
                action.action.inputs.filter(
                  (input) => input.name === action.userEOAField,
                )[0],
              )
            )}
          </div>
        </div>
        <div className="divider">
          <div></div>
        </div>
        {action.filters.length > 0 && (
          <>
            <div
              className="block"
              style={{
                cursor: isViewPage ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (isViewPage) {
                  handleOpenAdvancedCriteria({
                    index: props.index,
                  });
                }
              }}
            >
              <div className="label filters">
                + {action.filters.length} filters
              </div>
            </div>
            <div className="divider">
              <div></div>
            </div>
          </>
        )}

        {action.aggregations[0]?.type === ActionAggregationType.COUNT ? (
          <div className="block">
            <div className="label type">Count</div>
          </div>
        ) : (
          <div className="block">
            <div className="label type">Volume</div>
            <div className="label value">
              {ABI.getParamLabelComp(
                action.action.inputs.filter(
                  (input) => input.name === action.aggregations[0]?.field,
                )[0],
              )}
            </div>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .wrap {
            width: fit-content;
            overflow: auto;
          }

          .flex {
            display: flex;
          }

          .block {
            background: var(--G900);
            border: 2px solid var(--G400);
            border-radius: 18px;
            padding: 10px 14px;
            display: flex;
            gap: 8px;
          }
          .label {
            font-size: 1.6rem;
            line-height: 2.4rem;
            background: var(--WT15);
            border-radius: 8px;
            padding: 6px;
            width: max-content;
          }
          .divider {
            display: flex;
            align-items: center;
            width: 20px;
          }
          .divider div {
            width: 20px;
            height: 3px;
            background: var(--G400);
          }
          .checker {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            margin-right: 11px;
          }
          .checker span.check {
            width: 16px;
            display: inline-block;
            height: 18px;
            margin-right: 6px;
          }
        `}
      </style>
    </>
  );
};

export default Action;
