import React, { useCallback, useState } from 'react';
import { BadgeDetailed } from 'src/entity/badge';
import { codePointsToString } from 'src/utils';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';

const CheckEligibilityModal: React.FC<{
  badge: BadgeDetailed;
  handleClose: () => void;
  handleSubmit: any;
}> = ({ badge, handleClose, handleSubmit }) => {
  const [_loading, _setLoading] = useState<boolean>(false);
  const _handleSubmit = useCallback(async () => {
    try {
      _setLoading(true);
      await handleSubmit();
    } catch (e: any) {
      console.error(e.message);
    } finally {
      _setLoading(false);
      handleClose();
    }
  }, [handleSubmit, handleClose]);
  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h6-bold">Are you sure?</p>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <div className="body">
          <p className="b1-regular yellow desc">
            Checking eligibility process requires 30~60 seconds.
          </p>
          <ul>
            <li className="b2-medium">
              <span className="cap1-regular WT60">
                {codePointsToString(['U+2705'])} Badge name :
              </span>
              <br />
              {badge.name}
            </li>
            <li className="b2-medium">
              <span className="cap1-regular WT60">
                {codePointsToString(['U+2705'])} Badge description :{' '}
              </span>
              <br />
              {badge.description}
            </li>
          </ul>
          <Button
            isDisabled={true}
            label="Check eligibility"
            buttonStyle={ButtonStyle.PRIMARY}
            buttonSize={ButtonSize.M}
            handleClick={_handleSubmit}
            loading={_loading}
          />
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 520px;
          text-align: left;
        }
        .header {
          margin-bottom: 25px;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .body p.desc {
          margin-bottom: 24px;
        }
        .body ul li {
          margin-bottom: 24px;
        }
      `}</style>
    </>
  );
};

export default CheckEligibilityModal;
