import React, { useContext } from 'react';
import InputV2, {
  defaultInputError,
  InputError,
} from 'src/components/common/input-v2';
import Select from 'src/components/common/select';
import {
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
  PageType,
} from 'src/hooks/use-create-badge-state/index';

const GeneralInformation: React.FC = () => {
  const [state, setState, error, setError, , , pageType] = useContext(
    CreateBadgeStateContext,
  );
  const categoryOptions = [
    { label: 'General', value: 'General' },
    { label: 'DeFi', value: 'DeFi' },
    { label: 'NFT', value: 'NFT' },
    { label: 'Ecosystem', value: 'Ecosystem' },
    { label: 'Game', value: 'Game' },
    { label: 'DAO', value: 'DAO' },
  ];

  const isViewPage = pageType === PageType.VIEW;

  return (
    <>
      <div className="wrap h6-bold G25">
        <div className="input">
          <InputV2
            name="Title"
            value={state.title || ''}
            setValue={(val) => {
              setState((state) => ({
                ...state,
                title: val,
              }));
            }}
            error={error.title as InputError}
            setError={(val: string) => {
              setError((state) => ({
                ...state,
                title: val
                  ? (CreateBadgeStateValidation.title as any)[val]
                  : defaultInputError,
              }));
            }}
            isRequired={true}
            placeholder="Enter the title of the achievement"
            desc={<>e.g. Uniswap Governance Voter</>}
            errorArr={CreateBadgeStateValidation.title}
            disabled={isViewPage}
          />
        </div>
        <div className="input">
          <InputV2
            name="1-liner Eligibility Description"
            value={state.descriptionEligibility || ''}
            setValue={(val) => {
              setState((state) => ({
                ...state,
                descriptionEligibility: val,
              }));
            }}
            error={error.descriptionEligibility as InputError}
            setError={(val: string) => {
              setError((state) => ({
                ...state,
                descriptionEligibility: val
                  ? (CreateBadgeStateValidation.descriptionEligibility as any)[
                      val
                    ]
                  : defaultInputError,
              }));
            }}
            isRequired={true}
            placeholder="Enter 1-liner Eligibility Description"
            desc={<>e.g. Voted 3+ times on Uniswap</>}
            errorArr={CreateBadgeStateValidation.descriptionEligibility}
            tooltip="1-liner description of the eligibility for the badge. Max length is 75 chars. Please make sure to include all relevant eligibility criteria in one line (e.g. Voted 3+ times on Uniswap)"
            disabled={isViewPage}
            maxLength={75}
          />
        </div>
        <div className="input">
          <InputV2
            name="Full Description"
            value={state.description || ''}
            setValue={(val) => {
              setState((state) => ({
                ...state,
                description: val,
              }));
            }}
            error={error.description as InputError}
            setError={(val: string) => {
              setError((state) => ({
                ...state,
                description: val
                  ? (CreateBadgeStateValidation.description as any)[val]
                  : defaultInputError,
              }));
            }}
            isTextarea={true}
            isRequired={true}
            placeholder="Enter Full Description"
            desc={
              <>
                e.g. Owning this badge indicates that the user has voted on a
                proposal 1+ times on Uniswap
              </>
            }
            tooltip="Full description of the badge. As per the ERC-1155 standards, a full description is separate metadata and it may be visible on third-party apps like OpenSea, Gallery, as well as Noox."
            errorArr={CreateBadgeStateValidation.description}
            disabled={isViewPage}
          />
        </div>
        <div className="flex">
          <div className="input">
            <InputV2
              name="Project"
              value={state.project || ''}
              setValue={(val) => {
                setState((state) => ({
                  ...state,
                  project: val,
                }));
              }}
              error={error.project as InputError}
              setError={(val: string) => {
                setError((state) => ({
                  ...state,
                  project: val
                    ? (CreateBadgeStateValidation.project as any)[val]
                    : defaultInputError,
                }));
              }}
              isRequired={true}
              placeholder="Enter Project Name"
              errorArr={CreateBadgeStateValidation.project}
              disabled={isViewPage}
            />
          </div>
          <div className="input">
            <Select
              defaultValue={
                categoryOptions.filter(
                  (item) => item.value === state.projectCategory,
                )[0]
              }
              handleOnChange={(val) => {
                setState((state) => ({
                  ...state,
                  projectCategory: val,
                }));
              }}
              error={error.projectCategory as InputError}
              setError={(val: string) => {
                setError((state) => ({
                  ...state,
                  projectCategory: val
                    ? (CreateBadgeStateValidation.projectCategory as any)[val]
                    : defaultInputError,
                }));
              }}
              name="Project Category"
              placeholder="Select Category"
              isRequired={true}
              options={categoryOptions}
              errorArr={CreateBadgeStateValidation.projectCategory}
              disabled={isViewPage}
            />
          </div>
        </div>
        <div className="input">
          <InputV2
            name="Project Official Website URL"
            value={state.projectUrl || ''}
            setValue={(val) => {
              setState((state) => ({
                ...state,
                projectUrl: val,
              }));
            }}
            error={error.projectUrl as InputError}
            setError={(val: string) => {
              setError((state) => ({
                ...state,
                projectUrl: val
                  ? (CreateBadgeStateValidation.projectUrl as any)[val]
                  : defaultInputError,
              }));
            }}
            isRequired={true}
            placeholder="Enter URL"
            desc={<></>}
            errorArr={CreateBadgeStateValidation.projectUrl}
            disabled={isViewPage}
          />
        </div>
      </div>
      <style jsx>
        {`
          h3,
          .input {
            margin-bottom: 20px;
          }
          .flex {
            display: flex;
            gap: 16px;
          }
          .flex .input {
            width: 50%;
            flex-grow: 2;
          }
        `}
      </style>
    </>
  );
};

export default GeneralInformation;
