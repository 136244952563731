import { useRouter } from 'next/router';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';
import { AbisAPI } from 'src/apis/abis-api';
import { ROUTE } from 'src/apis/const';
import { UserBadgeDto, UserBadgesAPI } from 'src/apis/user-badges-api';
import Button, { ButtonSize, ButtonStyle } from 'src/components/common/button';
import { MODAL_TYPE } from 'src/components/common/modal/modal';
import { errorToast, savedToast } from 'src/components/common/toast';
import {
  convertToUserBadgeDto,
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
  PageType,
} from 'src/hooks/use-create-badge-state/index';
import useModal from 'src/hooks/use-modal';
import { TabType } from './create-badge-container';

const SubmitSection: React.FC<{
  isEdit: boolean;
  curTab?: TabType;
  setCurTab?: Dispatch<SetStateAction<TabType>>;
}> = ({ isEdit, curTab = null, setCurTab = null }) => {
  const router = useRouter();
  const [state, , , setError, abiState, , pageType] = useContext(
    CreateBadgeStateContext,
  );
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [renderContactModal, handleOpenContactModal] = useModal(
    MODAL_TYPE.Contact,
    async () => {},
  );
  const isContactModal = !curTab && !setCurTab;

  const validateTitleOnly = useCallback(() => {
    let isErrorExist = false;
    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.title,
    )) {
      if (!errValue.validate(state.title)) {
        setError((error) => ({
          ...error,
          title: (CreateBadgeStateValidation.title as any)[errorKey],
        }));
        isErrorExist = true;
        break;
      }
    }
    if (isErrorExist) {
      throw new Error('Title is required.');
    }
  }, [setError, state.title]);

  const validateActionConfirmed = useCallback(() => {
    let isErrorExist = false;
    for (const action of state.eligibilityRule.actionUnits) {
      if (action._isEditing) {
        isErrorExist = true;
        break;
      }
    }
    if (isErrorExist) {
      throw new Error('Please confirm the action.');
    }
  }, [state.eligibilityRule.actionUnits]);

  const validate = useCallback(() => {
    let isErrorExist = false;
    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.title,
    )) {
      if (!errValue.validate(state.title)) {
        setError((error) => ({
          ...error,
          title: (CreateBadgeStateValidation.title as any)[errorKey],
        }));
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.descriptionEligibility,
    )) {
      if (!errValue.validate(state.descriptionEligibility)) {
        setError((error) => ({
          ...error,
          descriptionEligibility: (
            CreateBadgeStateValidation.descriptionEligibility as any
          )[errorKey],
        }));
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.description,
    )) {
      if (!errValue.validate(state.description)) {
        setError((error) => ({
          ...error,
          description: (CreateBadgeStateValidation.description as any)[
            errorKey
          ],
        }));
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.project,
    )) {
      if (!errValue.validate(state.project)) {
        setError((error) => ({
          ...error,
          project: (CreateBadgeStateValidation.project as any)[errorKey],
        }));
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.projectCategory,
    )) {
      if (!errValue.validate(state.projectCategory)) {
        setError((error) => ({
          ...error,
          projectCategory: (CreateBadgeStateValidation.projectCategory as any)[
            errorKey
          ],
        }));
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.projectUrl,
    )) {
      if (!errValue.validate(state.projectUrl)) {
        setError((error) => ({
          ...error,
          projectUrl: (CreateBadgeStateValidation.projectUrl as any)[errorKey],
        }));
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.discordHandle,
    )) {
      if (!errValue.validate(state.discordHandle)) {
        setError((error) => ({
          ...error,
          discordHandle: (CreateBadgeStateValidation.discordHandle as any)[
            errorKey
          ],
        }));
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.eligibilityRule.required,
    )) {
      if (!errValue.validate(state.eligibilityRule.required)) {
        setError((error) => ({
          ...error,
          eligibilityRule: {
            ...error.eligibilityRule,
            required: (
              CreateBadgeStateValidation.eligibilityRule.required as any
            )[errorKey],
          },
        }));
        isErrorExist = true;
        break;
      }
    }

    for (const [errorKey, errValue] of Object.entries(
      CreateBadgeStateValidation.badgeThumbnail,
    )) {
      if (!errValue.validate(state.badgeThumbnail)) {
        setError((error) => ({
          ...error,
          badgeThumbnail: (CreateBadgeStateValidation.badgeThumbnail as any)[
            errorKey
          ],
        }));
        isErrorExist = true;
        break;
      }
    }

    if (state.eligibilityRule.actionUnits.length === 0) {
      isErrorExist = true;
    }

    if (isErrorExist) {
      throw new Error("Required field can't be blank.");
    }
  }, [
    setError,
    state.badgeThumbnail,
    state.description,
    state.descriptionEligibility,
    state.discordHandle,
    state.eligibilityRule,
    state.project,
    state.projectCategory,
    state.projectUrl,
    state.title,
  ]);

  const create = useCallback(async () => {
    const param: UserBadgeDto = convertToUserBadgeDto(state);

    // Image Upload
    const isImageShouldBeUploaded = (file: any) =>
      file && !file.preview.startsWith('https://');
    const isUploadedImage = (file: any) =>
      !(file instanceof File) && typeof file !== 'string';
    if (isImageShouldBeUploaded(param.image.thumbnailUrl)) {
      const image = await new UserBadgesAPI().storeUserBadgeImage(
        param.image.thumbnailUrl as any,
      );
      param.image.thumbnailUrl = image;
    }
    if (isImageShouldBeUploaded(param.image.backgroundUrl)) {
      const image = await new UserBadgesAPI().storeUserBadgeImage(
        param.image.backgroundUrl as any,
      );
      param.image.backgroundUrl = image;
    }
    if (isImageShouldBeUploaded(param.image.logoUrl)) {
      const image = await new UserBadgesAPI().storeUserBadgeImage(
        param.image.logoUrl as any,
      );
      param.image.logoUrl = image;
    }
    if (isUploadedImage(param.image.thumbnailUrl as any)) {
      param.image.thumbnailUrl = (param.image.thumbnailUrl as any).preview;
    }
    if (isUploadedImage(param.image.backgroundUrl as any)) {
      param.image.backgroundUrl = (param.image.backgroundUrl as any).preview;
    }
    if (isUploadedImage(param.image.logoUrl as any)) {
      param.image.logoUrl = (param.image.logoUrl as any).preview;
    }

    for (const [contract, abi] of Object.entries(abiState)) {
      if (abi?.isCustomABI) {
        await new AbisAPI().createABI(contract, abi.abi!.abi);
      }
    }

    let id;
    if (isEdit) {
      id = await new UserBadgesAPI().updateUserBadge(state?.id || '', param);
    } else {
      id = await new UserBadgesAPI().createUserBadge(param);
    }
    return id;
  }, [state, isEdit]);

  const goToListPage = useCallback(() => {
    router.push(ROUTE.USER_BADGE);
  }, []);

  const save = useCallback(async () => {
    try {
      setSaveLoading(true);
      validateTitleOnly();
      if (state.eligibilityRule.actionUnits[0].contract) {
        validateActionConfirmed();
      }
      await create();
      savedToast();
      goToListPage();
    } catch (e: any) {
      errorToast(e.message);
      console.log(e);
    } finally {
      setSaveLoading(false);
    }
  }, [create, goToListPage, validateActionConfirmed, validateTitleOnly]);

  const submit = useCallback(async () => {
    try {
      setSubmitLoading(true);
      validate();
      validateActionConfirmed();
      const id = await create();
      await new UserBadgesAPI().submitUserBadge(id);
      goToListPage();
    } catch (e: any) {
      errorToast(e.message);
      console.log(e);
      setSubmitLoading(false);
    }
  }, [create, goToListPage, validate, validateActionConfirmed]);

  const goToNextTab = useCallback(() => {
    if (curTab && setCurTab) {
      if (curTab === TabType.GENERAL) {
        setCurTab(TabType.IMAGE);
      } else if (curTab === TabType.IMAGE) {
        setCurTab(TabType.ELIGIBILITY_RULE);
      } else if (curTab === TabType.ELIGIBILITY_RULE) {
        handleOpenContactModal(null);
      }
    }
  }, [curTab, setCurTab, handleOpenContactModal]);

  return !(pageType === PageType.VIEW) ? (
    <>
      {renderContactModal}
      <div
        className="flex"
        style={{
          justifyContent: isContactModal ? 'center' : 'flex-start',
        }}
      >
        <Button
          label={isContactModal ? 'Submit' : 'Next step'}
          buttonStyle={
            isContactModal ? ButtonStyle.GRADIENT : ButtonStyle.PRIMARY
          }
          buttonSize={ButtonSize.M}
          handleClick={isContactModal ? submit : goToNextTab}
          loading={isContactModal ? submitLoading : false}
          style={{
            margin: 0,
          }}
        />
        <Button
          label="Save"
          buttonStyle={ButtonStyle.SECONDARY}
          buttonSize={ButtonSize.M}
          handleClick={save}
          loading={saveLoading}
          style={{
            margin: 0,
          }}
        />
        <style jsx>{`
          .flex {
            display: flex;
            gap: 12px;
          }
        `}</style>
      </div>
    </>
  ) : (
    <></>
  );
};

export default SubmitSection;
