import { Pagination } from 'src/entity/common';
import { decompressToBase64, isObjectEmpty } from 'src/utils';
import { KeyedMutator, SWRConfiguration, SWRResponse } from 'swr';
import {
  Badge,
  BadgeDetailed,
  getBadgeTierInfo,
  getBHLabel,
} from '../entity/badge';
import { API } from './api';
import { API_CONST } from './const';
import { _useSWR, _useSWRInfinite } from './swr';

type GetBadgesParams = {
  category?: string;
} & Partial<Pagination>;
interface GetBadgesResponse extends Pagination {
  badges: Badge[];
  totalClaimCount: number;
}

export class BadgesAPI extends API {
  constructor(_url?: string) {
    super(API_CONST.ROOT_URL, _url || API_CONST.BADGES_API_URL);
  }

  static PATH = {
    // GET
    GET_BADGES: (): string => '',
    GET_BADGE: (badgeId: string): string => `${badgeId}`,
    GET_METHODS: (): string => 'methods',
  };

  getSwrPath(): Record<string, any> {
    return {
      GET_BADGES: () => `${this.controllerUrl}${BadgesAPI.PATH.GET_BADGES()}`,
      GET_BADGE: (badgeId: string) =>
        `${this.controllerUrl}/${BadgesAPI.PATH.GET_BADGE(badgeId)}`,
    };
  }

  async getBadges(
    params: GetBadgesParams,
    query: string,
  ): Promise<GetBadgesResponse> {
    const paramsString = !isObjectEmpty(params)
      ? `&category=${params.category}`
      : '';
    const res = await this.get(
      `${BadgesAPI.PATH.GET_BADGES()}`,
      `${query}${paramsString}`,
    );

    const badges = res.badges.map((badge: Badge) => ({
      ...badge,
      ...getBadgeTierInfo(badge),
    }));

    return {
      ...res,
      badges,
    };
  }

  async getBadge(badgeId: string, address?: string): Promise<BadgeDetailed> {
    let _url = BadgesAPI.PATH.GET_BADGE(badgeId);
    _url = address ? `${_url}?address=${address}` : _url;
    const res = await this.get(_url);
    const logicArgs: Record<string, any> = decompressToBase64(
      res.badgeLogicDesc.logicArgs,
    );

    let newLogicArgs = { ...logicArgs };
    if (logicArgs.args.targets) {
      const methodNames = await this.getMethods(
        logicArgs.args.targets.map((target: any) => target.method),
      );
      newLogicArgs = {
        ...logicArgs,
        args: {
          ...logicArgs.args,
          targets: logicArgs.args.targets.map((target: any) => ({
            ...target,
            method: methodNames[target.method],
          })),
        },
      };
    }

    const badgeTierInfo = getBadgeTierInfo(res);
    return {
      ...res,
      ...badgeTierInfo,
      attributes: decompressToBase64(res.attributes),
      badgeLogicDesc: {
        ...res.badgeLogicDesc,
        logicArgs: { ...newLogicArgs },
      },
      uncles: res.uncles.map((uncle: Badge) => ({
        ...uncle,
        ...getBadgeTierInfo(uncle),
      })),
      _bhLabel: getBHLabel(res.publishBH, res.deprecateBH),
    };
  }

  async getMethods(methodIds: string[]): Promise<any> {
    const res = await this.post(`${BadgesAPI.PATH.GET_METHODS()}`, {
      ids: methodIds,
    });

    return res;
  }

  useGetBadges(
    params: GetBadgesParams,
    options: SWRConfiguration = {},
  ): [
    GetBadgesResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    return _useSWRInfinite<GetBadgesResponse, Badge>({
      key: this.getSwrPath().GET_BADGES(),
      params,
      size: 100,
      func: async (query) => {
        return await new BadgesAPI().getBadges(params, query);
      },
      dataKey: 'badges',
      options,
    });
  }

  useGetBadge(
    badgeId: string,
    options: SWRConfiguration = {},
  ): SWRResponse<BadgeDetailed, Error> {
    return _useSWR<BadgeDetailed>(
      badgeId ? this.getSwrPath().GET_BADGE(badgeId) : null,
      async () => new BadgesAPI().getBadge(badgeId),
      options,
    );
  }

  useGetBadgeWithAddress(
    badgeId: string,
    address: string,
    options: SWRConfiguration = {},
  ): SWRResponse<BadgeDetailed, Error> {
    return _useSWR<BadgeDetailed>(
      badgeId ? this.getSwrPath().GET_BADGE(badgeId) : null,
      async () => new BadgesAPI().getBadge(badgeId, address),
      options,
    );
  }
}
