import { User } from 'src/entity/user';
import { SWRConfiguration, SWRResponse } from 'swr';
import { API } from './api';
import { API_CONST } from './const';
import { _useSWR } from './swr';

export class ProfileAPI extends API {
  constructor() {
    super(API_CONST.ROOT_URL, API_CONST.PROFILE_API_URL);
  }

  static PATH = {
    GET_PROFILE: (address: string): string => `${address}`,
  };

  static SWR_PATH = {
    GET_PROFILE: (address: string): string =>
      `${API_CONST.PROFILE_API_URL}/${ProfileAPI.PATH.GET_PROFILE(address)}`,
  };

  async getProfile(address: string): Promise<User> {
    const res = await this.get(`${ProfileAPI.PATH.GET_PROFILE(address)}`);
    return res;
  }

  useGetProfile(
    address: string,
    options?: SWRConfiguration,
  ): SWRResponse<User, Error> {
    return _useSWR(
      ProfileAPI.SWR_PATH.GET_PROFILE(address),
      async () => new ProfileAPI().getProfile(address),
      options || {},
    );
  }
}
