import React, { useContext } from 'react';
import { CreateBadgeStateContext } from 'src/hooks/use-create-badge-state/index';
import { codePointsToString } from 'src/utils';

const MemoSection: React.FC = () => {
  const [state] = useContext(CreateBadgeStateContext);
  return (
    <>
      {state.memo && (
        <div className="memo">
          <div className="title b1-medium">
            Application Feedback {codePointsToString(['U+1F514'])}
          </div>
          <div className="desc cap1-light G100 scroll">{state.memo}</div>
        </div>
      )}
      <style jsx>{`
        .memo {
          padding: 15px;
          border: 1px solid var(--Y);
          border-radius: 16px;
        }
        .memo .title {
          color: var(--Y);
          margin-bottom: 8px;
        }
        .memo .desc {
          overflow: auto;
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */
          justify-content: flex-start;
          min-height: 20px;
          max-height: 90px;
          white-space: pre-wrap;
        }
      `}</style>
    </>
  );
};

export default MemoSection;
