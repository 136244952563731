import React, { useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import InputV2, {
  defaultInputError,
  InputError,
} from 'src/components/common/input-v2';
import {
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
  PageType,
} from 'src/hooks/use-create-badge-state/index';

const ActionRule: React.FC = () => {
  const [state, setState, error, setError, , , pageType] = useContext(
    CreateBadgeStateContext,
  );

  const [_isCount, setCount] = useState<boolean | null>(null);

  const isViewPage = pageType === PageType.VIEW;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <div className="wrap">
        <div className="header">
          <div className="flex">
            <div className="title h7-bold">
              Rule<span className="LG b1-medium">*</span>
            </div>
            <div
              data-tip="Create Rules for the Action(s) you created.<br/><br/>You can set the threshold value for the eligibility requirements. If you have created more than 1 action above, the system will either count the total number of actions generated or measure the sum of specific values.<br/><br/>For example, if you have selected to 'Count' the # of USDT 'Transfer' and set the threshold value to '10', the system will verify users who have conducted transfer action more than 10 times."
              className="tooltip"
            ></div>
          </div>
        </div>
        <div className="body no-scroll">
          <div className="flex">
            {state?.eligibilityRule.actionUnits.map((action, i) => {
              return (
                <div key={action._id}>
                  {i > 0 && <div className="plus ">+</div>}
                  <div className="action">
                    Action<span>{i + 1}</span>
                  </div>
                </div>
              );
            })}
            <div className="compare h5-regular">≥</div>
            <div className="threshold">
              <InputV2
                additionalRegex={/^(\s*|\d+)$/}
                value={state.eligibilityRule.required || ''}
                setValue={(val) => {
                  setState((state) => {
                    return {
                      ...state,
                      eligibilityRule: {
                        ...state.eligibilityRule,
                        required: val,
                      },
                    };
                  });
                }}
                error={error.eligibilityRule.required as InputError}
                setError={(val: string) => {
                  setError((state) => ({
                    ...state,
                    eligibilityRule: {
                      ...state.eligibilityRule,
                      required: val
                        ? (
                            CreateBadgeStateValidation.eligibilityRule
                              .required as any
                          )[val]
                        : defaultInputError,
                    },
                  }));
                }}
                errorArr={CreateBadgeStateValidation.eligibilityRule.required}
                placeholder="Threshold"
                // errorArr={CreateBadgeStateValidation.title}
                disabled={isViewPage}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .wrap {
          width: calc(100% - 40px);
          border-radius: 20px;
          background: var(--BT80);
          padding: 20px;
        }
        .flex {
          display: flex;
          align-items: center;
        }
        .header {
          margin-bottom: 12px;
        }
        .header .title {
          display: flex;
        }
        .body {
          overflow: auto;
          width: 100%;
        }
        .body .flex {
          gap: 12px;
          align-items: center;
          width: max-content;
        }
        .plus {
          font-size: 1.9rem;
          font-weight: bold;
        }
        .action {
          color: var(--G25);
          font-size: 2rem;
          line-height: 3rem;
          font-weight: bold;
        }
        .action span {
          display: inline-block;
          margin-left: 8px;
          background: rgba(67, 44, 148, 1);
          padding: 3px 9.5px;
          border-radius: 100%;
          font-size: 1.2rem;
          line-height: 1.8rem;
          vertical-align: text-bottom;
          margin-bottom: 2px;
        }
        .compare {
          background: var(--G);
          display: flex;
          border-radius: 100px;
          margin-left: 10px;
          margin-right: 10px;
          width: 48px;
          height: 48px;
          justify-content: center;
          align-items: center;
        }
        .tooltip {
          display: inline-block;
          width: 20.5px;
          height: 23.5px;
          margin-right: 5.8px;
          background: url(/image/information-icon.png) left 3px no-repeat;
          background-size: 18.5px 18.5px;
          margin-top: -3px;
          margin-left: 1px;
        }
      `}</style>
    </>
  );
};

export default ActionRule;
