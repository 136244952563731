// /notices/?OnlyLatest=true

import { SWRConfiguration, SWRResponse } from 'swr';
import { API } from './api';
import { API_CONST } from './const';
import { _useSWR } from './swr';

export interface Block {
  latestSyncedBH: number;
  chain: string;
}

export class BlocksAPI extends API {
  constructor() {
    super(API_CONST.ROOT_URL, API_CONST.BLOCKS_API_URL);
  }

  static PATH = {
    GET_LATEST_SYNCED_BLOCK: `latest-synced`,
  };

  async getLastSyncedBlock(): Promise<Block[]> {
    const res = await this.get(BlocksAPI.PATH.GET_LATEST_SYNCED_BLOCK);
    return res;
  }

  useGetLastSyncedBlock(
    options: SWRConfiguration = {},
  ): SWRResponse<Block[], Error> {
    return _useSWR<Block[]>(
      BlocksAPI.PATH.GET_LATEST_SYNCED_BLOCK,
      async () => new BlocksAPI().getLastSyncedBlock(),
      options,
    );
  }
}
