import { API } from './api';
import { API_CONST } from './const';

export class AuthAPI extends API {
  constructor() {
    super(API_CONST.ROOT_URL, API_CONST.AUTH_API_URL);
  }

  static PATH = {
    SIGN: `sign`,
    IS_AUTH: 'is-auth',
  };

  async getSignMessage(address: string): Promise<{
    signMessage: string;
  }> {
    const res = await this.get(`${AuthAPI.PATH.SIGN}?address=${address}`);
    return res;
  }

  async signIn(params: {
    signResult: string;
    signMessage: string;
    address: string;
  }): Promise<{
    accessToken: string;
  }> {
    const res = await this.post(AuthAPI.PATH.SIGN, params);
    return res;
  }

}
