import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from 'src/components/common/select';
import { errorToast } from 'src/components/common/toast';
import { ABI } from 'src/hooks/use-create-badge-state/abi-state';
import { ActionType } from 'src/hooks/use-create-badge-state/action';
import {
  ActionAggregationType,
  defaultActionAggregation,
} from 'src/hooks/use-create-badge-state/action-aggregation';
import { CreateBadgeStateContext } from 'src/hooks/use-create-badge-state/index';

const AcionTypeSelector: React.FC<{ index: number; isDisabled: boolean }> = ({
  index,
  isDisabled,
}) => {
  const [state, setState, error, setError] = useContext(
    CreateBadgeStateContext,
  );
  const currentAction = (state?.eligibilityRule.actionUnits)[index];
  const getOptions = useCallback(() => {
    if (currentAction.action.name) {
      return currentAction.action.inputs
        .filter((input) => {
          return input.type.startsWith('uint') || input.type.startsWith('int');
        })
        .map((input) => ({
          label: ABI.getParamLabelComp(input),
          value: input.name,
        }));
    } else {
      return [];
    }
  }, [currentAction.action.name, currentAction.action.inputs]);
  const [options, setOptions] = useState<
    {
      label: any;
      value: any;
    }[]
  >(getOptions());

  const actionType =
    currentAction.aggregations[0] === undefined
      ? null
      : currentAction.aggregations[0].type;

  const initSumOptions = useCallback(() => {
    if (currentAction.aggregations.length === 0) {
      setOptions([]);
      setTimeout(() => {
        if (currentAction.action.name && currentAction.userEOAField) {
          setOptions(getOptions());
        }
      }, 0);
    }
  }, [currentAction, getOptions]);

  useEffect(() => {
    initSumOptions();
  }, [
    currentAction.action.name,
    currentAction.action.inputs,
    currentAction.userEOAField,
    initSumOptions,
  ]);

  useEffect(() => {
    if (
      currentAction.aggregations.length > 0 &&
      currentAction.aggregations[0].type !== ActionAggregationType.SUM
    ) {
      initSumOptions();
    }
  }, [currentAction.aggregations, initSumOptions]);

  const _isSelectsDisabled = isDisabled || currentAction.type === ActionType.TX;

  return (
    <>
      <div className="wrap">
        <div className="action-type">
          <div
            className={`button ${
              actionType === ActionAggregationType.COUNT && 'active'
            }`}
            onClick={() => {
              if (!isDisabled) {
                setOptions([]);
                setState((state) => {
                  const newEligibilityRulesActionUnits = [
                    ...state?.eligibilityRule.actionUnits,
                  ];
                  newEligibilityRulesActionUnits[index].aggregations = [
                    {
                      ...defaultActionAggregation[ActionAggregationType.COUNT],
                    },
                  ];
                  return {
                    ...state,
                    eligibilityRule: {
                      ...state.eligibilityRule,
                      actionUnits: newEligibilityRulesActionUnits,
                    },
                  };
                });
              }
            }}
          >
            <p className="b1-bold">Count</p>
          </div>
          <div
            className={`button ${
              actionType === ActionAggregationType.SUM && 'active'
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (!isDisabled) {
                if (currentAction.type === ActionType.TX) {
                  errorToast('Count is the only allowed type for functions.');
                  return;
                }
                setOptions(getOptions());
                setState((state) => {
                  const newEligibilityRulesActionUnits = [
                    ...state?.eligibilityRule.actionUnits,
                  ];
                  newEligibilityRulesActionUnits[index].aggregations = [
                    {
                      type: ActionAggregationType.SUM,
                      field:
                        newEligibilityRulesActionUnits[index].aggregations[0]
                          ?.field || '',
                    },
                  ];
                  return {
                    ...state,
                    eligibilityRule: {
                      ...state.eligibilityRule,
                      actionUnits: newEligibilityRulesActionUnits,
                    },
                  };
                });
              }
            }}
          >
            <p className="b1-bold">Volume</p>
            <div className="volume-value">
              <Select
                disabled={_isSelectsDisabled}
                defaultValue={
                  currentAction.aggregations.length > 0 &&
                  !!currentAction.aggregations[0].field
                    ? (() => {
                        const input = currentAction.action.inputs.filter(
                          (input) =>
                            input.name === currentAction.aggregations[0].field,
                        )[0];
                        return {
                          label: ABI.getParamLabelComp(input),
                          value: input.name,
                        };
                      })()
                    : undefined
                }
                handleOnChange={(val) => {
                  setState((state) => {
                    const newEligibilityRulesActionUnits = [
                      ...state?.eligibilityRule.actionUnits,
                    ];
                    newEligibilityRulesActionUnits[index].aggregations = [
                      {
                        type: ActionAggregationType.SUM,
                        field: val,
                      },
                    ];
                    return {
                      ...state,
                      eligibilityRule: {
                        ...state.eligibilityRule,
                        actionUnits: newEligibilityRulesActionUnits,
                      },
                    };
                  });
                }}
                // error={error.projectCategory as InputError}
                // setError={(val: string) => {
                //   setError((state) => ({
                //     ...state,
                //     projectCategory: val
                //       ? (CreateBadgeStateValidation.projectCategory as any)[val]
                //       : defaultInputError,
                //   }));
                // }}
                placeholder="Select"
                isRequired={true}
                options={options}
                singleValueStyle={{
                  maxWidth: 108,
                }}
                menuStyle={{
                  minWidth: 280,
                }}
                // errorArr={CreateBadgeStateValidation.projectCategory}
                // disabled={isViewPage}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .wrap {
          width: 367.33px;
          border: 2px solid
            ${(error.eligibilityRule as any).actionUnits[index].aggregations
              ?.msg
              ? 'var(--R)'
              : 'var(--G400)'};
          border-radius: 16px;
          height: 136px;
          background: var(--G900);
        }
        .action-type {
          display: flex;
          height: inherit;
        }
        .button {
          width: 50%;
          display: flex;

          position: relative;
          padding: 11px;
        }
        .button:first-of-type {
          border-right: 2px solid var(--G400);
          align-items: center;
          justify-content: center;
        }
        .button:first-of-type.active {
          background: var(--G500);
          border-radius: 13px 0px 0px 13px;
        }
        .button:last-of-type {
          flex-direction: column;
          align-items: center;
          gap: 8px;
          justify-content: flex-end;
        }
        .button:last-of-type.active {
          background: var(--G500);
          border-radius: 0px 13px 13px 0px;
        }
        .button .volume-value {
          width: 164px;
        }
      `}</style>
    </>
  );
};

export default AcionTypeSelector;
