import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CreateBadgeStateContext,
  PageType,
} from 'src/hooks/use-create-badge-state';
import { ABI } from 'src/hooks/use-create-badge-state/abi-state';
import {
  ActionRule,
  ActionRuleUIType,
  defaultActionRuleValue,
  IActionRule,
} from 'src/hooks/use-create-badge-state/action-rule';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';
import InputV2 from '../input-v2';
import Select from '../select';
import { errorToast } from '../toast';

const Criteria: React.FC<{
  index: number;
  currentCriteria: IActionRule;
  criteriaIndex: number;
  _setFilter: Dispatch<SetStateAction<IActionRule[]>>;
}> = ({ index, currentCriteria, criteriaIndex, _setFilter }) => {
  const [state, setState, error, setError, abiState, setAbiState, pageType] =
    useContext(CreateBadgeStateContext);
  const isViewPage = pageType === PageType.VIEW;
  const [prevField, setPrevField] = useState<string>('');
  const currentAction = state?.eligibilityRule.actionUnits[index];
  const getRuleOptions = useCallback(() => {
    const equalOption = [
      {
        label: ActionRuleUIType.EQUAL,
        value: ActionRuleUIType.EQUAL,
      },
    ];
    const rangeOption = [
      {
        label: ActionRuleUIType.RANGE,
        value: ActionRuleUIType.RANGE,
      },
    ];
    if (currentCriteria.field && currentCriteria._fieldType) {
      if (currentCriteria.field === 'blockNumber') {
        return [...rangeOption];
      } else if (
        currentCriteria._fieldType.startsWith('uint') ||
        currentCriteria._fieldType.startsWith('int')
      ) {
        return [
          ...equalOption,
          {
            label: ActionRuleUIType.GTE,
            value: ActionRuleUIType.GTE,
          },
          ...rangeOption,
        ];
      } else {
        return [...equalOption];
      }
    }
    return [];
  }, [currentCriteria]);

  const [ruleOptions, setRuleOptions] = useState<
    { label: string; value: string }[]
  >(getRuleOptions());

  const getParameterOptions = useCallback(() => {
    const abi = abiState[currentAction.contract.toLowerCase()]?.abi;
    if (abi) {
      return [
        {
          label: 'General',
          options: [
            {
              label: (
                <span
                  style={{
                    color: 'rgba(180, 178, 197, 1)',
                  }}
                >
                  Block Number
                </span>
              ),
              value: {
                name: 'blockNumber',
                type: 'uint',
              },
            },
          ],
        },
        {
          label: 'Parameter',
          options: currentAction.action.inputs
            .filter((input) => {
              return !input.type.includes('[') || !input.type.includes('tuple');
            })
            .map((input) => ({
              label: ABI.getParamLabelComp(input),
              value: {
                name: input.name,
                type: input.type,
              },
            })),
        },
      ];
    } else {
      return [];
    }
  }, [abiState, currentAction.contract, currentAction.action.inputs]);

  useEffect(() => {
    if (prevField && currentCriteria.field !== prevField) {
      setRuleOptions([]);
      setTimeout(() => {
        setRuleOptions(getRuleOptions());
      }, 0);
    }
  }, [prevField, currentCriteria.field]);

  return (
    <>
      <div className="wrap">
        <div className="parameter">
          <Select
            defaultValue={
              currentCriteria.field
                ? (() => {
                    for (const options of getParameterOptions()) {
                      for (const option of options.options) {
                        if (
                          option.value.name === currentCriteria.field &&
                          option.value.type === currentCriteria._fieldType
                        ) {
                          return option;
                        }
                      }
                    }
                  })()
                : undefined
            }
            handleOnChange={(val: any) => {
              _setFilter((filter) => {
                const newFilter = [...filter];
                newFilter[criteriaIndex] = {
                  ...newFilter[criteriaIndex],
                  ...defaultActionRuleValue[ActionRuleUIType.EQUAL],
                  field: val.name,
                  _fieldType: val.type,
                };
                return newFilter;
              });
              setPrevField(val);
            }}
            // error={error.projectCategory as InputError}
            // setError={(val: string) => {
            //   setError((state) => ({
            //     ...state,
            //     projectCategory: val
            //       ? (CreateBadgeStateValidation.projectCategory as any)[val]
            //       : defaultInputError,
            //   }));
            // }}
            placeholder="Select Parameter"
            options={getParameterOptions()}
            // errorArr={CreateBadgeStateValidation.projectCategory}
            disabled={isViewPage}
            singleValueStyle={{
              maxWidth: 168,
            }}
            menuStyle={{
              minWidth: 280,
            }}
          />
        </div>
        <div className="is b1-medium G25">is</div>
        <div className="rule">
          <div className="rule-type">
            <Select
              defaultValue={
                currentCriteria.type
                  ? (() => {
                      for (const option of getRuleOptions()) {
                        if (option.label === currentCriteria.type) {
                          return option;
                        }
                      }
                    })()
                  : undefined
              }
              handleOnChange={(val: any) => {
                _setFilter((filter) => {
                  const newFilter = [...filter];
                  newFilter[criteriaIndex] = {
                    ...filter[criteriaIndex],
                    value:
                      defaultActionRuleValue[val as ActionRuleUIType].value,
                    type: val,
                  };
                  return newFilter;
                });
              }}
              // error={error.projectCategory as InputError}
              // setError={(val: string) => {
              //   setError((state) => ({
              //     ...state,
              //     projectCategory: val
              //       ? (CreateBadgeStateValidation.projectCategory as any)[val]
              //       : defaultInputError,
              //   }));
              // }}
              placeholder="Select Rule"
              options={ruleOptions}
              // errorArr={CreateBadgeStateValidation.projectCategory}
              disabled={isViewPage}
            />
          </div>
          {currentCriteria.type === ActionRuleUIType.EQUAL ? (
            <div className="value">
              <InputV2
                value={currentCriteria.value as string}
                setValue={(val) => {
                  _setFilter((filter) => {
                    const newFilter = [...filter];
                    const newValue = val;
                    newFilter[criteriaIndex] = {
                      ...filter[criteriaIndex],
                      value: newValue,
                    };
                    return newFilter;
                  });
                }}
                // error={error.title as InputError}
                // setError={(val: string) => {
                //   setError((state) => ({
                //     ...state,
                //     title: val
                //       ? (CreateBadgeStateValidation.title as any)[val]
                //       : defaultInputError,
                //   }));
                // }}
                // errorArr={CreateBadgeStateValidation.title}
                // isRequired={true}
                placeholder="Value"
                additionalRegex={
                  currentCriteria._fieldType.startsWith('uint') ||
                  currentCriteria._fieldType.startsWith('int')
                    ? /^(\s*|\d+)$/
                    : undefined
                }
                disabled={isViewPage}
              />
            </div>
          ) : currentCriteria.type === ActionRuleUIType.GTE ? (
            <div className="value">
              <InputV2
                value={(currentCriteria.value as any).gte}
                setValue={(val) => {
                  _setFilter((filter) => {
                    const newFilter = [...filter];
                    const newValue = {
                      gte: val,
                    };
                    newFilter[criteriaIndex] = {
                      ...filter[criteriaIndex],
                      value: newValue,
                    };
                    return newFilter;
                  });
                }}
                // error={error.title as InputError}
                // setError={(val: string) => {
                //   setError((state) => ({
                //     ...state,
                //     title: val
                //       ? (CreateBadgeStateValidation.title as any)[val]
                //       : defaultInputError,
                //   }));
                // }}
                // errorArr={CreateBadgeStateValidation.title}
                // isRequired={true}
                placeholder="Value"
                additionalRegex={/^(\s*|\d+)$/}
                disabled={isViewPage}
              />
            </div>
          ) : currentCriteria.type === ActionRuleUIType.RANGE ? (
            <div className="range flex">
              <InputV2
                value={(currentCriteria.value as any).gte}
                setValue={(val) => {
                  _setFilter((filter) => {
                    const newFilter = [...filter];
                    const newValue = {
                      ...(filter[criteriaIndex].value as {
                        gte: string;
                        lte: string;
                      }),
                      gte: val,
                    };
                    newFilter[criteriaIndex] = {
                      ...filter[criteriaIndex],
                      value: newValue,
                    };
                    return newFilter;
                  });
                }}
                // error={error.title as InputError}
                // setError={(val: string) => {
                //   setError((state) => ({
                //     ...state,
                //     title: val
                //       ? (CreateBadgeStateValidation.title as any)[val]
                //       : defaultInputError,
                //   }));
                // }}
                // errorArr={CreateBadgeStateValidation.title}
                // isRequired={true}
                placeholder="Min value"
                additionalRegex={/^(\s*|\d+)$/}
                disabled={isViewPage}
              />
              <div className="b1-medium G25">and</div>
              <InputV2
                value={(currentCriteria.value as any).lte}
                setValue={(val) => {
                  _setFilter((filter) => {
                    const newFilter = [...filter];
                    const newValue = {
                      ...(filter[criteriaIndex].value as {
                        gte: string;
                        lte: string;
                      }),
                      lte: val,
                    };
                    newFilter[criteriaIndex] = {
                      ...filter[criteriaIndex],
                      value: newValue,
                    };
                    return newFilter;
                  });
                }}
                // error={error.title as InputError}
                // setError={(val: string) => {
                //   setError((state) => ({
                //     ...state,
                //     title: val
                //       ? (CreateBadgeStateValidation.title as any)[val]
                //       : defaultInputError,
                //   }));
                // }}
                // errorArr={CreateBadgeStateValidation.title}
                // isRequired={true}
                placeholder="Max value"
                additionalRegex={/^(\s*|\d+)$/}
                disabled={isViewPage}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        {!isViewPage && (
          <div
            className="delete"
            onClick={() => {
              _setFilter((filter) => {
                const newFilter = [...filter];
                newFilter.splice(criteriaIndex, 1);
                return newFilter;
              });
            }}
          ></div>
        )}
      </div>
      <style jsx>
        {`
          .wrap,
          .flex,
          .rule {
            display: flex;
            gap: 8px;
            align-items: center;
          }

          .wrap {
            margin-bottom: 20px;
          }

          .parameter {
            width: 220px;
          }

          .is {
            width: 10px;
          }

          .rule {
            width: 431px;
          }

          .rule-type {
            width: 431px;
          }

          .value {
            min-width: 253px;
          }

          .range {
            min-width: 283px;
          }

          .delete {
            width: 32px;
            height: 32px;
            background: url(/image/delete-icon.png) center center no-repeat;
            background-size: 32px 32px;
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

const AdvancedCriteriaModal: React.FC<{
  index: number;
  handleClose: () => void;
  handleSubmit: (contract: string, tokenId: string) => void;
}> = ({ index, handleClose = () => {}, handleSubmit }) => {
  const [state, setState, , , , , pageType] = useContext(
    CreateBadgeStateContext,
  );
  const currentAction = state?.eligibilityRule.actionUnits[index];
  const [_filter, _setFilter] = useState<IActionRule[]>(
    currentAction.filters.length === 0
      ? [new ActionRule()]
      : currentAction.filters,
  );

  const isViewPage = pageType === PageType.VIEW;

  const isLastFilterGotAllValue =
    _filter.length <= 0 ||
    (_filter[_filter.length - 1].field &&
      _filter[_filter.length - 1].type &&
      Object.values(_filter[_filter.length - 1].value).filter((val) => !!val)
        .length > 0);

  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h6-bold WT100">Advanced Criteria</p>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <p className="desc b2-regular G25">
          This is a process to specify the action by adding criteria for each
          parameter. Only actions that match the criteria are counted or
          measured as valid actions. If you want to count or measure only
          actions with specific parameter conditions in the eligibility rule,
          set the parameters below.
          <br />
          <br />
          Setting advanced criteria is OPTIONAL. When entering the value of
          parameters, please enter it according to the unit displayed in the
          eventlog. For example, If you want to add the amount of tokens, please
          che ck the token decimal in advance.
        </p>
        <div className="body scroll">
          <div className="criteria">
            {_filter.map((rule, i) => {
              const currentCriteria = _filter[i];
              return (
                <Criteria
                  key={rule._id}
                  index={index}
                  currentCriteria={currentCriteria}
                  criteriaIndex={i}
                  _setFilter={_setFilter}
                />
              );
            })}
          </div>
          <div
            className={`button cap1-medium P25 ${
              (isViewPage || !isLastFilterGotAllValue) && 'disabled'
            }`}
            onClick={() => {
              if (!isViewPage && isLastFilterGotAllValue) {
                _setFilter((state) => {
                  return [...state, new ActionRule()];
                });
              }
            }}
          >
            + Add criteria
          </div>
        </div>
        {!isViewPage && (
          <div className="button-wrap">
            <Button
              label="Save"
              buttonStyle={ButtonStyle.SECONDARY}
              buttonSize={ButtonSize.M}
              handleClick={() => {
                try {
                  let isErrorExist = false;
                  for (const filter of _filter) {
                    if (!filter.field || !filter.type) {
                      isErrorExist = true;
                      break;
                    }
                    if (
                      filter.type === ActionRuleUIType.EQUAL &&
                      !filter.value
                    ) {
                      isErrorExist = true;
                      break;
                    }
                    if (
                      filter.type === ActionRuleUIType.GTE &&
                      !(filter.value as any).gte
                    ) {
                      isErrorExist = true;
                      break;
                    }
                    if (
                      filter.type === ActionRuleUIType.RANGE &&
                      !(filter.value as any).gte &&
                      !(filter.value as any).lte
                    ) {
                      isErrorExist = true;
                      break;
                    }
                  }

                  if (isErrorExist) {
                    throw new Error("Required field can't be blank.");
                  }

                  // Save
                  setState((state) => {
                    const newEligibilityRulesActionUnits = [
                      ...state?.eligibilityRule.actionUnits,
                    ];
                    newEligibilityRulesActionUnits[index].filters = _filter;
                    return {
                      ...state,
                      eligibilityRule: {
                        ...state.eligibilityRule,
                        actionUnits: newEligibilityRulesActionUnits,
                      },
                    };
                  });
                  handleClose();
                } catch (e: any) {
                  errorToast(e.message);
                }
              }}
              style={{
                margin: 0,
              }}
            />
          </div>
        )}
      </div>
      <style jsx>{`
        .container {
          width: 720px;
          background: var(--G900);
        }
        .header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 24px;
        }

        p.desc {
          margin-bottom: 20px;
        }

        div.body {
        }

        .criteria {
          margin-bottom: 20px;
        }
        .criteria:last-of-tpye {
          margin-bottom: 24px;
        }

        .button {
          width: fit-content;
          background: var(--BT15);
          padding: 8px 12px;
          border: 1px solid var(--G700);
          border-radius: 8px;
          align-items: center;
          cursor: pointer;
        }

        .button.disabled {
          cursor: not-allowed;
          color: var(--B600);
        }
        .button-wrap {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 24px;
        }
      `}</style>
    </>
  );
};

export default AdvancedCriteriaModal;
