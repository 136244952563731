import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import useCopyClipboard from 'src/hooks/use-copy-clipboard';

const CopyDom: React.FC<{
  text: string;
  children: React.ReactNode;
}> = (props) => {
  const { text, children } = props;
  const [, copy] = useCopyClipboard(2000);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <span
        onClick={() => {
          copy(text);
        }}
      >
        {children}
      </span>

      <style jsx>
        {`
          span {
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};

export default CopyDom;
