/* eslint-disable @next/next/next-script-for-ga */
import Head from 'next/head';
import React from 'react';
import { ROUTE } from 'src/apis/const';

const HeadDom: React.FC<{
  title?: string;
  description?: string;
  url?: string;
  ogImage?: string;
  curRoute?: string;
}> = (props) => {
  const {
    title = `Noox.world 👑 | Proof of achievements for your Web3 life`,
    description = `Noox is a place where you can mint Web3 achievement as soulbound NFTs like “badges”. Owning these badges will unlock new opportunities in the Web3-verse.`,
    url = 'https://noox.world',
    ogImage = 'https://www.noox.world/og_image.png',
    curRoute = '',
  } = props;

  return (
    <Head>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.GA_KEY}');
          `,
        }}
      />
      <title>{title}</title>
      {curRoute === ROUTE.PROJECTS ? (
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      ) : (
        <meta
          name="viewport"
          content="width=1200px, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      )}

      <meta name="description" content={description} />

      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="noox.world" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={ogImage} />
      <link rel="icon" href="/favicon.ico" />
    </Head>
  );
};

export const DefaultHeadDom: React.FC = () => {
  return <HeadDom />;
};

export default HeadDom;
