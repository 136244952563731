import Image from 'next/image';
import React, { useMemo } from 'react';
import BadgeChip from './badge-chip';

import { minidenticon } from 'minidenticons';

interface Props {
  thumbnailUrl: string;
  category: string;
  size: 'sm' | 'lg';
  isClaimed: boolean;
  username?: string;
}

const sizeStyle: Record<
  string,
  {
    imageSize: number;
    badgeChipSize: 'small' | undefined;
    thumbWidth: number;
    thumbHeight: number;
  }
> = {
  sm: {
    imageSize: 90,
    badgeChipSize: 'small',
    thumbWidth: 90,
    thumbHeight: 90,
  },
  lg: {
    imageSize: 135,
    badgeChipSize: undefined,
    thumbWidth: 208,
    thumbHeight: 162,
  },
};
const MinidenticonImg = ({
  username,
  saturation = 50,
  lightness = 50,
  ...props
}: any) => {
  const svgURI = useMemo(
    () =>
      'data:image/svg+xml;utf8,' +
      encodeURIComponent(minidenticon(username, saturation, lightness)),
    [username, saturation, lightness],
  );
  return (
    <img
      src={svgURI}
      alt={username}
      style={{ backgroundColor: 'white' }}
      {...props}
    />
  );
};

const BadgeThumbnail: React.FC<Props> = ({
  thumbnailUrl,
  category,
  isClaimed,
  size,
  username,
}) => {
  const { imageSize, badgeChipSize, thumbWidth, thumbHeight } = sizeStyle[size];
  return (
    <>
      <div className={`thumbnail ${isClaimed ? 'claimed' : ''}`}>
        <div className="outerglow blend-image"></div>
        <div className="innerglow blend-image"></div>
        <div className="glass blend-image"></div>
        <div className="clip">
          {thumbnailUrl !== '' ? (
            <Image
              src={thumbnailUrl}
              alt={thumbnailUrl}
              width={`${imageSize}px`}
              height={`${imageSize}px`}
              objectFit="cover"
              className="rounded-full image"
            />
          ) : (
            <>
              <MinidenticonImg username={username}></MinidenticonImg>
            </>
          )}
          <div className="bg"></div>
        </div>
        <div className="blur blend-image"></div>
        {category !== '' && (
          <div className="badgeChip">
            <BadgeChip label={category} size={badgeChipSize} />
          </div>
        )}
      </div>
      <style jsx>{`
        div.thumbnail {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          ${size === 'sm'
            ? `
              width: 90px;
              height: 90px;
              margin: 0 auto 4px;
            `
            : `
              width: 208px;
              height: 162px;
              margin: 0 auto 8px;
            `}
        }
        div.thumbnail > div.clip {
          display: flex;
          background: none;
          ${size === 'sm'
            ? `
              width: 58px;
              height: 58px;
              margin-top: 16px;
            `
            : `
              width: 100px;
              height: 100px;
              margin-top: 31px;
            `}
        }
        div.thumbnail > div.clip .image {
        }
        div.thumbnail > div.clip > div.bg {
          position: absolute;
          width: 100%;
          height: 100%;
          mix-blend-mode: screen;
        }
        div.thumbnail > .blend-image {
          width: ${thumbWidth}px;
          height: ${thumbHeight}px;
          position: absolute;
        }
        div.thumbnail > .outerglow {
          background: url('/image/${size}_badge_4_outerglow_off.png') center
            center no-repeat;
          background-size: ${thumbWidth}px ${thumbHeight}px;
          mix-blend-mode: screen;
          z-index: 4;
        }
        div.thumbnail.claimed > .outerglow {
          background: url('/image/${size}_badge_4_outerglow.png') center center
            no-repeat;
          background-size: ${thumbWidth}px ${thumbHeight}px;
        }
        div.thumbnail > .innerglow {
          background: url('/image/${size}_badge_3_innerglow_off.png') center
            center no-repeat;
          background-size: ${thumbWidth}px ${thumbHeight}px;
          mix-blend-mode: lighten;
          z-index: 3;
        }
        div.thumbnail.claimed > .innerglow {
          background: url('/image/${size}_badge_3_innerglow.png') center center
            no-repeat;
          background-size: ${thumbWidth}px ${thumbHeight}px;
        }
        div.thumbnail > .glass {
          background: url('/image/${size}_badge_2_glass_off.png') center center
            no-repeat;
          background-size: ${thumbWidth}px ${thumbHeight}px;
          mix-blend-mode: hard-light;
          opacity: 0.8;
          z-index: 2;
        }
        div.thumbnail.claimed > .glass {
          background: url('/image/${size}_badge_2_glass.png') center center
            no-repeat;
          background-size: ${thumbWidth}px ${thumbHeight}px;
        }
        div.thumbnail > .glass::before {
          mix-blend-mode: darken;
          content: '';
          display: block;
          position: absolute;
          border-radius: 100%;

          ${size === 'sm'
            ? `
            box-shadow: 0px 0px 10px 0px #505050 inset;
            height: 58px;
            width: 58px;
            top: 16px;
            left: 16px;
          `
            : `
            box-shadow: 0px 0px 5px 0px #505050 inset;
            height: 100px;
            width: 100px;
            top: 31px;
            left: 54px;
          `}
        }

        ${size === 'sm'
          ? `
          div.thumbnail.claimed > .glass::before {
            box-shadow: 0px 0px 10px 0px #4a318a inset;
            border-radius: 100%;
          }
          div.thumbnail > .clip::after {
            box-shadow: 0px 0px 12px 0px #4a318a inset;
            mix-blend-mode: darken;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            border-radius: 100%;
          }
        `
          : `
          div.thumbnail.claimed > .glass::before,
          div.thumbnail.claimed > .glass::after {
            box-shadow: 0px 0px 5px 0px rgba(50, 5, 104, 1) inset;
          }
          div.thumbnail > .clip::before,
          div.thumbnail > .clip::after {
            box-shadow: 0px 0px 30px 0px rgba(55, 32, 115, 1) inset;
            mix-blend-mode: darken;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
          }
        `}

        div.thumbnail > .blur {
          background: url('/image/${size}_badge_1_blur_off.png') center center
            no-repeat;
          background-size: ${thumbWidth}px ${thumbHeight}px;
          opacity: 0.7;
          mix-blend-mode: screen;
          z-index: 0;
        }

        div.thumbnail.claimed > .blur {
          background: url('/image/${size}_badge_1_blur.png') center center
            no-repeat;
          background-size: ${thumbWidth}px ${thumbHeight}px;
        }

        div.thumbnail > div.badgeChip {
          margin-top: -9px;
          z-index: 5;
        }
      `}</style>
    </>
  );
};

export default React.memo(BadgeThumbnail);
