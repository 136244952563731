import React from 'react';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';

const TxFeeErrorModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose }) => {
  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h6-bold">Sorry, your fund is insufficient</p>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <div className="body">
          <p className="b1-regular">
            Your transaction failed due to lack of balance. <br />
            Please check your balance and try again.
          </p>
          <ul>
            <li>
              <Button
                label="Confirmed"
                buttonStyle={ButtonStyle.PRIMARY}
                buttonSize={ButtonSize.M}
                handleClick={handleClose}
              />
            </li>
          </ul>
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 520px;
          text-align: center;
          height: 185px;
        }
        p {
          margin-bottom: 24px;
        }
        .close-button {
          position: absolute;
          top: 40px;
          right: 40px;
        }
        span {
        }
        ul {
        }
        li:first-of-type {
          margin-bottom: 16px;
        }
      `}</style>
    </>
  );
};

export default TxFeeErrorModal;
