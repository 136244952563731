import React, { useContext, useState } from 'react';
import InputV2, { InputError } from 'src/components/common/input-v2';
import { ABI } from 'src/hooks/use-create-badge-state/abi-state';
import { ActionType } from 'src/hooks/use-create-badge-state/action';
import {
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
} from 'src/hooks/use-create-badge-state/index';

const AddABISection: React.FC<{ index: number }> = ({ index }) => {
  const [state, setState, error, , abiState, setAbiState] = useContext(
    CreateBadgeStateContext,
  );

  const currentAction = (state?.eligibilityRule.actionUnits)[index];
  const [abi, setAbi] = useState<string>(
    abiState[currentAction.contract.toLowerCase()]
      ? JSON.stringify(abiState[currentAction.contract.toLowerCase()]?.abi!.abi)
      : '',
  );

  return (
    <>
      <div className="input">
        <InputV2
          value={abi}
          setValue={(val) => {
            try {
              const _parsedJSON = JSON.parse(val);
              const _abi = new ABI(_parsedJSON);
              if (_abi) {
                const lAddress = currentAction.contract.toLowerCase();
                setAbiState((state) => ({
                  ...state,
                  [lAddress]: {
                    abi: _abi,
                    isCustomABI: true,
                  },
                }));
              }
            } catch (e) {
              console.log(e);
              const lAddress = currentAction.contract.toLowerCase();
              setAbiState((state) => ({
                ...state,
                [lAddress]: null,
              }));
              setState((state) => {
                const newEligibilityRulesActionUnits = [
                  ...state?.eligibilityRule.actionUnits,
                ];
                newEligibilityRulesActionUnits[index].action = {
                  type: ActionType.TX,
                  name: '',
                  inputs: [],
                };
                newEligibilityRulesActionUnits[index].userEOAField = '';
                newEligibilityRulesActionUnits[index].aggregations = [];
                return {
                  ...state,
                  eligibilityRule: {
                    ...state.eligibilityRule,
                    actionUnits: newEligibilityRulesActionUnits,
                  },
                };
              });
            } finally {
              setAbi(val);
            }
          }}
          placeholder="Add ABI"
          error={
            (error.eligibilityRule as any).actionUnits[index]
              .action as InputError
          }
          errorArr={
            CreateBadgeStateValidation.eligibilityRule.actionUnits.action
          }
        />
        <p
          className="cap1-light"
          style={{
            color: 'rgba(180, 178, 197, 1)',
            marginTop: 4,
          }}
        >
          ABI Fetching failed.
        </p>
      </div>
      <style jsx>{`
        .input {
          margin-bottom: 12px;
        }
      `}</style>
    </>
  );
};

export default AddABISection;
