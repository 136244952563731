import React from 'react';

interface Props {
  width?: number;
  color?: 'white' | 'primary';
}
// SPINNER
// const Loading: React.FC<Props> = (props) => {
//   const { width = 42 } = props;
//   return (
//     <>
//       <div>
//         <div>
//           <CircularProgress color="inherit" size={width} />
//         </div>
//       </div>

//       <style jsx>{`
//         div {
//           display: flex;
//           height: 100vh;
//           justify-content: center;
//           align-items: center;
//         }
//       `}</style>
//     </>
//   );
// };

const Loading: React.FC<Props> = (props) => {
  const { color = 'white' } = props;
  return (
    <>
      <div className="loader-wrap">
        <div className="loader">
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
        </div>
      </div>

      <style jsx>{`
        .loader-wrap {
          position: relative;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .loader {
        }

        .bubble {
          animation: expand 0.75s ease-in-out infinite;
          border-radius: 20px;
          display: inline-block;
          transform-origin: center center;
          margin: 0 3px;
          width: 10px;
          height: 10px;
          background: var(--${color === 'white' ? 'WT100' : 'P500'});
        }

        .bubble:nth-child(1) {
          margin-left: 0px;
        }

        .bubble:nth-child(2) {
          animation-delay: 180ms;
        }

        .bubble:nth-child(3) {
          animation-delay: 360ms;
          margin-right: 0px;
        }

        @keyframes expand {
          0% {
            transform: scale(1);
            opacity: 0.5;
          }
          25% {
            transform: scale(1.25);
            opacity: 1;
          }
          100% {
            opacity: 0.5;
          }
        }
      `}</style>
    </>
  );
};

export default Loading;
