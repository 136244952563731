import { CryptoAsset, GetPfpAssetsResponse } from 'src/entity/asset';
import { CollectionAchievements } from 'src/entity/collection';
import { Pagination } from 'src/entity/common';
import { Interest } from 'src/entity/interest';
import { User } from 'src/entity/user';
import { Provider } from 'src/modules/web3_modal';
import { decompressToBase64, isObjectEmpty } from 'src/utils';
import { KeyedMutator, SWRConfiguration, SWRResponse } from 'swr';
import {
  Badge,
  BadgeDetailed,
  getBHLabel,
  getBadgeTierInfo,
} from '../entity/badge';
import { API } from './api';
import { BadgeGroupsAPI } from './badge-groups-api';
import { BadgesAPI } from './badges-api';
import { CollectionsAPI, GetCollectionsResponse } from './collections-api';
import { API_CONST, TEST_ADDRESS } from './const';
import { BearerTokenSession, ClaimTxHashSession } from './local-storage';
import { NotableBadgesAPI } from './notable-badges-api';
import { _useSWR, _useSWRInfinite } from './swr';
import { GetUserBadgesResponse, UserBadgesAPI } from './user-badges-api';

type GetBadgesParams = {
  category?: string;
} & Partial<Pagination>;
interface GetBadgesResponse extends Pagination {
  badges: Badge[];
  totalClaimCount: number;
}

type UpdateProfileParams = Pick<Partial<User>, 'nickname' | 'bio'> & {
  interests?: number[];
  primaryBadge?: string;
  primaryPfp?: {
    contract: string;
    tokenId: string;
  };
};

export interface UpdateVisibleForProfileParam {
  badgeId: string;
  isVisible: boolean;
}

export class UsersAPI extends API {
  constructor() {
    super(API_CONST.ROOT_URL, API_CONST.USERS_API_URL);
  }

  static PATH = {
    GET_BADGES: () => `me/badges`,
    GET_BADGE: (badgeId: string) => `me/badges/${badgeId}`,
    GET_PROFILE_BADGES: (address: string) => `${address}/visible-claim-badges`,
    GET_MY_PROFILE_BADGES: () => `me/claim-badges`,
    GET_GROUP_BADGES: () => `me/badge-groups`,

    // Profile
    GET_PROFILE: () => `me/profile`,
    GET_PFP_ASSETS: () => `me/pfp-assets`,
    GET_INTERESTS: () => `me/interests`,
    UPDATE_PROFILE: () => `me/profile`,

    // Collection
    GET_COLLECTIONS: (): string => `me/${API_CONST.COLLECTIONS_API_URL}`,
    GET_COLLECTION: (collectionId: string): string =>
      `me/${API_CONST.COLLECTIONS_API_URL}/${collectionId}`,
    GET_COLLECTION_BADGES: (collectionId: string): string =>
      `me/${API_CONST.COLLECTIONS_API_URL}/${collectionId}/onboard-badges`,
    GET_MY_ACHIEVEMENT: (collectionId: string) =>
      `me/${API_CONST.COLLECTION_ACHIEVEMENTS_API_URL}/${collectionId}`,
    GET_USER_ACHIEVEMENT: (address: string, collectionId: string) =>
      `${address}/${API_CONST.COLLECTION_ACHIEVEMENTS_API_URL}/${collectionId}`,

    // Notable Badges
    GET_NOTABLE_BADGES: (): string => `me/${API_CONST.NOTABLE_BADGES_API_URL}`,
  };

  static SWR_PATH = {
    GET_BADGES: () =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_BADGES()}`,
    GET_BADGE: (badgeId: string) =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_BADGE(badgeId)}`,
    GET_PROFILE_BADGES: (address: string) =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_PROFILE_BADGES(address)}`,
    GET_MY_PROFILE_BADGES: () =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_MY_PROFILE_BADGES()}`,

    // Profile
    GET_PROFILE: () =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_PROFILE()}`,
    GET_PFP_ASSETS: () =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_PFP_ASSETS()}`,
    GET_INTERESTS: () =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_INTERESTS()}`,

    // Collections
    GET_COLLECTIONS: () =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_COLLECTIONS()}`,
    GET_COLLECTION: (collectionId: string) =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_COLLECTION(
        collectionId,
      )}`,
    GET_COLLECTION_BADGES: (collectionId: string) =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_COLLECTION_BADGES(
        collectionId,
      )}`,
    GET_MY_ACHIEVEMENT: (collectionId: string) =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_MY_ACHIEVEMENT(
        collectionId,
      )}`,
    GET_USER_ACHIEVEMENT: (address: string, collectionId: string) =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_USER_ACHIEVEMENT(
        address,
        collectionId,
      )}`,

    // Notable Badges
    GET_NOTABLE_BADGES: () =>
      `${API_CONST.USERS_API_URL}/${UsersAPI.PATH.GET_NOTABLE_BADGES()}`,
  };

  async getProfileBadges(
    query: string,
    address: string,
  ): Promise<GetBadgesResponse> {
    const res = await this.get(
      `${UsersAPI.PATH.GET_PROFILE_BADGES(address)}${query}`,
    );

    const badges = res.badges.map((badge: Badge) => ({
      ...badge,
      ...getBadgeTierInfo(badge),
    }));

    return {
      ...res,
      badges,
    };
  }

  async getMyProfileBadges(query: string): Promise<GetBadgesResponse> {
    const res = await this.get(
      `${UsersAPI.PATH.GET_MY_PROFILE_BADGES()}${query}`,
    );

    const badges = res.badges.map((badge: Badge) => ({
      ...badge,
      ...getBadgeTierInfo(badge),
    }));

    return {
      ...res,
      badges,
    };
  }

  async getBadges(params: GetBadgesParams, query: string): Promise<any> {
    try {
      const paramsString = !isObjectEmpty(params)
        ? `&category=${params.category}`
        : '';
      const res = await this.get(
        `${UsersAPI.PATH.GET_BADGES()}`,
        `${query}${paramsString}`,
      );
      const badges = res.badges.map((badge: Badge) => ({
        ...badge,
        ...getBadgeTierInfo(badge),
      }));

      return {
        ...res,
        badges,
      };
    } catch (e) {
      console.error(e);
    }
  }

  async getBadge(badgeId: string): Promise<BadgeDetailed> {
    const _url = UsersAPI.PATH.GET_BADGE(badgeId);
    const res = await this.get(_url);
    const logicArgs: Record<string, any> = decompressToBase64(
      res.badgeLogicDesc.logicArgs,
    );

    let newLogicArgs = { ...logicArgs };
    if (logicArgs.args.targets) {
      const methodNames = await new BadgesAPI().getMethods(
        logicArgs.args.targets.map((target: any) => target.method),
      );
      newLogicArgs = {
        ...logicArgs,
        args: {
          ...logicArgs.args,
          targets: logicArgs.args.targets.map((target: any) => ({
            ...target,
            method: methodNames[target.method],
          })),
        },
      };
    }

    const badgeTierInfo = getBadgeTierInfo(res);
    return {
      ...res,
      ...badgeTierInfo,
      attributes: decompressToBase64(res.attributes),
      badgeLogicDesc: {
        ...res.badgeLogicDesc,
        logicArgs: { ...newLogicArgs },
      },
      uncles: res.uncles.map((uncle: Badge) => ({
        ...uncle,
        ...getBadgeTierInfo(uncle),
      })),
      _bhLabel: getBHLabel(res.publishBH, res.deprecateBH),
    };
  }

  async getProfile(): Promise<User> {
    try {
      if (!BearerTokenSession.get()) {
        throw new Error('Unauthorized');
      }
      return await this.get(UsersAPI.PATH.GET_PROFILE());
    } catch (e) {
      console.error(e);
      Provider.removeConnectCachedProvider();
      BearerTokenSession.remove();
      ClaimTxHashSession.remove();
      throw e;
    }
  }

  async getPfpAssets(key: string): Promise<GetPfpAssetsResponse> {
    const res = await this.get(
      `${UsersAPI.PATH.GET_PFP_ASSETS()}${key}&cache=true`,
    );
    return res;
  }

  async getInterests(): Promise<Interest[]> {
    const res = await this.get(UsersAPI.PATH.GET_INTERESTS());
    return res.map((item: any) => ({
      ...item,
      id: item._id,
    }));
  }

  async getMyCollectionAchievement(
    collectionId: string,
  ): Promise<CollectionAchievements> {
    const res = await this.get(UsersAPI.PATH.GET_MY_ACHIEVEMENT(collectionId));
    return res;
  }

  async getUserCollectionAchievement(
    address: string,
    collectionId: string,
  ): Promise<CollectionAchievements> {
    const res = await this.get(
      UsersAPI.PATH.GET_USER_ACHIEVEMENT(address, collectionId),
    );
    return res;
  }

  async updateProfile(params: UpdateProfileParams): Promise<User> {
    const res = await this.put(UsersAPI.PATH.UPDATE_PROFILE(), params);
    return res;
  }

  async updateVisibleForProfile(
    badges: UpdateVisibleForProfileParam[],
  ): Promise<Badge[]> {
    const isSuccess = await this.put(
      `${UsersAPI.PATH.GET_MY_PROFILE_BADGES()}`,
      {
        badges,
      },
    );
    return isSuccess;
  }

  useGetProfileBadges(
    address: string,
    options: SWRConfiguration = {},
  ): [
    GetBadgesResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    return _useSWRInfinite<GetBadgesResponse, Badge>({
      key: UsersAPI.SWR_PATH.GET_PROFILE_BADGES(address),
      size: 100,
      func: async (query: string) =>
        await this.getProfileBadges(query, address),
      dataKey: 'badges',
      options,
    });
  }

  useGetMyProfileBadges(
    options: SWRConfiguration = {},
  ): [
    GetBadgesResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    return _useSWRInfinite<GetBadgesResponse, Badge>({
      key: UsersAPI.SWR_PATH.GET_MY_PROFILE_BADGES(),
      size: 100,
      func: async (query: string) => await this.getMyProfileBadges(query),
      dataKey: 'badges',
      options,
    });
  }

  useGetBadges(
    params: GetBadgesParams,
    options: SWRConfiguration = {},
  ): [
    GetBadgesResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    return _useSWRInfinite<GetBadgesResponse, Badge>({
      key: UsersAPI.SWR_PATH.GET_BADGES(),
      params,
      size: 100,
      func: async (query) => await this.getBadges(params, query),
      dataKey: 'badges',
      options,
    });
  }

  useGetBadge(
    badgeId: string,
    options: SWRConfiguration = {},
  ): SWRResponse<BadgeDetailed, Error> {
    return _useSWR<BadgeDetailed>(
      badgeId ? UsersAPI.SWR_PATH.GET_BADGE(badgeId) : null,
      async () => this.getBadge(badgeId),
      options,
    );
  }

  useGetNotableBadges(options: SWRConfiguration = {}): SWRResponse<
    {
      order: number;
      badge: Badge;
    }[],
    Error
  > {
    return new NotableBadgesAPI(
      UsersAPI.SWR_PATH.GET_NOTABLE_BADGES(),
    ).useGetNotableBadges(options);
  }

  useGetProfile(options?: SWRConfiguration): SWRResponse<User, Error> {
    return _useSWR(
      UsersAPI.SWR_PATH.GET_PROFILE(),
      async () => this.getProfile(),
      options || {},
    );
  }

  useGetPfpAssets(
    size: number,
    options?: SWRConfiguration,
  ): [
    GetPfpAssetsResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    return _useSWRInfinite<GetPfpAssetsResponse, CryptoAsset>({
      key: `${UsersAPI.SWR_PATH.GET_PFP_ASSETS()}?${TEST_ADDRESS}`,
      size,
      func: async (query: string) => await this.getPfpAssets(query),
      dataKey: 'assets',
      options,
    });
  }

  useGetInterests(options?: SWRConfiguration): SWRResponse<Interest[], Error> {
    return _useSWR(
      UsersAPI.SWR_PATH.GET_INTERESTS(),
      async () => this.getInterests(),
      options || {},
    );
  }

  useGetCollections(
    options: SWRConfiguration = {},
  ): [
    GetCollectionsResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    return new CollectionsAPI(
      UsersAPI.SWR_PATH.GET_COLLECTIONS(),
    ).useGetCollections(options);
  }

  useGetCollectionBadges(
    id: string,
    options: SWRConfiguration = {},
  ): SWRResponse<any[], Error> {
    return new CollectionsAPI(
      UsersAPI.SWR_PATH.GET_COLLECTIONS(),
    ).useGetCollectionBadges(id, options);
  }

  useGetMyAchievement(
    id: string,
    options: SWRConfiguration = {},
  ): SWRResponse<CollectionAchievements, Error> {
    return _useSWR(
      UsersAPI.SWR_PATH.GET_MY_ACHIEVEMENT(id),
      async () => this.getMyCollectionAchievement(id),
      options || {},
    );
  }

  useGetUserAchievements(
    address: string,
    id: string,
    options: SWRConfiguration = {},
  ): SWRResponse<CollectionAchievements, Error> {
    return _useSWR(
      UsersAPI.SWR_PATH.GET_USER_ACHIEVEMENT(address, id),
      async () => this.getUserCollectionAchievement(address, id),
      options || {},
    );
  }

  useGetGroupBadges(
    groupId: string,
    excludeBadges: string[],
    options: SWRConfiguration = {},
  ): SWRResponse<Badge[], Error> {
    const badgeGroupsAPI = new BadgeGroupsAPI(
      `${API_CONST.USERS_API_URL}/me/badge-groups`,
    );
    return badgeGroupsAPI.useGetGroupBadges(groupId, excludeBadges, options);
  }

  useGetUserBadges(
    options: SWRConfiguration = {},
  ): [
    GetUserBadgesResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    const userBadgesAPI = new UserBadgesAPI(
      `${API_CONST.USERS_API_URL}/me/${API_CONST.USER_BADGES_API_URL}`,
    );
    return userBadgesAPI.useGetUserBadges(options);
  }
}
