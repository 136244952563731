import { Badge, getBadgeTierInfo } from 'src/entity/badge';
import { Collection, CollectionDetail } from 'src/entity/collection';
import { Pagination } from 'src/entity/common';
import { KeyedMutator, SWRConfiguration, SWRResponse } from 'swr';
import { API } from './api';
import { API_CONST } from './const';
import { _useSWR, _useSWRInfinite } from './swr';

export interface GetCollectionsResponse extends Pagination {
  collections: Collection[];
}

export class CollectionsAPI extends API {
  constructor(_url?: string) {
    super(API_CONST.ROOT_URL, _url || API_CONST.COLLECTIONS_API_URL);
  }

  static PATH = {
    // GET
    GET_COLLECTIONS: (): string => '',
    GET_COLLECTION: (collectionId: string): string => `${collectionId}`,
    GET_COLLECTION_BADGES: (collectionId: string): string =>
      `${collectionId}/onboard-badges`,
    GET_COLLECTION_BY_SLUG: (slugName: string): string => `slug/${slugName}`,
  };

  getSwrPath(): Record<string, any> {
    return {
      GET_COLLECTIONS: () => `${this.controllerUrl}`,
      GET_COLLECTION: (id: string) =>
        `${this.controllerUrl}/${CollectionsAPI.PATH.GET_COLLECTION(id)}`,
      GET_COLLECTION_BADGES: (id: string) =>
        `${this.controllerUrl}/${CollectionsAPI.PATH.GET_COLLECTION_BADGES(
          id,
        )}`,
    };
  }

  async getCollections(query: string): Promise<GetCollectionsResponse> {
    const res = await this.get(
      `${CollectionsAPI.PATH.GET_COLLECTIONS()}`,
      query,
    );
    return res;
  }

  async getCollection(id: string): Promise<Collection> {
    const res = await this.get(`${CollectionsAPI.PATH.GET_COLLECTION(id)}`);
    return res;
  }

  async getCollectionBySlug(slugName: string): Promise<Collection> {
    const res = await this.get(
      `${CollectionsAPI.PATH.GET_COLLECTION_BY_SLUG(slugName)}`,
    );
    return res;
  }

  async getCollectionBadges(id: string): Promise<Badge[]> {
    const res = await this.get(
      `${CollectionsAPI.PATH.GET_COLLECTION_BADGES(id)}`,
    );
    return res.labels.map((label: any) => {
      return {
        ...label,
        badges: label.badges.map((badge: Badge) => ({
          ...badge,
          ...getBadgeTierInfo(badge),
        })),
      };
    });
  }

  useGetCollections(
    options: SWRConfiguration = {},
  ): [
    GetCollectionsResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    return _useSWRInfinite<GetCollectionsResponse, Collection>({
      key: this.getSwrPath().GET_COLLECTIONS(),
      size: 10,
      func: async (query: string) => {
        return await this.getCollections(query);
      },
      dataKey: 'collections',
      options,
    });
  }

  useGetCollection(
    id: string,
    options: SWRConfiguration = {},
  ): SWRResponse<CollectionDetail, Error> {
    return _useSWR<CollectionDetail>(
      this.getSwrPath().GET_COLLECTION(id),
      async () => this.getCollection(id),
      options,
    );
  }

  useGetCollectionBadges(
    id: string,
    options: SWRConfiguration = {},
  ): SWRResponse<Badge[], Error> {
    return _useSWR<Badge[]>(
      this.getSwrPath().GET_COLLECTION_BADGES(id),
      async () => this.getCollectionBadges(id),
      options,
    );
  }
}
