import React, { useState } from 'react';
import { ROUTE } from 'src/apis/const';
import { UsersAPI } from 'src/apis/users-api';
import { User } from 'src/entity/user';
import useDisplayName from 'src/hooks/use-display-name';
import { getDateString, getShortAddress, getTruncatedValue } from 'src/utils';
import { mutate } from 'swr';
import { CopyDom, InterestList, Pfp } from '..';
import useModal from '../../hooks/use-modal';
import Button, { ButtonSize, ButtonStyle } from '../common/button';
import Chip, { ChipSize, ChipStyle } from '../common/chip';
import CopyLinkButton from '../common/copy-link-button';
import Icon from '../common/icon';
import { MODAL_TYPE } from '../common/modal/modal';
import { PfpBgType } from '../common/pfp';
import PrimaryBadgeChip from '../common/primary-badge-chip';
import ProgressBar from '../common/progress-bar';
import { handleExistsToast, noProfileBadgesToast } from '../common/toast';
import TwitterButton from '../common/twitter-button';

interface Props {
  profile: User & {
    link: string;
    tweetText: string;
  };
  isMine: boolean;
}

const BioSection: React.FC<Props> = ({ profile, isMine }) => {
  const {
    nickname,
    address,
    primaryPfp,
    bio,
    primaryBadge,
    interests,
    ens,
    totalTx,
    birth,
    tweetText,
    progress = {
      badge: false,
      bio: false,
      interest: false,
      primaryBadge: false,
      primaryPfp: false,
    },
  } = profile;
  const [renderModal, handleOpenEditProfile] = useModal(
    MODAL_TYPE.EditProfile,
    async (dataChanged: Record<string, any>) => {
      try {
        await new UsersAPI().updateProfile(dataChanged);
        await mutate(UsersAPI.SWR_PATH.GET_PROFILE());
      } catch (e: any) {
        if (e.code === 400) {
          handleExistsToast();
        }
      }
    },
  );
  const [renderPrimaryBadgeModal, handleOpenPrimaryBadgeModal] = useModal(
    MODAL_TYPE.SelectPrimaryBadge,
    async (primaryBadge: string) => {
      await new UsersAPI().updateProfile({
        primaryBadge,
      });
      mutate(UsersAPI.SWR_PATH.GET_PROFILE());
    },
  );

  const [displayName] = useDisplayName(nickname, address, true);
  const [choosePrimaryBadgeloading, setChoosePrimaryBadgeloading] =
    useState<boolean>(false);

  return (
    <>
      {renderPrimaryBadgeModal}
      {renderModal}
      <div className="container" id="profile-container">
        <div className="left">
          <div className="pfp">
            <Pfp
              pfp={primaryPfp}
              primaryBadge={primaryBadge}
              address={address}
              pfpBgType={PfpBgType.GRADIENT}
            />
          </div>
          <div className="tool">
            {isMine && (
              <>
                <div className="edit-button">
                  <Button
                    label="Edit profile"
                    buttonStyle={ButtonStyle.LINEAR}
                    buttonSize={ButtonSize.S}
                    handleClick={() => {
                      handleOpenEditProfile(null);
                    }}
                    icon={<Icon iconKey="pencil" />}
                    iconWidth={18}
                  />
                </div>
                <div>
                  <ProgressBar
                    progress={
                      [
                        progress.badge,
                        progress.bio,
                        progress.interest,
                        progress.primaryBadge,
                        progress.primaryPfp,
                      ].filter((item) => item).length * 20
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="right">
          <div className="info1">
            <div className="name h6-bold WT100">
              <p>{displayName}</p>
              <div className="buttons">
                <CopyLinkButton
                  url={`${window.location.origin}${ROUTE.PROFILE}/${address}`}
                />
                {isMine && <TwitterButton tweetText={tweetText} />}
              </div>
            </div>
            <div className="primaryBadge">
              {primaryBadge ? (
                <PrimaryBadgeChip badge={primaryBadge as any} />
              ) : isMine ? (
                <Chip
                  label={'+ Add your primary badge'}
                  chipStyle={ChipStyle.DASH_GRAD}
                  chipSize={ChipSize.S}
                  handleClick={
                    !choosePrimaryBadgeloading
                      ? async () => {
                          try {
                            setChoosePrimaryBadgeloading(true);
                            const getProfileBadgesRes =
                              await new UsersAPI().getProfileBadges(
                                '?size=1',
                                address,
                              );
                            if (getProfileBadgesRes.badges.length !== 0) {
                              handleOpenPrimaryBadgeModal(null);
                            } else {
                              noProfileBadgesToast();
                            }
                          } catch (e: any) {
                            console.error(e.message);
                          } finally {
                            setChoosePrimaryBadgeloading(false);
                          }
                        }
                      : () => {}
                  }
                />
              ) : (
                <></>
              )}
            </div>
            <p className="address b2-medium P200">
              <span>
                {ens && (
                  <>
                    <CopyDom text={ens}>{getTruncatedValue(ens, 30)}</CopyDom>
                    &nbsp;·&nbsp;
                  </>
                )}
                <CopyDom text={address}>{getShortAddress(address, 4)}</CopyDom>
              </span>
            </p>
          </div>
          <div className="info2 b1-regular desc pre-wrap">
            {bio ||
              (isMine ? (
                <Chip
                  label={'+ Add your introduction'}
                  chipStyle={ChipStyle.DASH_LITE}
                  chipSize={ChipSize.S}
                  handleClick={() => {
                    handleOpenEditProfile(null);
                  }}
                />
              ) : (
                <></>
              ))}
          </div>
          <div className="info3">
            {birth && (
              <p className="b1-regular B400 birth">
                <span>
                  <Icon iconKey="cake" />
                </span>
                <span>Active since {getDateString(birth.date)}</span>
              </p>
            )}

            <p className="b1-regular B400">
              <span>
                <Icon iconKey="chart-square-bar" />
              </span>
              <span>{totalTx} txs created</span>
            </p>
          </div>
          <div className="info4">
            <InterestList interestList={interests} isMine={isMine} />
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
          display: flex;
        }
        .left {
          margin-right: 46px;
        }
        .tool {
          width: 200px;
          margin: 12px auto 0px;
          text-align: center;
        }
        .edit-button {
          margin-bottom: 16px;
        }

        .pfp {
          margin-bottom: 16px;
        }

        .info1,
        .info2,
        .info3 {
          margin-bottom: 28px;
        }

        .name {
          margin-bottom: 10px;
          width: 100%;
          overflow-wrap: break-word;
          display: flex;
        }
        .name > p {
          width: 393px;
        }
        .name > .buttons {
          display: flex;
          justify-content: flex-end;
          gap: 8px;
          margin-bottom: -2px;
          width: 80px;
        }
        .primaryBadge {
          margin-bottom: 8px;
        }
        .address {
          margin-bottom: 26px;
        }

        .desc {
          width: 473px;
        }

        .info3 span {
          vertical-align: middle;
        }
        .info3 span:first-of-type {
          display: inline-block;
          width: 24px;
          height: 24px;
          padding-right: 9px;
        }
        .info3 p.birth {
          margin-bottom: 4px;
        }
      `}</style>
    </>
  );
};

export default BioSection;
