import { useRouter } from 'next/router';
import React from 'react';
import { ROUTE } from 'src/apis/const';
import useUser from 'src/hooks/use-user';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';

const TxSucceedModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose, handleSubmit }) => {
  const router = useRouter();
  const [profile] = useUser();
  return (
    <>
      <div className="container">
        <div className="header">
          <p className="title h6-bold">Your transaction has been sent</p>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <div className="body">
          <div className="timer h7-regular G25">
            <span className="h7-bold">30+</span> seconds
          </div>
          <p className="desc h7-regular WT100">
            It takes about <span className="LG">30+ seconds</span> for the badge
            <br />
            to appear on your profile
          </p>
          <ul>
            <li>
              <Button
                label="Browse other badges"
                buttonStyle={ButtonStyle.PRIMARY}
                buttonSize={ButtonSize.M}
                handleClick={() => {
                  router.push(ROUTE.BADGE);
                }}
                style={{
                  margin: 0,
                }}
              />
              <Button
                label="View profile"
                buttonStyle={ButtonStyle.SECONDARY}
                buttonSize={ButtonSize.M}
                handleClick={() => {
                  router.push(`${ROUTE.PROFILE}/${profile!.address}`);
                }}
                style={{
                  margin: 0,
                }}
              />
            </li>
            <li>
              <Button
                label="View transaction on Etherscan"
                buttonStyle={ButtonStyle.ONLY_TEXT}
                buttonSize={ButtonSize.S}
                handleClick={handleSubmit}
              />
            </li>
          </ul>
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 571px;
          text-align: center;
        }
        .title {
          margin-bottom: 30px;
        }
        .close-button {
          position: absolute;
          top: 40px;
          right: 40px;
        }
        span {
        }
        ul {
        }
        li:first-of-type {
          margin-bottom: 16px;
          display: flex;
          gap: 8px;
          justify-content: center;
        }
        .desc {
          margin-bottom: 24px;
        }
        .timer {
          width: 128px;
          height: 114px;
          margin: 0 auto;
          background: url(/image/timer-icon.png) top center no-repeat;
          background-size: 48px 69.6px;
          margin-bottom: 24px;
          display: flex;
          align-items: flex-end;
        }
      `}</style>
    </>
  );
};

export default TxSucceedModal;
