import React from 'react';

const ProgressBar: React.FC<{ progress?: number }> = ({ progress = 0 }) => {
  return (
    <>
      <div className="container">
        <div className="bg">
          <div className={`bar progress-${progress}`}></div>
        </div>
        <p className="WT100 cap1-regular">
          Your profile is <span className="P300">{progress}%</span> complete
        </p>
      </div>
      <style jsx>
        {`
          .container {
          }
          .bg {
            background: var(--WT15);
            width: 200px;
            height: 10px;
            border-radius: 10px;
            padding: 2px 2px;
            margin-bottom: 4px;
          }
          .bar {
            background: var(--GR-PG);
            width: 0px;
            height: 10px;
            border-radius: 10px 0px 0px 10px;
          }
          .bar.progress-20 {
            width: 40px;
          }
          .bar.progress-40 {
            width: 80px;
          }
          .bar.progress-60 {
            width: 120px;
          }
          .bar.progress-80 {
            width: 160px;
          }
          .bar.progress-100 {
            width: 200px;
            border-radius: 10px;
          }
          p {
          }
          span {
          }
        `}
      </style>
    </>
  );
};

export default ProgressBar;
