import React from 'react';
import ReactTooltip from 'react-tooltip';

const CertifiedIcon: React.FC = () => {
  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <span
        data-tip="This badge is created by a verified account"
        className="check"
      ></span>
      <style jsx>{`
        .check {
          background: url(/image/badge-check.png) center center no-repeat;
          background-size: 20px 20px;
          width: 20px;
          height: 20px;
        }
      `}</style>
    </>
  );
};

export default CertifiedIcon;
