import React, { useCallback, useEffect, useState } from 'react';
import { UsersAPI } from 'src/apis/users-api';
import { codePointsToString } from 'src/utils';
import Button, { ButtonSize, ButtonStyle } from '../button';

const SignUpSuccessAlertModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose, handleSubmit }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const _handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      if (handleSubmit) {
        await handleSubmit();
        handleClose();
      }
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [handleSubmit, handleClose]);

  useEffect(() => {
    new UsersAPI().updateProfile({
      bio: '',
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h6-bold">
            You’re ready to collect badges{codePointsToString(['U+1F396'])}
          </p>
        </div>
        <div className="body">
          <p className="b1-regular">
            Complete your profile & collect on-chain achievement badges
            <br /> to unlock more opportunities!
          </p>
          <ul>
            <li>
              <Button
                label="Got it"
                buttonStyle={ButtonStyle.PRIMARY}
                buttonSize={ButtonSize.L}
                handleClick={_handleSubmit}
                loading={loading}
              />
            </li>
          </ul>
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 520px;
          text-align: center;
        }
        p {
          margin-bottom: 24px;
        }
        span {
        }
        ul {
        }
        li {
        }
      `}</style>
    </>
  );
};

export default SignUpSuccessAlertModal;
