// /notices/?OnlyLatest=true

import { Badge } from 'src/entity/badge';
import { SWRConfiguration, SWRResponse } from 'swr';
import { API } from './api';
import { API_CONST } from './const';
import { _useSWR } from './swr';

export class NotableBadgesAPI extends API {
  constructor(_url?: string) {
    super(API_CONST.ROOT_URL, _url || API_CONST.NOTABLE_BADGES_API_URL);
  }

  static PATH = {
    GET_NOTABLE_BADGES: ``,
  };

  getSwrPath(): Record<string, any> {
    return {
      GET_NOTABLE_BADGES: () =>
        `${this.controllerUrl}${NotableBadgesAPI.PATH.GET_NOTABLE_BADGES}`,
    };
  }

  async getNotableBadges(): Promise<
    {
      order: number;
      badge: Badge;
    }[]
  > {
    const res = await this.get(`${NotableBadgesAPI.PATH.GET_NOTABLE_BADGES}`);
    return res;
  }

  useGetNotableBadges(options: SWRConfiguration = {}): SWRResponse<
    {
      order: number;
      badge: Badge;
    }[],
    Error
  > {
    return _useSWR<
      {
        order: number;
        badge: Badge;
      }[]
    >(
      this.getSwrPath().GET_NOTABLE_BADGES(),
      async () => this.getNotableBadges(),
      options,
    );
  }
}
