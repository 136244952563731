import React, { useContext } from 'react';
import { ROUTE, SAMPLE_APPLICATION_ID } from 'src/apis/const';
import Icon from 'src/components/common/icon';
import { Action } from 'src/hooks/use-create-badge-state/action';
import {
  CreateBadgeStateContext,
  defaultActionInputError,
  PageType
} from 'src/hooks/use-create-badge-state/index';
import ActionComp from './action';
import ActionRule from './action-rule';
// import TestAddresses from './test-addresses';

const EligibilityRuleSection: React.FC = () => {
  const [state, setState, error, setError, , , pageType] = useContext(
    CreateBadgeStateContext,
  );

  const eligibilityRule = state?.eligibilityRule.actionUnits;
  const confirmedAction = eligibilityRule.filter(
    (action) => action._confirmedOnce,
  );
  const isMoreThanOneActionConfirmed = confirmedAction.length > 0;
  const isUnconfirmedActionExist =
    eligibilityRule.length !== confirmedAction.length;

  const isViewPage = pageType === PageType.VIEW;

  return (
    <>
      <div className="wrap">
        {!isViewPage ? (
          <div className="header">
            <div className="flex">
              <p className="title h5-bold G25">Define Actions</p>
              <div
                onClick={() => {
                  window
                    .open(
                      `${ROUTE.USER_BADGE}/${SAMPLE_APPLICATION_ID[0]}`,
                      '_blank',
                    )
                    ?.focus();
                }}
                className="button b2-regular P25"
              >
                See sample
                <span className="WT60">
                  <Icon iconKey="chevronRight" />
                </span>
              </div>
            </div>
            <p className="desc b1-regular">
              Define actions that users must do to be eligible for the badge
            </p>
          </div>
        ) : (
          <div
            className="header"
            style={{
              marginBottom: 10,
            }}
          >
            <div className="flex">
              <p className="title h5-bold G25">Actions</p>
            </div>
          </div>
        )}

        <div className="actions">
          <div className="list">
            {state?.eligibilityRule.actionUnits.map((action, i) => {
              return (
                <div key={action._id} className="action">
                  <ActionComp index={i} />
                </div>
              );
            })}
          </div>
          {!isViewPage &&
            isMoreThanOneActionConfirmed &&
            !isUnconfirmedActionExist && (
              <div
                className="add-action G100 b1-medium"
                onClick={() => {
                  setError((error) => {
                    const newActionUnitsError = [
                      ...error?.eligibilityRule.actionUnits,
                      {
                        ...defaultActionInputError,
                      },
                    ];
                    return {
                      ...error,
                      eligibilityRule: {
                        ...error.eligibilityRule,
                        actionUnits: newActionUnitsError,
                      },
                    };
                  });
                  setState((state) => {
                    const newEligibilityRulesActionUnits = [
                      ...state?.eligibilityRule.actionUnits,
                      new Action(),
                    ];
                    return {
                      ...state,
                      eligibilityRule: {
                        ...state.eligibilityRule,
                        actionUnits: newEligibilityRulesActionUnits,
                      },
                    };
                  });
                }}
              >
                +&nbsp;&nbsp;Add Action
              </div>
            )}
        </div>
        <div className="create-rule">
          {!isViewPage ? (
            <>
              <p className="title h5-bold G25">Set Action Rules</p>
              <p className="desc b1-regular">
                Set rules for your actions. You can set a threshold for the
                total count or sum of the volumes for the actions defined above.
                Check the unit of the value you want to put in the threshold.
              </p>
              <p className="sub-desc">
                <p className="cap1-bold G100">Examples</p>
                <ul>
                  <li className="cap1-regular G100">
                    ‣ If you want a badge for users who have sent A tokens 10+
                    times, define an action for transfer above with type
                    ‘Count’, and{' '}
                    <span className="P400">set the threshold to</span> 10.
                  </li>
                  <li className="cap1-regular G100">
                    ‣ if you want a badge for users who have transferred A
                    tokens and the sum of all the transfers , define an action
                    for transfer above with type ‘Volume’, and{' '}
                    <span className="P400">set the threshold to</span> 100. (The
                    actual value of tokens depends on the decimal unit of each
                    token).
                  </li>
                </ul>
              </p>
            </>
          ) : (
            <p className="title h5-bold G25">Rule</p>
          )}
          <div className="rule">
            <ActionRule />
          </div>
        </div>
        {/* <div className="test-eligible-addresses">
          {!isViewPage ? (
            <>
              <p className="title h5-bold G25">Test Eligible Addresses</p>
              <p className="desc b1-regular">
                Please put eligible address for badge testing. This is not
                required, but this step allows you to create badges much faster.
              </p>
            </>
          ) : (
            <p className="title h5-bold G25">Test Eligible Addresses</p>
          )}
          <div className="addresses">
            <TestAddresses />
          </div>
        </div> */}
      </div>
      <style jsx>{`
        .flex {
          display: flex;
          justify-content: space-between;
        }
        .header {
          margin-bottom: 40px;
        }
        .header .button {
          background: var(--BT15);
          padding: 9.5px 20px 7.5px;
          border: 1px solid rgba(238, 173, 255, 1);
          border-radius: 8px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .header .button span {
          width: 12px;
          margin-left: 8px;
          margin-top: 2px;
        }
        .actions {
          margin-bottom: 80px;
        }
        .actions .list {
        }
        .actions .list .action {
          margin-bottom: 40px;
        }
        .actions .add-action {
          padding: 12px 25px;
          background: var(--BT80);
          border: 1px solid var(--G700);
          border-radius: 12px;
          cursor: pointer;
        }
        .create-rule {
          margin-bottom: 80px;
        }
        .create-rule .title,
        .test-eligible-addresses .title {
          margin-bottom: 10px;
        }
        .create-rule .desc {
          margin-bottom: 10px;
        }
        .test-eligible-addresses .desc {
          margin-bottom: 40px;
        }
        .sub-desc {
          background: var(--BT30);
          border: 1px solid var(--G600);
          border-radius: 12px;
          padding: 8px 12px;
          margin-bottom: 40px;
        }
        .sub-desc p {
          margin-bottom: 4px;
        }
        .sub-desc ul li {
          margin-bottom: 4px;
        }
        .sub-desc ul li:last-of-type {
          margin-bottom: 0px;
        }
      `}</style>
    </>
  );
};

export default EligibilityRuleSection;
