import { useRouter } from 'next/router';
import React, { useCallback, useState } from 'react';
import { ClaimSigAPI } from 'src/apis/claim-sig-api';
import { UsersAPI } from 'src/apis/users-api';
import supportedChains from 'src/const/chains';
import { BadgeDetailed, Chain } from 'src/entity/badge';
import useAlertModal from 'src/hooks/use-alert-modal';
import useUser from 'src/hooks/use-user';
import { nooxContract } from 'src/modules/noox-contract';
import Button, { ButtonSize, ButtonStyle } from '../button';
import ModalHeader from '../modal/modal-header';
import { TxSucceedToast } from '../toast';
import { ALERT_MODAL_TYPE } from './alert-modal';

const UpgradeModal: React.FC<{
  badge: BadgeDetailed;
  handleClose: () => void;
  handleSubmit: any;
}> = ({ badge, handleClose, handleSubmit }) => {
  const router = useRouter();
  const [profile] = useUser();
  const [_loading, _setLoading] = useState<boolean>(false);

  const [renderTxFeeError, handleTxFeeErrorModalOpen] = useAlertModal(
    ALERT_MODAL_TYPE.TxFeeError,
    () => {},
  );

  const [renderTxError, handleTxErrorModalOpen] = useAlertModal(
    ALERT_MODAL_TYPE.TxError,
    () => {},
  );

  const pollDB = useCallback(
    (tokenId: string) => {
      const cb = () => {
        new UsersAPI()
          .getBadge(tokenId)
          .then((res) => {
            if (res.isClaimed) {
              handleSubmit();
              TxSucceedToast(
                profile?.address || '',
                router,
                'Congratulations! Upgrade succeeded!',
              );
              handleClose();
              clearInterval(id);
            }
          })
          .catch((error) => {
            console.error(error.message);
            clearInterval(id);
          });
      };
      const id = setInterval(cb, 2500);
    },
    [profile, router, handleSubmit],
  );

  const upgrade = useCallback(async () => {
    try {
      const { chainId } = await window.nooxProvider.getNetwork();
      const currentNetwork = supportedChains[badge.chain];
      if (chainId !== currentNetwork.chain_id) {
        alert(
          `You are currently on the wrong network. Please switch to ${currentNetwork.network} network.`,
        );
        _setLoading(false);
        return;
      }

      const res = await new ClaimSigAPI().upgradeSig({
        badgeId: badge.id,
      });
      const { burnBadgeIds, claimBadgeId, signature } = res.badge;
      const txHash = await nooxContract(
        window.nooxProvider.getSigner(),
        badge.chain,
      ).migrate(burnBadgeIds, claimBadgeId, signature);
      const receipt = await window.nooxProvider.waitForTransaction(txHash);
      if (receipt.status === 1) {
        pollDB(badge.id);
      }
    } catch (e: any) {
      console.error(e);
      const isEthereumChain = badge.chain === Chain.Ethereum;
      const isFeeError = isEthereumChain
        ? e.message &&
          e.message.startsWith(
            'insufficient funds for intrinsic transaction cost',
          )
        : e.message && e.data.message.startsWith('err: insufficient funds');
      if (isFeeError) {
        handleTxFeeErrorModalOpen(null);
      } else {
        handleTxErrorModalOpen({
          errorMessage: isEthereumChain ? e.message : e.data?.message,
        });
      }
      _setLoading(false);
    }
  }, [badge, pollDB, handleTxErrorModalOpen, handleTxFeeErrorModalOpen]);

  const _handleSubmit = useCallback(async () => {
    try {
      _setLoading(true);
      await upgrade();
    } catch (e: any) {
      console.error(e.message);
      _setLoading(false);
    }
  }, [upgrade]);

  return (
    <>
      {renderTxFeeError}
      {renderTxError}
      <div className="container">
        <ModalHeader title={`Badge Level Upgrade`} handleCancel={handleClose} />
        <div className="body">
          <p className="b1-regular">
            Upgrade your badge in this series to this badge. Do you want to
            proceed? More details can be found{' '}
            <span
              className="link"
              onClick={() => {
                window
                  .open('https://docs.noox.world/learn/upgrade', '_blank')
                  ?.focus();
              }}
            >
              here.
            </span>
          </p>
          <div className="button-wrap">
            {_loading ? (
              <Button
                label="loading"
                buttonStyle={ButtonStyle.PRIMARY}
                buttonSize={ButtonSize.L}
                handleClick={() => {}}
                loading={true}
                style={{
                  width: 150,
                }}
              />
            ) : (
              <Button
                label="Upgrade"
                buttonStyle={ButtonStyle.PRIMARY}
                buttonSize={ButtonSize.L}
                handleClick={_handleSubmit}
                style={{
                  margin: 0,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
        }
        p {
          margin-bottom: 24px;
        }
        .code {
          width: 488px;
          background: var(--BT15);
          border-radius: 16px;
          border: 1px solid var(--WT30);
          padding: 16px;
          margin-bottom: 30px;
          overflow: auto;
          max-height: 150px;
        }
        .button-wrap {
          display: flex;
          gap: 0px;
          justify-content: center;
        }
        span.link {
          text-decoration: underline;
          cursor: pointer;
        }
      `}</style>
    </>
  );
};

export default UpgradeModal;
