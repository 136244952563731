import React, { useCallback, useState } from 'react';
import { codePointsToString } from 'src/utils';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';

const CheckEligibilitiesModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose, handleSubmit }) => {
  const [_loading, _setLoading] = useState<boolean>(false);
  const _handleSubmit = useCallback(async () => {
    try {
      _setLoading(true);
      await handleSubmit();
    } catch (e: any) {
      console.error(e.message);
    } finally {
      _setLoading(false);
      handleClose();
    }
  }, [handleSubmit, handleClose]);
  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h6-bold">Are you sure?</p>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <div className="body">
          <p className="b1-regular yellow desc">
            Please read the guidelines below
          </p>
          <ul>
            <li className="b2-regular">
              <span className="WT100">
                {codePointsToString(['U+2705'])} &nbsp;It will check your
                eligibilities for all badges on Noox
              </span>
            </li>
            <li className="b2-regular">
              <span className="WT100">
                {codePointsToString(['U+2705'])} &nbsp;The process may take up
                to 3 minutes
              </span>
            </li>
            <li className="b2-regular">
              <span className="WT100">
                {codePointsToString(['U+2705'])} &nbsp;You are able to check for
                batch eligibility every 30 minutes
              </span>
            </li>
          </ul>
          <Button
            label="Check all eligibility"
            isDisabled={true}
            buttonStyle={ButtonStyle.PRIMARY}
            buttonSize={ButtonSize.M}
            handleClick={_handleSubmit}
            loading={_loading}
          />
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 520px;
          text-align: left;
        }
        .header {
          margin-bottom: 25px;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .body p.desc {
          margin-bottom: 24px;
        }
        .body ul {
          margin-bottom: 24px;
        }
        .body ul li {
          margin-bottom: 4px;
        }
      `}</style>
    </>
  );
};

export default CheckEligibilitiesModal;
