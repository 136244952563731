import { useRouter } from 'next/router';
import React from 'react';
import { ROUTE } from 'src/apis/const';
import Button, { ButtonSize, ButtonStyle } from '../common/button';

const ThinFooter: React.FC = () => {
  const router = useRouter();
  return (
    <>
      <footer>
        <span className="b1-medium P200">powered by</span>
        <span>
          <Button
            label="Learn more"
            buttonStyle={ButtonStyle.PRIMARY}
            buttonSize={ButtonSize.S}
            handleClick={() => {
              router.push(ROUTE.HOME);
            }}
          />
        </span>
      </footer>
      <style jsx>{`
        footer {
          position: fixed;
          bottom: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--BT100);
          padding: 22px 0px;
        }
        span:first-of-type {
          background: url(/image/logo.png) right center no-repeat;
          background-size: 80px 20.56px;
          height: 20.56px;
          width: 184px;
          margin-right: 20px;
        }
      `}</style>
    </>
  );
};

export default ThinFooter;
