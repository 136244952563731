import { KeyedMutator, SWRConfiguration, SWRResponse } from 'swr';
import { API } from './api';
import { BadgeGroupsAPI } from './badge-groups-api';
import { API_CONST } from './const';
import { _useSWR, _useSWRInfinite } from './swr';

interface GetEligibilitiesResponse {
  address: string;
  state: number; // 0: false, 1: true
  updatedAt: string;
  createdAt?: string;
  badgeId?: string;
  message?: string;
  lastCheckedAt?: string;
}

interface GetEligibilityDetailResponse extends GetEligibilitiesResponse {
  details: {
    transactions: {
      index: number;
      hash: string;
      blockNumber: number;
      totalUSD: string;
    }[];
    size: number;
    next: number;
  };
}

export interface GetEligibilityDetailDebugResponse
  extends GetEligibilityDetailResponse {
  executionResult: boolean;
  actualActionValue: string;
  requiredActionValue: string;
}

export class EligibilitiesAPI extends API {
  constructor() {
    super(API_CONST.ROOT_URL, API_CONST.ELIGIBILITIES_API_URL);
  }

  static PATH = {
    GET_ELIGIBILITIES: (): string => ``,
    GET_ELIGIBILITY: (badgeId: string): string => `${badgeId}`,
    GET_ELIGIBILITY_DETAIL: (badgeId: string): string => `${badgeId}/detail`,
    GET_IS_SUPPORTING_DETAIL: (badgeId: string): string =>
      `${badgeId}/support-detail`,
    GET_ELIGIBILITY_DETAIL_DEBUG: (badgeId: string, address: string): string =>
      `${badgeId}/addresses/${address}/detail-debug`,
  };

  static SWR_PATH = {
    GET_ELIGIBILITIES: (): string =>
      `${
        API_CONST.ELIGIBILITIES_API_URL
      }/${EligibilitiesAPI.PATH.GET_ELIGIBILITIES()}`,
    GET_ELIGIBILITY: (badgeId: string): string =>
      `${
        API_CONST.ELIGIBILITIES_API_URL
      }/${EligibilitiesAPI.PATH.GET_ELIGIBILITY(badgeId)}`,
    GET_ELIGIBILITY_DETAIL: (badgeId: string): string =>
      `${
        API_CONST.ELIGIBILITIES_API_URL
      }/${EligibilitiesAPI.PATH.GET_ELIGIBILITY_DETAIL(badgeId)}`,
    GET_IS_SUPPORTING_DETAIL: (badgeId: string): string =>
      `${
        API_CONST.ELIGIBILITIES_API_URL
      }/${EligibilitiesAPI.PATH.GET_IS_SUPPORTING_DETAIL(badgeId)}`,
    GET_ELIGIBILITY_DETAIL_DEBUG: (badgeId: string, address: string): string =>
      `${
        API_CONST.ELIGIBILITIES_API_URL
      }/${EligibilitiesAPI.PATH.GET_ELIGIBILITY_DETAIL_DEBUG(
        badgeId,
        address,
      )}`,
  };

  async getEligibilities(retryNum: number): Promise<GetEligibilitiesResponse> {
    try {
      const res = await this.put(
        `${EligibilitiesAPI.PATH.GET_ELIGIBILITIES()}`,
      );
      return res;
    } catch (err) {
      if (retryNum === 1) throw err;
      return await this.getEligibilities(retryNum - 1);
    }
  }

  async updateEligibilities(
    retryNum: number,
    ids?: {
      badgeId: string;
      groupId: string;
    }[],
  ): Promise<GetEligibilitiesResponse> {
    try {
      let badgeIds: string[] = [];

      if (ids) {
        for (const id of ids) {
          if (id.groupId) {
            const res = await new BadgeGroupsAPI().getGroupBadges(id.groupId, [
              '',
            ]);
            badgeIds = [...badgeIds, ...res.map((badge) => badge.id)];
          } else {
            badgeIds = [...badgeIds, id.badgeId];
          }
        }
      }


      const res = await this.post(
        `${EligibilitiesAPI.PATH.GET_ELIGIBILITIES()}`,
        badgeIds.length > 0
          ? {
              badgeIds,
            }
          : undefined,
      );
      return res;
    } catch (err) {
      if (retryNum === 1) throw err;
      return await this.updateEligibilities(retryNum - 1);
    }
  }

  async getEligibility(badgeId: string): Promise<GetEligibilitiesResponse> {
    const res = await this.get(
      `${EligibilitiesAPI.PATH.GET_ELIGIBILITY(badgeId)}`,
    );
    return res;
  }

  async updateEligibility(badgeId: string): Promise<{
    from: string;
    badgeId: string;
    message: string;
  }> {
    const res = await this.post(
      `${EligibilitiesAPI.PATH.GET_ELIGIBILITY(badgeId)}`,
    );
    return res;
  }

  useGetEligibilities(
    options: SWRConfiguration = {},
  ): SWRResponse<GetEligibilitiesResponse, Error> {
    return _useSWR<GetEligibilitiesResponse>(
      EligibilitiesAPI.SWR_PATH.GET_ELIGIBILITIES(),
      async () => new EligibilitiesAPI().getEligibilities(5),
      options || {},
    );
  }

  useGetEligibility(
    badgeId: string,
    options: SWRConfiguration = {},
  ): SWRResponse<GetEligibilitiesResponse, Error> {
    return _useSWR<GetEligibilitiesResponse>(
      EligibilitiesAPI.SWR_PATH.GET_ELIGIBILITY(badgeId),
      async () => new EligibilitiesAPI().getEligibility(badgeId),
      options || {},
    );
  }

  async getEligibilityDetail(
    badgeId: string,
    query: string,
  ): Promise<GetEligibilityDetailResponse> {
    const res = await this.get(
      `${EligibilitiesAPI.PATH.GET_ELIGIBILITY_DETAIL(badgeId)}`,
      query,
    );
    return {
      ...res,
      details: {
        ...res.details,
        size: res.details.txCount,
        next: res.details.txNextIndex,
      },
    };
  }

  async getEligibilityDetailDebug(
    badgeId: string,
    address: string,
  ): Promise<GetEligibilityDetailDebugResponse> {
    const res = await this.post(
      `${EligibilitiesAPI.PATH.GET_ELIGIBILITY_DETAIL_DEBUG(
        badgeId,
        address.toLowerCase(),
      )}`,
    );
    return {
      ...res,
      details: {
        ...res.details,
        size: res.details.txCount,
        next: res.details.txNextIndex,
      },
    };
  }

  async getIsSupportingDetail(badgeId: string): Promise<boolean> {
    const res = await this.get(
      `${EligibilitiesAPI.PATH.GET_IS_SUPPORTING_DETAIL(badgeId)}`,
    );
    return res.isSupported;
  }

  async updateEligibilityDetail(badgeId: string): Promise<{
    address: string;
    badgeId: string;
    message: string;
  }> {
    const res = await this.post(
      `${EligibilitiesAPI.PATH.GET_ELIGIBILITY_DETAIL(badgeId)}`,
    );
    return res;
  }

  useGetIsSupportingDetail(
    badgeId: string,
    options: SWRConfiguration = {},
  ): SWRResponse<boolean, Error> {
    return _useSWR<boolean>(
      EligibilitiesAPI.SWR_PATH.GET_IS_SUPPORTING_DETAIL(badgeId),
      async () => new EligibilitiesAPI().getIsSupportingDetail(badgeId),
      options || {},
    );
  }

  useGetEligibilityDetail(
    badgeId: string,
    options: SWRConfiguration = {},
  ): [any | null, (node: any) => void, boolean, KeyedMutator<any[]>] {
    return _useSWRInfinite<GetEligibilityDetailResponse, any>({
      key: EligibilitiesAPI.SWR_PATH.GET_ELIGIBILITY_DETAIL(badgeId),
      size: 500,
      func: async (query) => {
        const { details } = await this.getEligibilityDetail(badgeId, query);
        return details;
      },
      dataKey: 'transactions',
      options,
    });
  }
}
