export enum ActionAggregationType {
  COUNT = 'count',
  SUM = 'sum',
}

export interface ActionAggregation {
  type: ActionAggregationType;
  field?: string;
}

export const defaultActionAggregation: Record<
  ActionAggregationType,
  ActionAggregation
> = {
  [ActionAggregationType.COUNT]: {
    type: ActionAggregationType.COUNT,
  },
  [ActionAggregationType.SUM]: {
    type: ActionAggregationType.SUM,
    field: '',
  },
};
