import { stringify } from '../utils';
import { NooxServerError } from './error';
import { BearerTokenSession } from './local-storage';

export class API {
  protected url: string;
  protected controllerUrl: string;
  constructor(rootUrl: string, controllerUrl: string) {
    this.url = `${rootUrl}${controllerUrl}`;
    this.controllerUrl = controllerUrl;
  }

  private async _parseRes(res: Response) {
    if (!res.ok) {
      const { statusCode, timestamp, path, message } = await res.json();
      throw new NooxServerError(statusCode, `${message}`);
    }
    const r = await res.json();
    return r.result;
  }
  private async _parseResv2(res: Response) {
    if (!res.ok) {
      const { statusCode, timestamp, path, message } = await res.json();
      throw new NooxServerError(statusCode, `${message}`);
    }
    const r = (await res.json()).data;
    return r;
  }

  private _getHeader(isFormData?: boolean) {
    const headers: any = {
      'Access-Control-Allow-Origin': '*',
      Accept: 'application/json',
    };

    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
    }

    const token = BearerTokenSession.get();
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
  }

  async get(
    path: string,
    query?: string,
    options: RequestInit | undefined = {},
  ): Promise<any> {
    try {
      const headers = this._getHeader();
      const res = await fetch(
        `${this.url}${path ? `/${path}` : ''}${query || ''}`,
        {
          method: 'GET',
          headers,
          ...options,
        },
      );

      return await this._parseRes(res);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async getv2(
    path: string,
    query?: string,
    options: RequestInit | undefined = {},
  ): Promise<any> {
    try {
      const headers = this._getHeader();
      const res = await fetch(
        `${this.url}${path ? `/${path}` : ''}${query || ''}`,
        {
          method: 'GET',
          headers,
          ...options,
        },
      );

      return await this._parseResv2(res);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async post(
    path: string,
    payload: Record<string, any> = {},
    options: {
      isFormData: boolean;
    } = {
      isFormData: false,
    },
  ): Promise<any> {
    try {
      const headers = this._getHeader(options.isFormData);
      const res = await fetch(`${this.url}${path ? `/${path}` : ''}`, {
        method: 'POST',
        headers,
        body: (options.isFormData ? payload : stringify(payload)) as any,
      });
      return await this._parseRes(res);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async postv2(
    path: string,
    payload: Record<string, any> = {},
    options: {
      isFormData: boolean;
    } = {
      isFormData: false,
    },
  ): Promise<any> {
    try {
      const headers = this._getHeader(options.isFormData);
      const res = await fetch(`${this.url}${path ? `/${path}` : ''}`, {
        method: 'POST',
        headers,
        body: (options.isFormData ? payload : stringify(payload)) as any,
      });
      return await this._parseResv2(res);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async patch(path: string, payload: Record<string, any> = {}): Promise<any> {
    try {
      const headers = this._getHeader();
      const res = await fetch(`${this.url}${path ? `/${path}` : ''}`, {
        method: 'PATCH',
        headers,
        body: stringify(payload),
      });
      return true;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async put(path: string, payload: Record<string, any> = {}): Promise<any> {
    try {
      const headers = this._getHeader();
      const res = await fetch(`${this.url}${path ? `/${path}` : ''}`, {
        method: 'PUT',
        headers,
        body: stringify(payload),
      });
      return await this._parseRes(res);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
