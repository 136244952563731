import React from 'react';
import { Badge } from 'src/entity/badge';
import OvalButton, { OvalButtonSize, OvalButtonType } from './oval-button';

export const TweetShareText = (badge: Badge, url: string): string =>
  encodeURI(
    `Claimed "${badge.name}" badge from @noox_world\n${url.replace(
      '&',
      '%26',
    )}`,
  );

const TwitterButton: React.FC<{
  tweetText: string;
  ovalButtonType?: OvalButtonType;
}> = ({ tweetText, ovalButtonType = OvalButtonType.NORMAL }) => {
  return (
    <a
      className="twitter-share-button"
      target="_blank"
      href={`https://twitter.com/intent/tweet?text=${tweetText}`}
      rel="noreferrer"
    >
      <OvalButton
        icon={
          <div
            style={{
              width: 18,
              height: 18,
              background: "url( '/image/twitter.png') center center no-repeat",
              backgroundSize: '18px 18px',
            }}
          ></div>
        }
        style={{
          width: '18px!important',
          height: '18px!important',
        }}
        ovalButtonSize={OvalButtonSize.L}
        ovalButtonType={ovalButtonType}
        handleClick={() => {}}
      />
    </a>
  );
};
export default TwitterButton;
