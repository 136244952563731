interface SessionStorage {
  inviteCode: string;
  filter: string;
}

const sessionStorage: SessionStorage = {
  inviteCode: 'inviteCode',
  filter: 'filter',
};

class SessionData {
  key: string;
  constructor(_key: string) {
    this.key = _key;
  }
  get() {
    if (typeof window == 'undefined') return;
    return window.sessionStorage.getItem(this.key) || null;
  }
  set(value: string) {
    if (typeof window == 'undefined') return;
    window.sessionStorage.setItem(this.key, value);
  }
  remove() {
    if (typeof window == 'undefined') return;
    window.sessionStorage.removeItem(this.key);
  }
}

// string
export const InviteCodeSession = new SessionData(
  `${sessionStorage.inviteCode}`,
);

// string
export const FilterSession = new SessionData(`${sessionStorage.filter}`);
