import { ethers } from 'ethers';
import { Interface, ParamType } from 'ethers/lib/utils';
import { ActionType } from './action';

export interface ABIAction {
  type: ActionType;
  name: string;
  inputs: ParamType[];
  _signature?: string;
}

export class ABI {
  public abi;
  private _interface: Interface;
  private _functions: ABIAction[];
  private _events: ABIAction[];
  constructor(abi: Record<string, any>[]) {
    this.abi = abi;
    this._interface = new ethers.utils.Interface(abi);
    console.log(this._interface);
    this._functions = this._getFunctions();
    this._events = this._getEvents();
  }

  getActionFromInterface(functionSignature: string): ABIAction {
    const { type, name, inputs } = this._interface.functions[functionSignature];
    return {
      type: type === 'function' ? ActionType.TX : ActionType.EVENTLOG,
      name,
      inputs,
      _signature: functionSignature,
    };
  }

  static getActionLabel(action: ABIAction, isEvent: boolean): string {
    if (isEvent) {
      const prefix = 'event ';
      return `${prefix}${action.name}(${action.inputs.reduce((acc, cur, i) => {
        return (
          acc +
          `${cur.type} ${cur.name}${action.inputs.length - 1 !== i ? ',' : ''}`
        );
      }, '')})`;
    } else {
      return `${action._signature}`;
    }
  }

  static getActionLabelComp(action: ABIAction): React.ReactElement {
    return (
      <p
        style={{
          color: 'rgba(180, 178, 197, 1)',
          width: 'max-content',
        }}
        className="line-1"
      >
        {action.name} (
        {action.inputs.map(({ type, name }, i) => {
          return (
            <>
              <span
                key={i}
                style={{
                  color: 'rgba(117, 234, 194, 1)',
                }}
              >
                {type}{' '}
              </span>
              <span
                style={{
                  color: 'rgba(248, 176, 92, 1)',
                }}
              >
                {name}
              </span>
              {i !== action.inputs.length - 1 && (
                <span
                  style={{
                    color: 'rgba(180, 178, 197, 1)',
                  }}
                >
                  ,{' '}
                </span>
              )}
            </>
          );
        })}
        )
      </p>
    );
  }

  static getParamLabelComp(input: ParamType): React.ReactElement {
    return (
      <p
        style={{
          color: 'rgba(180, 178, 197, 1)',
          width: 'max-content',
        }}
        className="line-1"
      >
        <span
          style={{
            color: 'rgba(117, 234, 194, 1)',
          }}
        >
          {input?.type || ''}{' '}
        </span>
        <span>{input?.name}</span>
      </p>
    );
  }

  getActions(): {
    functions: ABIAction[];
    events: ABIAction[];
  } {
    return {
      functions: this._functions,
      events: this._events,
    };
  }

  getActionsInList(): ABIAction[] {
    return [...this._events, ...this._functions];
  }

  private _getFunctions() {
    const result = [];
    const { functions } = this._interface;
    for (const [
      functionSignature,
      { name, inputs, constant },
    ] of Object.entries(functions)) {
      if (!constant) {
        result.push({
          type: ActionType.TX,
          name,
          inputs,
          _signature: functionSignature,
        });
      }
    }
    return result;
  }

  private _getEvents() {
    const { events } = this._interface;
    return Object.values(events)
      .map(({ name, inputs }) => ({
        type: ActionType.EVENTLOG,
        name,
        inputs,
      }))
      .filter((item) => {
        for (const input of item.inputs) {
          if (input.type === 'address') {
            return true;
          }
        }
        return false;
      });
  }
}
