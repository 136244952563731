/* tslint:disable */

import { useCallback, useRef } from 'react';
import useSWR, {
  Fetcher,
  KeyedMutator,
  SWRConfiguration,
  SWRResponse,
} from 'swr';
import useSWRInfinite from 'swr/infinite';
import { stringify } from '../utils';

export function loggerMiddleware(useSWRNext: any) {
  return (key: string, fetcher: any, config: Record<string, any>) => {
    const extendedFetcher = (...args: any[]) => {
      console.log('SWR Request:', key);
      return fetcher(...args);
    };
    return useSWRNext(key, extendedFetcher, config);
  };
}

export function serializeMiddleware(useSWRNext: any) {
  return (key: string, fetcher: any, config: Record<string, any>) => {
    const isKeyArray = Array.isArray(key);
    const serializedKey = isKeyArray ? stringify(key) : key;
    return useSWRNext(
      serializedKey,
      isKeyArray ? (k: any) => fetcher(...JSON.parse(k)) : fetcher,
      config,
    );
  };
}

export function getPrefetchedDataMiddleware(useSWRNext: any) {
  return (key: string, fetcher: any, config: Record<string, any>) => {
    return useSWRNext(key, fetcher, config);
  };
}

export class SWR {
  static GLOBAL_SETTING = {
    use: [serializeMiddleware, loggerMiddleware],
    errorRetryInterval: 2000,
  };
}

export function _useSWR<T>(
  key: string | null,
  func: Fetcher<any>,
  options: SWRConfiguration = {},
): SWRResponse<T, Error> {
  return useSWR(key, func, options);
}

export function _useSWRInfinite<T, F>(props: {
  key: string | null;
  size: number;
  func: Fetcher<any>;
  dataKey: string;
  params?: Record<string, any>;
  options?: SWRConfiguration;
}): [T | null, (node: any) => void, boolean, KeyedMutator<any[]>] {
  const { key, size, func, dataKey, params = null, options = {} } = props;
  const keyReturnType = (key: string, params: Record<string, any> | null) =>
    params ? JSON.stringify([key, params]) : key;

  const {
    isValidating,
    data: _data,
    size: _size,
    setSize,
    mutate,
  } = useSWRInfinite(
    key
      ? (pageIndex, previousPageData) => {
          const _key = `${key}?size=${size}`;
          if (previousPageData && !previousPageData.next) return null;
          if (pageIndex === 0) return keyReturnType(_key, params);
          return keyReturnType(
            `${_key}&next=${previousPageData!.next}`,
            params,
          );
        }
      : null,
    async (_keyReturnType: string) => {
      const _key = params ? JSON.parse(_keyReturnType)[0] : _keyReturnType;
      const query = (_key as string).replace(key!, '');
      return await func(query);
    },
    options || {
      revalidateAll: true,
    },
  );

  const hasMore = _data ? _data[_data.length - 1].next : false;
  const flatData: F[] = _data
    ? _data
        .map((item) => item[dataKey])
        .flat()
        .map((item, i) => ({ ...item, _idx: i }))
    : [];

  const data: T | null = _data
    ? {
        ..._data[_data.length - 1],
        [dataKey]: [...flatData],
      }
    : null;

  const observer = useRef<any>();
  const lastElementRef = useCallback(
    (node) => {
      if (isValidating) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setSize(_size + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isValidating, _size, setSize, hasMore],
  );

  return [data, lastElementRef, isValidating, mutate];
}
