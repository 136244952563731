import { Chain } from 'src/entity/badge';

export interface IChainData {
  chain: string;
  network: string;
  chain_id: number;
  etherscan_url: string;
  network_id: number;
  rpc_url: string;
  symbol: string;
}

export const chainInfo: Record<string, IChainData> = {
  mainnet: {
    chain: 'Ethereum',
    network: 'mainnet',
    chain_id: 1,
    network_id: 1,
    etherscan_url: `https://etherscan.io`,
    rpc_url: 'https://eth-mainnet.alchemyapi.io/v2/',
    symbol: 'ETH',
  },
  ropsten: {
    chain: 'Ethereum',
    network: 'ropsten',
    chain_id: 3,
    network_id: 3,
    etherscan_url: `https://ropsten.etherscan.io`,
    rpc_url: 'https://ropsten.infura.io/v3/',
    symbol: 'ETH',
  },
  rinkeby: {
    chain: 'Ethereum',
    network: 'rinkeby',
    chain_id: 4,
    network_id: 4,
    etherscan_url: `https://rinkeby.etherscan.io`,
    rpc_url: 'https://rinkeby.infura.io/v3/',
    symbol: 'ETH',
  },
  goerli: {
    chain: 'Ethereum',
    network: 'goerli',
    chain_id: 5,
    network_id: 5,
    etherscan_url: `https://goerli.etherscan.io`,
    rpc_url: 'https://eth-goerli.alchemyapi.io/v2/',
    symbol: 'ETH',
  },
  polygon: {
    chain: 'Polygon',
    network: 'polygon',
    chain_id: 137,
    network_id: 137,
    etherscan_url: `https://polygonscan.com`,
    rpc_url: 'https://polygon-mainnet.g.alchemy.com/v2/',
    symbol: 'MATIC',
  },
  mumbai: {
    chain: 'Polygon',
    network: 'mumbai',
    chain_id: 80001,
    network_id: 80001,
    etherscan_url: `https://mumbai.polygonscan.com`,
    rpc_url: 'https://polygon-mumbai.g.alchemy.com/v2/',
    symbol: 'MATIC',
  },
  optimism: {
    chain: 'Optimism',
    network: 'optimism',
    chain_id: 10,
    network_id: 10,
    etherscan_url: `https://optimistic.etherscan.io`,
    rpc_url: 'https://opt-mainnet.g.alchemy.com/v2/',
    symbol: 'ETH',
  },
  'optimism-goerli': {
    chain: 'Optimism',
    network: 'Optimism Goerli',
    chain_id: 420,
    network_id: 420,
    etherscan_url: `https://goerli-optimism.etherscan.io/`,
    rpc_url: 'https://opt-goerli.g.alchemy.com/v2/',
    symbol: 'ETH',
  },
};

const supportedChains = {
  [Chain.Ethereum]: {
    ...chainInfo[process.env.ETHEREUM_NETWORK!],
  },
  [Chain.Polygon]: {
    ...chainInfo[process.env.POLYGON_NETWORK!],
  },
  [Chain.Optimism]: {
    ...chainInfo[process.env.OPTIMISM_NETWORK!],
  },
};

export const mintingFee = {
  [Chain.Ethereum]: '0.002',
  [Chain.Polygon]: '0.8',
  [Chain.Optimism]: '0.002',
};

export default supportedChains;
