import React, { useCallback, useContext, useState } from 'react';
import { CreateBadgeStateContext } from 'src/hooks/use-create-badge-state';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';
import InputV2 from '../input-v2';
import Select from '../select';

const JoinWaitlistModal: React.FC<{
  waitlistQuestions: any;
  handleClose: () => void;
  handleSubmit: any;
}> = ({ waitlistQuestions = [], handleClose = () => {}, handleSubmit }) => {
  const [state, setState, error, setError, , , pageType] = useContext(
    CreateBadgeStateContext,
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [answers, setAnswers] = useState(
    waitlistQuestions?.map((i: any) => {
      return {
        question_value: i.question_value,
        optional: i.optional,
        answer_value: '',
      };
    }),
  );

  const handleOnSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await handleSubmit(email, answers);
      // handleClose();
    } catch (e: any) {
      console.error(e);
      alert(e.message);
    } finally {
      setIsSubmitting(false);
    }
  }, [email, answers]);

  const inputStyle = {
    width: '100%',
    height: 48,
  };

  return (
    <>
      <div className="container">
        <div className="header">
          <h6 className="h6-bold ">Join waitlist as a broadcaster</h6>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <div className="body scroll">
          <div className="input">
            <InputV2
              style={inputStyle}
              isRequired={true}
              name="Your Email"
              value={email || ''}
              setValue={(val) => {
                setEmail(val);
              }}
              placeholder="Enter your answer"
            />
          </div>
          {waitlistQuestions.map((i: any, index: number) => {
            return (
              <div className="input" key={index}>
                {Array.isArray(i?.answer_value) ? (
                  <Select
                    name={i.question_value}
                    placeholder={'Select...'}
                    disabled={false}
                    defaultValue={undefined}
                    handleOnChange={(val: any) => {
                      setAnswers(
                        answers.map((a: any, idx: number) => {
                          return idx === index
                            ? {
                                ...a,
                                answer_value: val,
                              }
                            : a;
                        }),
                      );
                    }}
                    isRequired={!i.optional}
                    options={i?.answer_value?.map((answer: string) => {
                      return {
                        label: answer,
                        value: answer,
                      };
                    })}
                    singleValueStyle={{}}
                    menuStyle={{
                      minWidth: 280,
                    }}
                  />
                ) : (
                  <InputV2
                    style={inputStyle}
                    name={i.question_value}
                    isRequired={!i.optional}
                    value={answers[index].answer}
                    setValue={(val) => {
                      setAnswers(
                        answers.map((a: any, idx: number) => {
                          return idx === index
                            ? {
                                ...a,
                                answer_value: val,
                              }
                            : a;
                        }),
                      );
                    }}
                    placeholder={i?.answer_value || 'Enter your answer'}
                  />
                )}
              </div>
            );
          })}
          <div>
            <Button
              handleClick={handleOnSubmit}
              label={'Submit'}
              buttonStyle={
                isSubmitting ? ButtonStyle.NEUTRAL : ButtonStyle.PRIMARY
              }
              isDisabled={isSubmitting}
              buttonSize={ButtonSize.L}
            ></Button>
          </div>
        </div>
        {/* TODO: Default Value isEdit에 넘겨주기 */}
      </div>
      <style jsx>{`
        .container {
          width: 720px;
          background: var(--G900);
          max-height: 520px;
          display: flex;
          flex-direction: column;
        }
        .header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 25px;
        }
        div.body {
          display: flex;
          flex-direction: column;
          gap: 24px;

          min-height: 420px;
          max-height: 670px;
          overflow: auto;
          height: calc(100vh - 120px);
          margin-bottom: -20px;
          margin-right: -24px;
          padding-right: 12px;
        }

        p.desc {
          margin-bottom: 20px;
        }

        .input {
        }
      `}</style>
    </>
  );
};

export default JoinWaitlistModal;
