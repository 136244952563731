import React, { CSSProperties } from 'react';
import Loading from './loading';

export enum ButtonStyle {
  PRIMARY,
  SECONDARY,
  NEUTRAL,
  GRADIENT,
  LINEAR,
  ONLY_TEXT,
  CUSTOM,
}

export enum ButtonSize {
  L = 'L',
  M = 'M',
  S = 'S',
}

const basicStyle = {
  [ButtonStyle.PRIMARY]: {
    background: 'var(--P500)',
    color: 'var(--WT100)',
  },
  [ButtonStyle.SECONDARY]: {
    background: 'var(--WT100)',
    color: 'var(--P500)',
  },
  [ButtonStyle.NEUTRAL]: {
    background: 'var(--B600)',
    color: 'var(--WT100)',
  },
  [ButtonStyle.GRADIENT]: {
    background: 'var(--GR-PP)',
    color: 'var(--WT100)',
  },
  [ButtonStyle.LINEAR]: {
    background: 'none',
    border: '1px solid var(--B300)',
    color: 'var(--B300)',
    '&:hover': {
      background: 'var(--BT30)',
      border: '1px solid var(--B300)',
      color: 'var(--B300)',
    },
  },
  [ButtonStyle.ONLY_TEXT]: {
    background: 'none',
    color: 'var(--B100)',
  },
  [ButtonStyle.CUSTOM]: {
    color: 'var(--WT100)',
  },
};

const hoverStyle = {
  [ButtonStyle.PRIMARY]: `background-color: var(--P600)!important;`,
  [ButtonStyle.SECONDARY]: `background-color: var(--B100)!important;`,
  [ButtonStyle.NEUTRAL]: `background-color: var(--B800)!important;`,
  [ButtonStyle.LINEAR]: `background-color: var(--BT30)!important; border: 1px solid var(--B300)!important; color: var(--B300)!important;`,
  [ButtonStyle.GRADIENT]: `background-color: linear-gradient(270deg, #7854f7 0%, #7854f7 100%)!important;`,
  [ButtonStyle.ONLY_TEXT]: `background-color: var(--BT15)!important;`,
  [ButtonStyle.CUSTOM]: {},
};

const sizeStyles = {
  padding: {
    [ButtonSize.L]: '16px 35px',
    [ButtonSize.M]: `11px 30px`,
    [ButtonSize.S]: `6px 23px`,
  },
  height: {
    [ButtonSize.L]: 60 - 32,
    [ButtonSize.M]: 50 - 22,
    [ButtonSize.S]: 36 - 12,
  },
  font: {
    [ButtonSize.L]: 'h7-bold',
    [ButtonSize.M]: 'b1-bold',
    [ButtonSize.S]: 'b1-medium',
  },
};

const loaderColor: Record<ButtonStyle, 'white' | 'primary'> = {
  [ButtonStyle.PRIMARY]: `white`,
  [ButtonStyle.SECONDARY]: `primary`,
  [ButtonStyle.NEUTRAL]: `white`,
  [ButtonStyle.LINEAR]: `white`,
  [ButtonStyle.GRADIENT]: `white`,
  [ButtonStyle.ONLY_TEXT]: `white`,
  [ButtonStyle.CUSTOM]: 'white',
};

interface Props {
  handleClick: () => void;
  label: string;
  buttonStyle: ButtonStyle;
  buttonSize: ButtonSize;
  loading?: boolean;
  icon?: React.ReactNode;
  iconWidth?: number;
  isDisabled?: boolean;
  style?: CSSProperties;
  fontStyle?: CSSProperties;
  tooltip?: string;
  _hoverStyle?: string;
  className?: string;
}

const Button: React.FC<Props> = (props) => {
  const {
    buttonStyle,
    buttonSize,
    handleClick,
    label,
    loading = false,
    icon = null,
    iconWidth = 0,
    isDisabled = false,
    style = {},
    fontStyle = {},
    _hoverStyle = '',
    tooltip = null,
  } = props;

  return (
    <>
      <button
        disabled={isDisabled}
        style={
          {
            ...basicStyle[buttonStyle],
            ...style,
          } as any
        }
        className={`${buttonStyle === ButtonStyle.GRADIENT ? 'gradient' : ''} ${
          loading ? 'loading' : ''
        }`}
        onClick={loading ? () => {} : handleClick}
        {...props}
      >
        <label
          className={sizeStyles.font[buttonSize]}
          style={{
            ...fontStyle,
          }}
        >
          {loading ? (
            <div style={{ minWidth: 38 }}>
              <Loading width={16} color={loaderColor[buttonStyle]} />
            </div>
          ) : (
            <>
              {icon && <span className="icon">{icon}</span>}
              <span className={buttonSize}>{label}</span>
              {tooltip && <span className="tooltip" data-tip={tooltip}></span>}
            </>
          )}
        </label>
      </button>

      <style jsx>{`
        div {
          display: inline-block;
        }
        button {
          display: block;
          border-radius: 30px;
          cursor: pointer;
          padding: ${sizeStyles.padding[buttonSize]};
          margin: 0 auto;
        }
        button:hover {
          ${isDisabled ? '' : hoverStyle[buttonStyle]}
          ${_hoverStyle}
        }
        button.gradient {
          display: inline-block;
          position: relative;
          background-color: #ccc;
          background-image: var(--GR-PP);
          -webkit-backface-visibility: hidden;
          z-index: 1;
        }
        button.gradient:after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.3em;
          background-image: linear-gradient(
            90deg,
            rgba(79, 40, 179, 1) 0%,
            rgba(79, 40, 179, 1) 100%
          );
          transition: opacity 0.25s ease-out;
          z-index: 2;
          opacity: 0;
          border-radius: 30px;
        }
        button.gradient:hover:after {
          opacity: 1;
        }
        button.gradient > label > span {
          z-index: 3;
        }
        button.loading {
          cursor: wait;
          ${hoverStyle[buttonStyle]}
        }
        button.loading:hover {
        }
        label {
          cursor: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
          height: ${sizeStyles.height[buttonSize]}px;
        }
        button.loading > label {
          cursor: inherit;
        }
        label > span.icon {
          width: ${iconWidth}px;
          display: flex;
          align-items: center;
          margin-right: 6.5px;
        }
        label > span.S {
          padding-top: 1px;
        }
        button:disabled,
        button[disabled],
        button:disabled > label,
        button[disabled] > label {
          cursor: not-allowed;
        }
        .tooltip {
          width: 24px;
          height: 24px;
          background: url(/image/information-icon.png) center center no-repeat;
          background-size: 24px 24px;
          padding-left: 13px;
        }
      `}</style>
    </>
  );
};

export default Button;
