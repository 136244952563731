import React, { useEffect, useState } from 'react';
import { UsersAPI } from 'src/apis/users-api';
import useUser from 'src/hooks/use-user';
import Loading from '../loading';
import ModalHeader from './modal-header';
import PfpItem from './pfp-item';

const SelectPfpModal: React.FC<{
  isInit: boolean;
  handleClose: (address: string) => void;
  handleSubmit: (contract: string, tokenId: string) => void;
}> = ({ isInit, handleClose, handleSubmit }) => {
  const [initialId, setInitialId] = useState<number>(-1);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [user] = useUser();
  const [data, lastElementRef] = new UsersAPI().useGetPfpAssets(12, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (data && selectedId < 0) {
      for (const asset of data.assets) {
        if (
          user?.primaryPfp.contract === asset.contract &&
          user?.primaryPfp.tokenId === asset.tokenId
        ) {
          setInitialId(asset._idx);
        }
      }
    }
  }, [data, selectedId, user]);

  return (
    <>
      <div className="container">
        <ModalHeader
          title="Select Profile Picture"
          handleCancel={() => handleClose(user?.address || '')}
          handleSubmit={
            data && data.assets.length > 0
              ? async () => {
                  const { contract, tokenId } = data.assets[selectedId];
                  await handleSubmit(contract, tokenId);
                  handleClose(user?.address || '');
                }
              : null
          }
          showCancel={true}
          cancelText={isInit ? 'Skip now' : undefined}
        />
        <div className="body scroll">
          {!data ? (
            <div className="loader-wrap">
              <Loading />
            </div>
          ) : (
            data.assets.map((item, i) => {
              const isLastElement = data.assets.length === i + 1;
              return (
                <div
                  key={item._idx}
                  ref={isLastElement ? lastElementRef : null}
                >
                  <PfpItem
                    selectedId={selectedId >= 0 ? selectedId : initialId}
                    setSelectedId={setSelectedId}
                    item={item}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 884px;
        }
        div.body {
          display: flex;
          gap: 24px 20px;
          flex-wrap: wrap;
          overflow-y: auto;
          overflow-x: hidden;
          justify-content: center;
          cursor: pointer;
          min-height: 420px;
          max-height: 520px;
          height: calc(100vh - 120px);
          align-items: center;
        }

        .loader-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      `}</style>
    </>
  );
};

export default SelectPfpModal;
