import React, { useCallback, useContext, useEffect, useState } from 'react';
import { components, GroupHeadingProps } from 'react-select';
import { AbisAPI } from 'src/apis/abis-api';
import InputV2, { InputError } from 'src/components/common/input-v2';
import Select from 'src/components/common/select';
import { ABI } from 'src/hooks/use-create-badge-state/abi-state';
import { ActionType } from 'src/hooks/use-create-badge-state/action';
import {
  ActionAggregationType,
  defaultActionAggregation,
} from 'src/hooks/use-create-badge-state/action-aggregation';
import {
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
} from 'src/hooks/use-create-badge-state/index';
import { isAddress } from 'src/utils';
import AddABISection from './add-abi-section';

const ActionGroupHeading = (props: GroupHeadingProps) => (
  <div>
    <components.GroupHeading {...props} />
  </div>
);

const ActionSelector: React.FC<{ index: number }> = ({ index }) => {
  const [state, setState, error, setError, abiState, setAbiState] = useContext(
    CreateBadgeStateContext,
  );
  const currentAction = (state?.eligibilityRule.actionUnits)[index];
  const [abiLoading, setAbiLoading] = useState<boolean>(false);
  const [showAbi, setShowAbi] = useState<boolean>(
    abiState[currentAction.contract.toLowerCase()]?.isCustomABI || false,
  );

  const getOptions = useCallback(() => {
    const abi = abiState[currentAction.contract.toLowerCase()]?.abi;
    if (abi) {
      const abiActions = abi.getActions();
      return [
        {
          label: 'EventLog',
          options: abiActions.events.map((event) => ({
            label: ABI.getActionLabelComp(event),
            value: event,
          })),
        },
        {
          label: 'Function',
          options: abiActions.functions.map((func) => ({
            label: ABI.getActionLabelComp(func),
            value: func,
          })),
        },
      ];
    } else {
      return [];
    }
  }, [abiState, currentAction.contract]);

  const [options, setOptions] = useState<
    {
      label: string;
      options: any[];
    }[]
  >(getOptions());

  useEffect(() => {
    if (!abiState[currentAction.contract.toLowerCase()]) {
      setOptions([]);
    }
    setOptions(getOptions());
  }, [abiState, currentAction.contract]);

  const searchABI = useCallback(async (address: string) => {
    try {
      setAbiLoading(true);
      const lAddress = address.toLowerCase();

      if (!abiState[lAddress]) {
        const abi = await new AbisAPI().getABI(address.toLowerCase());
        setAbiState((state) => ({
          ...state,
          [lAddress]: {
            abi: abi,
            isCustomABI: false,
          },
        }));
      }
    } catch (e: any) {
      if (e.message.startsWith('Not verifed contract:')) {
        setShowAbi(true);
      }
    } finally {
      setAbiLoading(false);
    }
  }, []);

  return (
    <>
      <div className="wrap">
        <div className="input">
          <InputV2
            value={currentAction.contract}
            setValue={(val) => {
              setState((state) => {
                const newEligibilityRulesActionUnits = [
                  ...state?.eligibilityRule.actionUnits,
                ];
                newEligibilityRulesActionUnits[index].contract = val;
                if (isAddress(val)) {
                  searchABI(val);
                } else {
                  setShowAbi(false);
                  setOptions([]);
                  newEligibilityRulesActionUnits[index].action = {
                    type: ActionType.TX,
                    name: '',
                    inputs: [],
                  };
                  newEligibilityRulesActionUnits[index].userEOAField = '';
                  newEligibilityRulesActionUnits[index].aggregations = [];
                }
                return {
                  ...state,
                  eligibilityRule: {
                    ...state.eligibilityRule,
                    actionUnits: newEligibilityRulesActionUnits,
                  },
                };
              });
            }}
            error={
              (error.eligibilityRule as any).actionUnits[index]
                .contract as InputError
            }
            errorArr={
              CreateBadgeStateValidation.eligibilityRule.actionUnits.contract
            }
            placeholder="Add Contract Address"
            // disabled={isViewPage}
          />
        </div>

        {showAbi && <AddABISection index={index} />}

        <Select
          defaultValue={
            currentAction.action.name
              ? {
                  label: ABI.getActionLabelComp(currentAction.action),
                  value: currentAction.action,
                }
              : undefined
          }
          handleOnChange={(val: any) => {
            setState((state) => {
              const newEligibilityRulesActionUnits = [
                ...state?.eligibilityRule.actionUnits,
              ];
              newEligibilityRulesActionUnits[index].type = val.type;
              newEligibilityRulesActionUnits[index].action = val;
              if (val.type === ActionType.TX) {
                newEligibilityRulesActionUnits[index].userEOAField =
                  'address from [TX Object]';
                newEligibilityRulesActionUnits[index].aggregations = [
                  { ...defaultActionAggregation[ActionAggregationType.COUNT] },
                ];
              } else {
                newEligibilityRulesActionUnits[index].userEOAField = '';
                newEligibilityRulesActionUnits[index].aggregations = [];
              }

              return {
                ...state,
                eligibilityRule: {
                  ...state.eligibilityRule,
                  actionUnits: newEligibilityRulesActionUnits,
                },
              };
            });
          }}
          error={
            (error.eligibilityRule as any).actionUnits[index]
              .action as InputError
          }
          errorArr={
            CreateBadgeStateValidation.eligibilityRule.actionUnits.action
          }
          placeholder={
            abiLoading ? 'Fetching ABI...' : 'Select Eventlog or Function'
          }
          components={{ ActionGroupHeading }}
          options={options}
          // disabled={isViewPage}
          menuStyle={{
            minWidth: 592,
            width: 592,
            overflowX: 'auto',
          }}
          singleValueStyle={{
            maxWidth: 282,
          }}
          disabled={abiLoading}
        />
      </div>
      <style jsx>{`
        .wrap {
          width: 341.33px;
          border: 2px solid var(--G400);
          border-radius: 14px;
          padding: 14px;
          background: var(--G900);
        }
        .input {
          margin-bottom: 12px;
        }
      `}</style>
    </>
  );
};

export default ActionSelector;
