export interface ServerError {
  statusCode: number;
  timestamp: string;
  path: string;
}

export enum CLIENT_ERROR_TYPE {
  INVALID_AUTH = 'INVALID_AUTH',
}

export class NooxClientError extends Error {
  code: CLIENT_ERROR_TYPE;
  constructor(code: CLIENT_ERROR_TYPE, message: string) {
    super(`[${code}] ${message}`);
    this.code = code;
  }
}

export class NooxServerError extends Error {
  code: number;
  constructor(code: number, message: string) {
    super(message);
    this.code = code;
  }
}
