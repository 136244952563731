import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { ROUTE } from 'src/apis/const';
import { InviteCodeProvider } from 'src/contexts/InviteCodeContext';
import { Navbar } from '..';
import GlobalLogicLayer from '../common/global-logic-layer';
import MessageNoticeBar from '../common/message-notice-bar';
import Footer from './footer';
import ThinFooter from './thin-footer';

type Props = {
  children: React.ReactNode;
};

const Layout: FC<Props> = ({ children }: Props) => {
  const router = useRouter();
  const isSharePage = router.pathname === ROUTE.SHARE;
  const isProjectsPage = router.pathname === ROUTE.PROJECTS;
  const isRewardPage = router.pathname === '/reward';

  if (isRewardPage) {
    return (
      <>
        <main>{children}</main>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5GT7QTX"
            height="0"
            width="0"
            style={{
              display: 'none',
              visibility: 'hidden',
            }}
          ></iframe>
        </noscript>
      </>
    );
  }

  return (
    <>
      <InviteCodeProvider>
        <GlobalLogicLayer />
        <MessageNoticeBar />
        <Navbar />
        <main>{children}</main>
        {isSharePage ? <ThinFooter /> : !isProjectsPage ? <Footer /> : <></>}
        <div
          style={{
            background: 'var(--G900)',
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            zIndex: -1,
            top: 0,
          }}
        ></div>
      </InviteCodeProvider>
    </>
  );
};

export default Layout;
