import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import UAuthSPA from '@uauth/js';
import * as UAuthWeb3Modal from '@uauth/web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { ethers } from 'ethers';
import { BearerTokenSession } from 'src/apis/local-storage';
import Web3Modal from 'web3modal';
import supportedChains, { chainInfo } from '../../const/chains';

const rpc = {
  [chainInfo.mainnet.chain_id]: `${chainInfo.mainnet.rpc_url}${process.env
    .ALCHEMY_API_KEY!}`,
  [chainInfo.goerli.chain_id]: `${chainInfo.goerli.rpc_url}${process.env
    .ALCHEMY_API_KEY!}`,
  [chainInfo.polygon.chain_id]: `${chainInfo.polygon.rpc_url}${process.env
    .ALCHEMY_API_KEY!}`,
  [chainInfo.mumbai.chain_id]: `${chainInfo.mumbai.rpc_url}${process.env
    .ALCHEMY_API_KEY!}`,
    [chainInfo.optimism.chain_id]: `${chainInfo.optimism.rpc_url}${process.env
      .ALCHEMY_API_KEY!}`,
    [chainInfo["optimism-goerli"].chain_id]: `${chainInfo["optimism-goerli"].rpc_url}${process.env
      .ALCHEMY_API_KEY!}`,
};

const uauthOptions: UAuthWeb3Modal.IUAuthOptions = {
  clientID: process.env.UAUTH_CLIENT_ID!,
  // TODO: MUST set noox.world when deploy
  redirectUri: process.env.UAUTH_REDIRECT_URL!,
  // Must include both the openid and wallet scopes.
  scope: 'openid wallet',
};

const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: 'noox', // Required
      rpc,
      darkMode: true,
    },
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc,
    },
  },
  'custom-uauth': {
    // The UI Assets
    display: UAuthWeb3Modal.display,

    // The Connector
    connector: UAuthWeb3Modal.connector,

    // The SPA libary
    package: UAuthSPA,

    // The SPA libary options
    options: uauthOptions,
  },
};

let web3Modal: Web3Modal;
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: supportedChains['Ethereum'].network,
    cacheProvider: true,
    providerOptions,
    theme: 'dark',
  });
  UAuthWeb3Modal.registerWeb3Modal(web3Modal);
}

const Provider = {
  getProvider: async () => {
    await Provider._getProvider();
  },
  getConnectCachedProvider: async () => {
    if (web3Modal.cachedProvider) {
      await Provider._getProvider();
    }
  },
  removeConnectCachedProvider: async () => {
    window.nooxProvider = undefined;
    if (web3Modal.cachedProvider === 'custom-uauth') {
      await new UAuthSPA(uauthOptions).logout();
    }
    web3Modal.clearCachedProvider();
  },
  toggleModal: async () => {
    await web3Modal.toggleModal();
  },
  _getProvider: async () => {
    try {
      const provider = await web3Modal.connect();
      provider.on('chainChanged', async (chainId: number) => {
        console.debug(chainId);
        await window.nooxProvider.provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        });
      });
      provider.on('accountsChanged', (accounts: string[]) => {
        Provider.removeConnectCachedProvider();
        BearerTokenSession.remove();
        window.location.reload();
      });
      window.nooxProvider = new ethers.providers.Web3Provider(provider, "any");
      return provider;
    } catch (e: any) {
      window.nooxProvider = new ethers.providers.AlchemyProvider(
        process.env.ETHEREUM_NETWORK!,
        process.env.ALCHEMY_API_KEY!,
      );
    }
  },
};

export { Provider };
