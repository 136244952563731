import React from 'react';
import Icon from './icon';
import OvalButton, { OvalButtonSize, OvalButtonType } from './oval-button';

const CloseIconButton: React.FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  return (
    <OvalButton
      icon={<Icon iconKey="x" />}
      ovalButtonSize={OvalButtonSize.L}
      ovalButtonType={OvalButtonType.NORMAL}
      handleClick={handleClose}
    />
  );
};
export default CloseIconButton;
