import { useState } from 'react';
import { Badge as BadgeComponent } from 'src/components';
import { Badge } from 'src/entity/badge';
import Checkbox from '../checkbox';

interface Props {
  isSelected: boolean;
  setSelectedId: (badge: Badge) => void;
  item: Badge;
}

const BadgeItem: React.FC<Props> = (props) => {
  const { isSelected, setSelectedId, item } = props;
  const [isMouseEnter, setMouseEnter] = useState<boolean>(false);

  return (
    <>
      <div
        onMouseEnter={() => {
          setMouseEnter(true);
        }}
        onMouseLeave={() => {
          setMouseEnter(false);
        }}
        className="item"
        onClick={(e) => {
          e.preventDefault();
          setSelectedId(item);
        }}
      >
        <div className="badge">
          <BadgeComponent
            badge={item}
            isSelected={isSelected}
            style={{
              boxShadow: 'none',
            }}
          />
        </div>
        <Checkbox
          id={item._idx}
          isHovered={isMouseEnter}
          isSelected={isSelected}
        />
      </div>
      <style jsx>
        {`
          div.item {
            text-align: center;
            cursor: pointer;
          }
          div.item .badge {
            margin-bottom: 16px;
          }
        `}
      </style>
    </>
  );
};

export default BadgeItem;
