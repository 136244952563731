interface LocalStorage {
  accessToken: string;
  claimTxHash: string;
  checkClaimableToken: string;
  isCheckEligibilitiesRunning: string;
  checkedNoticeId: string;
  inviteCode: string;
}

const localStorage: LocalStorage = {
  accessToken: 'accessToken',
  claimTxHash: 'claimTxHash',
  checkClaimableToken: 'checkClaimableToken',
  isCheckEligibilitiesRunning: 'isCheckEligibilitiesRunning',
  checkedNoticeId: 'checkedNoticeId',
  inviteCode: 'inviteCode',
};

class SessionData {
  key: string;
  constructor(_key: string) {
    this.key = _key;
  }
  get() {
    if (typeof window == 'undefined') return;
    return window.localStorage.getItem(this.key) || null;
  }
  set(value: string) {
    if (typeof window == 'undefined') return;
    window.localStorage.setItem(this.key, value);
  }
  remove() {
    if (typeof window == 'undefined') return;
    window.localStorage.removeItem(this.key);
  }
}

// string
export const BearerTokenSession = new SessionData(localStorage.accessToken);

// json string { txHash: string, tokenId: string }
export const ClaimTxHashSession = new SessionData(
  `${localStorage.claimTxHash}`,
);

// boolean
export const CheckClaimableTokenSession = new SessionData(
  `${localStorage.checkClaimableToken}`,
);

// boolean
export const IsCheckEligibilitiesRunningSession = new SessionData(
  `${localStorage.isCheckEligibilitiesRunning}`,
);

// string
export const CheckedNoticeIdSession = new SessionData(
  `${localStorage.checkedNoticeId}`,
);

// string
export const InviteCodeSession = new SessionData(`${localStorage.inviteCode}`);
