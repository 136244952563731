import React, { CSSProperties } from 'react';

export enum OvalButtonType {
  PRIMARY,
  NORMAL,
  GRAY,
  ONLY_TEXT,
}
export enum OvalButtonSize {
  L,
  S,
}

const colorStyles = {
  [OvalButtonType.PRIMARY]: {
    normal: `background: var(--P500);color: var(--WT100);`,
    hover: `background: var(--P300);color: var(--WT100);`,
  },
  [OvalButtonType.NORMAL]: {
    normal: `background: var(--B600);color: var(--WT60);`,
    hover: `background: var(--B800);color: var(--WT60);`,
  },
  [OvalButtonType.GRAY]: {
    normal: `background: var(--G800);color: var(--G100);`,
    hover: `background: var(--G800);color: var(--G100);opacity: 0.7;`,
  },
  [OvalButtonType.ONLY_TEXT]: {
    normal: `background: transparent;`,
    hover: `background:  var(--BT15)!important;`,
  },
};

const sizeStyles = {
  [OvalButtonSize.L]: 'width: 36px; height: 36px;',
  [OvalButtonSize.S]: 'width: 28px; height: 28px;',
};

const iconSizeStyles = {
  [OvalButtonSize.L]: 'width: 18px;height: 18px;',
  [OvalButtonSize.S]: 'width: 14px;height: 14px;',
};

interface Props {
  ovalButtonType: OvalButtonType;
  ovalButtonSize: OvalButtonSize;
  icon: React.ReactNode;
  handleClick: () => void;
  style?: CSSProperties;
}

const OvalButton: React.FC<Props> = ({
  handleClick,
  icon,
  ovalButtonType,
  ovalButtonSize,
  style = {},
}) => {
  return (
    <>
      <div onClick={handleClick || (() => {})}>
        <span style={style}>{icon}</span>
      </div>

      <style jsx>{`
        div {
          display: inline-block;
          border-radius: 30px;
          ${colorStyles[ovalButtonType].normal}
          ${sizeStyles[ovalButtonSize]}
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        div:hover {
          ${colorStyles[ovalButtonType].hover}
        }

        span {
          ${iconSizeStyles[ovalButtonSize]}
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
};

export default OvalButton;
