import { v4 as uuidv4 } from 'uuid';

type GeneralField = 'blockNumber';

export enum ActionRuleType {
  EQUAL = 'eq',
  RANGE = 'range',
}

export enum ActionRuleUIType {
  EQUAL = 'equal to',
  GTE = 'greater than',
  RANGE = 'between',
}

export interface IActionRule {
  _id: string;
  field: GeneralField | string;
  _fieldType: string;
  type: ActionRuleUIType | null;
  value:
    | string
    | {
        gte?: string;
        lte?: string;
      };
}

export const defaultActionRuleValue: Record<
  ActionRuleUIType,
  Pick<IActionRule, 'type' | 'value'>
> = {
  [ActionRuleUIType.EQUAL]: {
    type: ActionRuleUIType.EQUAL,
    value: '',
  },
  [ActionRuleUIType.GTE]: {
    type: ActionRuleUIType.GTE,
    value: {
      gte: '',
    },
  },
  [ActionRuleUIType.RANGE]: {
    type: ActionRuleUIType.RANGE,
    value: {
      gte: '',
      lte: '',
    },
  },
};

export class ActionRule implements IActionRule {
  _id: string;
  field: GeneralField | string;
  _fieldType: string;
  type: ActionRuleUIType | null;
  value:
    | string
    | {
        gte?: string;
        lte?: string;
      };

  constructor() {
    this._id = uuidv4();
    this.field = '';
    this._fieldType = '';
    this.type = null;
    this.value = '';
  }
}
