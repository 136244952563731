import { API } from './api';
import { API_CONST } from './const';

export class ClaimSigAPI extends API {
  constructor(_url?: string) {
    super(API_CONST.ROOT_URL, _url || API_CONST.CLAIM_SIG_API_URL);
  }

  static PATH = {
    CLAIM_SIG: (): string => ``,
    UPGRADE_SIG: (): string => `upgrade`,
    MIGRATE_SIG: (): string => `migrate`,
  };

  async claimSig(params: { badgeIds: string[] }): Promise<{
    from: string;
    badges: {
      badgeId: string;
      signature: string;
    }[];
  }> {
    const res = await this.post(`${ClaimSigAPI.PATH.CLAIM_SIG()}`, params);
    return res;
  }

  async upgradeSig(params: { badgeId: string }): Promise<{
    from: string;
    badge: {
      burnBadgeIds: string[];
      claimBadgeId: string;
      signature: string;
    };
  }> {
    const res = await this.post(`${ClaimSigAPI.PATH.UPGRADE_SIG()}`, params);
    return res;
  }

  async migrateSig(params: { badgeId: string }): Promise<{
    from: string;
    badge: {
      burnBadgeIds: string[];
      claimBadgeId: string;
      signature: string;
    };
  }> {
    const res = await this.post(`${ClaimSigAPI.PATH.MIGRATE_SIG()}`, params);
    return res;
  }
}
