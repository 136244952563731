import { BigNumber } from 'ethers';
import { ParamType } from 'ethers/lib/utils';
import { isObjectEmpty } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';
import { ABI, ABIAction } from './abi-state';
import { ActionAggregation, ActionAggregationType } from './action-aggregation';
import {
  ActionRule,
  ActionRuleType,
  ActionRuleUIType,
  IActionRule,
} from './action-rule';

export enum ActionType {
  EVENTLOG = 'EVENTLOG',
  TX = 'TX',
}
export interface IAction {
  _id: string;
  _confirmedOnce: boolean;
  _isEditing: boolean;
  type: ActionType;
  contract: string;
  action: ABIAction;
  userEOAField: string;
  filters: IActionRule[];
  aggregations: ActionAggregation[];
}

export interface IActionJSON {
  type: string;
  contract: string;
  action?: string;
  operations: {
    filters: {
      type: string;
      field: string;
      value: string;
    }[];
    aggregations: {
      type: string;
      field?: string;
    }[];
  }[];
  blockNumber?: {
    gte?: number;
    lte?: number;
  };
}

export class Action implements IAction {
  _id = uuidv4();
  _confirmedOnce = false;
  _isEditing = true;
  type = ActionType.TX;
  contract = '';

  action = {
    type: '' as ActionType,
    name: '',
    inputs: [] as ParamType[],
  };
  userEOAField = '';
  filters = [] as IActionRule[];
  aggregations = [] as ActionAggregation[];

  convertToJSON(): IActionJSON {
    const actionLabel = ABI.getActionLabel(
      this.action,
      this.type === ActionType.EVENTLOG,
    );
    const convertedFilter: {
      field: string;
      type: ActionRuleType;
      value: any;
    }[] = [];

    const blockNumber: {
      gte?: number;
      lte?: number;
    } = {};

    if (this.type === ActionType.EVENTLOG) {
      convertedFilter.push({
        type: ActionRuleType.EQUAL,
        field: this.userEOAField,
        value: 'noox::userEOA',
      });
    } else {
      convertedFilter.push({
        type: ActionRuleType.EQUAL,
        field: 'from',
        value: 'noox::userEOA',
      });
      convertedFilter.push({
        type: ActionRuleType.EQUAL,
        field: 'method',
        value: actionLabel,
      });
    }

    for (const filter of this.filters) {
      const _filter = { ...filter };
      const actionRuleType =
        _filter.type === ActionRuleUIType.EQUAL
          ? ActionRuleType.EQUAL
          : ActionRuleType.RANGE;

      if (filter.field === 'blockNumber') {
        const value = filter.value as {
          gte?: string;
          lte?: string;
        };
        if (value.gte) {
          blockNumber['gte'] = Number(value.gte);
        }
        if (value.lte) {
          blockNumber['lte'] = Number(value.lte);
        }
        continue;
      }

      if (filter._fieldType === 'address') {
        _filter.value = (_filter.value as string).toLowerCase();
      }

      if (
        filter._fieldType.startsWith('int') ||
        filter._fieldType.startsWith('uint')
      ) {
        if (typeof filter.value === 'string') {
          _filter.value = BigNumber.from(filter.value).toHexString();
        } else {
          if (filter.value.gte) {
            (_filter as any).value['gte'] = BigNumber.from(
              filter.value.gte,
            ).toHexString();
          }
          if (filter.value.lte) {
            (_filter as any).value['lte'] = BigNumber.from(
              filter.value.lte,
            ).toHexString();
          }
        }
      }

      convertedFilter.push({
        type: actionRuleType,
        field: _filter.field,
        value: _filter.value,
      });
    }

    const result: IActionJSON = {
      type: this.type,
      contract: this.contract.toLowerCase(),
      operations: [
        {
          filters: convertedFilter as any,
          aggregations: this.aggregations,
        },
      ],
    };

    if (this.type === ActionType.EVENTLOG) {
      result['action'] = actionLabel;
    }

    if (!isObjectEmpty(blockNumber)) {
      result['blockNumber'] = blockNumber;
    }

    return result;
  }

  static fromJSON(
    json: IActionJSON,
    abi: Record<
      string,
      {
        abi: ABI | null;
        isCustomABI: boolean;
      } | null
    >,
  ): Action {
    const getActionRuleUIType = (val: any) => {
      return typeof val === 'string'
        ? ActionRuleUIType.EQUAL
        : val['gte'] && !val['lte']
        ? ActionRuleUIType.GTE
        : ActionRuleUIType.RANGE;
    };

    const result: Action = new Action();
    result._confirmedOnce = true;
    result._isEditing = false;
    result.type = json.type as ActionType;
    result.contract = json.contract;

    // Action

    let actionParam: Record<string, any> = {};
    if (json.type === ActionType.TX) {
      const _action = json.operations[0].filters.filter(
        (filter) => filter.field === 'method',
      )[0].value;
      const _abi = abi[json.contract];
      result.action = {
        ...(_abi?.abi?.getActionFromInterface(_action) as ABIAction),
      };
      for (const input of result.action.inputs) {
        actionParam[input.name] = input.type;
      }
    } else {
      const _action = json.action!.replace('event ', '').split('(');
      const actionName = _action[0];
      actionParam = _action[1]
        .slice(0, _action[1].length - 1)
        .split(',')
        .reduce((acc, cur) => {
          const [type, name] = cur.split(' ');
          return {
            ...acc,
            [name]: type,
          };
        }, {});

      for (const _action of abi[json.contract]?.abi
        ?.getActionsInList()
        .filter((action) => action.name === actionName) as any) {
        let _result = true;
        for (const input of _action.inputs) {
          if (actionParam[input.name] !== input.type) {
            _result = false;
          }
        }
        if (_result) {
          result.action = { ..._action };
        }
      }
    }

    // Filter
    const convertedFilter = [];
    let userEOAField = '';

    for (const filter of json.operations[0].filters) {
      if (json.type === ActionType.TX && filter.field === 'method') {
        continue;
      }
      if (filter.type === 'eq' && filter.value === 'noox::userEOA') {
        userEOAField = filter.field;
        continue;
      }
      const newActionRule = new ActionRule();
      newActionRule.type = getActionRuleUIType(filter.value);
      newActionRule.field = filter.field;
      newActionRule._fieldType = actionParam[filter.field];
      if (
        actionParam[filter.field].startsWith('uint') ||
        actionParam[filter.field].startsWith('int')
      ) {
        if (typeof filter.value === 'string') {
          newActionRule.value = BigNumber.from(filter.value).toString();
        } else {
          newActionRule.value = {};
          if ((filter.value as any).gte) {
            newActionRule.value['gte'] = BigNumber.from(
              (filter.value as any).gte,
            ).toString();
          }
          if ((filter.value as any).lte) {
            newActionRule.value['lte'] = BigNumber.from(
              (filter.value as any).lte,
            ).toString();
          }
        }
      } else {
        newActionRule.value = filter.value;
      }
      convertedFilter.push(newActionRule);
    }

    if (json.blockNumber) {
      const blockNumberFilter = new ActionRule();
      blockNumberFilter._fieldType = 'uint';
      blockNumberFilter.field = 'blockNumber';
      blockNumberFilter.type = ActionRuleUIType.RANGE;
      blockNumberFilter.value = {};
      if (json.blockNumber.gte) {
        blockNumberFilter.value['gte'] = `${json.blockNumber.gte}`;
      }
      if (json.blockNumber.lte) {
        blockNumberFilter.value['lte'] = `${json.blockNumber.lte}`;
      }
      convertedFilter.push(blockNumberFilter);
    }

    result.userEOAField = userEOAField;
    result.filters = convertedFilter;
    result.aggregations = [
      {
        type:
          json.operations[0].aggregations[0].type ===
          ActionAggregationType.COUNT
            ? ActionAggregationType.COUNT
            : ActionAggregationType.SUM,
        field: json.operations[0].aggregations[0].field,
      },
    ];

    return result;
  }
}
