import { useRouter } from 'next/router';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { BadgesAPI } from 'src/apis/badges-api';
import { ROUTE } from 'src/apis/const';
import { ProfileAPI } from 'src/apis/profile-api';
import { Badge as BadgeComponent } from 'src/components';
import supportedChains from 'src/const/chains';
import { codePointsToString } from 'src/utils';
import BadgeTitleSection from '../badge-title-section';
import Button, { ButtonSize, ButtonStyle } from '../button';
import Chip, { ChipSize, ChipStyle } from '../chip';
import CloseIconButton from '../close-icon-button';
import CopyLinkButton from '../copy-link-button';
import Icon from '../icon';
import Loading from '../loading';
import TwitterButton, { TweetShareText } from '../twitter-button';

const BadgeDetailModal: React.FC<{
  address: string;
  isMine: boolean;
  tokenId: string;
  handleClose: () => void;
  handleSubmit: any;
}> = ({ address, tokenId, handleClose, isMine }) => {
  const router = useRouter();
  const { data: profile } = new ProfileAPI().useGetProfile(address);
  const { data: badge } = new BadgesAPI().useGetBadgeWithAddress(
    tokenId,
    address,
  );

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!profile || !badge) {
    return (
      <>
        <div className="container">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 620,
            }}
          >
            <Loading />
          </div>
        </div>
        <style jsx>
          {`
            .container {
              width: 916px;
              min-height: 620px;
            }
          `}
        </style>
      </>
    );
  }

  const shareUrl = `${window.location.origin}${ROUTE.SHARE}?address=${profile.address}&tokenId=${badge.id}`;
  const tweetText = TweetShareText(badge, shareUrl);
  return (
    <>
      <div className="container">
        <>
          <div className="header">
            <p className="h7-medium P200">{profile?.nickname}’s Achievement</p>
            <div className="close-button">
              <CloseIconButton handleClose={handleClose} />
            </div>
          </div>
          <div className="body">
            <div className="left">
              <div className="buttons">
                <CopyLinkButton url={shareUrl} />
                {isMine && <TwitterButton tweetText={tweetText} />}
              </div>
              <div className="info1">
                <BadgeTitleSection badge={badge} titleFontSize="h5-bold" />
                <div className="chips">
                  <Chip
                    label={`Version ${badge.revision}`}
                    chipStyle={ChipStyle.WHITE}
                    chipSize={ChipSize.S}
                  />
                  <Chip
                    label={badge._bhLabel}
                    handleClick={() => {
                      window
                        .open(
                          `${
                            supportedChains[
                              badge.chain
                            ].etherscan_url
                          }/block/${badge.publishBH}`,
                          '_blank',
                        )
                        ?.focus();
                    }}
                    chipStyle={ChipStyle.WHITE}
                    chipSize={ChipSize.S}
                  />
                </div>
              </div>
              <div className="info2 b2-regular G100 pre-wrap">
                {badge.description}
              </div>
              <div className="howToClaim">
                <p className="title h7-bold">
                  {codePointsToString(['U+1F525'])} How to claim?
                </p>
                <ul>
                  <li>
                    <p className="b1-regular ellipsis">
                      <span className="P300">
                        <span className="icon">{<Icon iconKey="check" />}</span>
                        Action
                      </span>
                      {badge.attributes.required_action}
                    </p>
                  </li>
                  <li>
                    <p className="b1-regular ellipsis">
                      <span className="P300">
                        <span className="icon">{<Icon iconKey="check" />}</span>
                        Project
                      </span>
                      {badge.attributes.project}
                    </p>
                  </li>
                  <li>
                    <p className="b1-regular ellipsis">
                      <span className="P300">
                        <span className="icon">{<Icon iconKey="check" />}</span>
                        Criterion
                      </span>
                      {badge.attributes.criterion}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right">
              <div className="badge">
                <BadgeComponent badge={badge} />
              </div>
              <Button
                label="View More Details"
                buttonStyle={ButtonStyle.SECONDARY}
                buttonSize={ButtonSize.S}
                handleClick={() => {
                  router.push(`${ROUTE.BADGE}/${tokenId}`);
                }}
              />
            </div>
          </div>
        </>
      </div>
      <style jsx>{`
        .container {
          width: 916px;
        }
        .header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 25px;
        }
        .body {
          display: flex;
          justify-content: space-between;
          min-height: 420px;
        }
        .body .left {
          width: 566px;
        }
        .body .left .buttons {
          display: flex;
          gap: 8px;
          margin-bottom: 24px;
        }
        .body .left .collectionName {
          margin-bottom: 4px;
          display: flex;
          align-items: center;
        }
        .body .left .collections-icon {
          width: 16px;
          height: 16px;
          background: url(/image/series-icon.png) center center no-repeat;
          background-size: 16px 16px;
          margin-right: 4px;
        }
        .body .left .badgeTitle {
          margin-bottom: 16px;
        }
        .body .left .chips {
          margin-bottom: 16px;
          display: flex;
          gap: 6px;
        }
        .body .left .info2 {
          margin-bottom: 24px;
        }
        .body > .left > .howToClaim {
          background: var(--BT15);
          border-radius: 24px;
          padding: 20px;
        }
        .body > .left > .howToClaim .title {
          margin-bottom: 12px;
        }
        .body > .left > .howToClaim > ul {
          background: var(--BT15);
          border-radius: 24px;
          padding: 24px 28px;
        }
        .body > .left > .howToClaim > ul li {
          margin-bottom: 4px;
        }
        .body > .left > .howToClaim > ul li:last-of-type {
          margin-bottom: 0px;
        }
        .body > .left > .howToClaim > ul li span {
          width: 140px;
          display: inline-block;
          align-items: center;
        }
        .body > .left > .howToClaim > ul li span span.icon {
          width: 16px;
          margin-right: 8px;
        }
        .body > .right {
          padding: 0px 20px 0px;
        }
        .body > .right > .badge {
          margin-bottom: 28px;
        }
      `}</style>
    </>
  );
};

export default BadgeDetailModal;
