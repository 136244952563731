import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { AuthAPI } from 'src/apis/auth-api';
import { TEST_ADDRESS } from 'src/apis/const';
import {
  BearerTokenSession,
  ClaimTxHashSession,
  IsCheckEligibilitiesRunningSession
} from 'src/apis/local-storage';
import { UsersAPI } from 'src/apis/users-api';
import { MODAL_TYPE } from 'src/components/common/modal/modal';
import { Provider } from 'src/modules/web3_modal';
import { mutate } from 'swr';
import useModal from './use-modal';

const signing = async (address: string) => {
  const authAPI = new AuthAPI();
  const provider = await Provider.getProvider();
  const nooxProvider = window.nooxProvider || provider;
  const { signMessage } = await authAPI.getSignMessage(address);
  const signResult = await nooxProvider.provider.request({
    method: 'personal_sign',
    params: [signMessage, address],
  });
  const { accessToken } = await authAPI.signIn({
    signMessage,
    address,
    signResult,
  });
  BearerTokenSession.set(accessToken);
  ClaimTxHashSession.remove();
  IsCheckEligibilitiesRunningSession.remove();
};

const signIn = async () => {
  try {
    const provider = await Provider.getProvider();
    const nooxProvider = window.nooxProvider || provider;
    const address = (await nooxProvider.getSigner().getAddress()).toLowerCase();
    await signing(address);
    return address;
  } catch (e: any) {
    console.log(e.message);
    console.error(`------SignIn------${e}`);
    throw e;
  }
};

const useConnectWallet = (): [any, any, boolean] => {
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();

  // const goToProfilePage = useCallback(async (address, isInit = null) => {
  //   await router.push({
  //     pathname: `${ROUTE.PROFILE}/${address}`,
  //     query: isInit
  //       ? {
  //           welcome: true,
  //         }
  //       : {},
  //   });
  // }, []);

  const [renderPfpModal, handleOpenPfpModal] = useModal(
    MODAL_TYPE.SelectPfp,
    async (contract: string, tokenId: string) => {
      try {
        const profile = await new UsersAPI().updateProfile({
          primaryPfp: {
            contract,
            tokenId,
          },
        });

        // await goToProfilePage(profile.address, true);
        router.reload()
      } catch (e: any) {
        console.error(e);
      }
    },
    async () => {
      router.reload()
    },
  );

  const tryLogin = async () => {
    try {
      setLoading(true);
      await showWeb3Modal();
    } catch (e: any) {
      if (e.code === 406) {
        await Provider.toggleModal();
      }
      if (e.code === 4001) {
        alert('MetaMask Message Signature: User denied message signature.');
      }
    } finally {
      setLoading(false);
    }
  };

  const getAddress = useCallback(async () => {
    return (await new UsersAPI().getProfile()).address;
  }, []);

  const showWeb3Modal = useCallback(async () => {
    const address = await signIn();
    if (address) {
      await handleSuccess(address);
    }
  }, []);

  // When Sign-in Success
  const handleSuccess = useCallback(
    async (address: string) => {
      const getProfileRes = await new UsersAPI().getProfile();
      const getPfpAssetsRes = await new UsersAPI().getPfpAssets(
        `?${TEST_ADDRESS}size=12`,
      );

      if (getProfileRes.isInit) {
        if (getPfpAssetsRes.assets.length === 0) {
          router.reload()
        } else {
          handleOpenPfpModal({
            isInit: true,
            address,
          });
        }
      } else {
        await mutate(UsersAPI.SWR_PATH.GET_PROFILE());
      }

      // await Provider.toggleModal();
    },
    [router],
  );

  const renderModal = useCallback(() => {
    return 
    <>{renderPfpModal}</>
  }, [renderPfpModal]);

  return [renderModal, tryLogin, loading];
};

export default useConnectWallet;
