import React, { useContext, useEffect, useState } from 'react';
import { InviteCodeSession } from 'src/apis/session-storage';

interface InviteCodeProps {
  code: string;
  setCode: (arg0?: any) => any;
}
const InviteCodeContext = React.createContext<InviteCodeProps>({
  code: '',
  setCode: () => {},
});

export const InviteCodeProvider: React.FC = ({ children }) => {
  const [code, setCode] = useState<string>(InviteCodeSession.get() as string);

  useEffect(() => {
    if (code) {
      InviteCodeSession.set(code);
    } else {
      InviteCodeSession.remove();
    }
  }, [code]);

  return (
    <InviteCodeContext.Provider value={{ code, setCode }}>
      {children}
    </InviteCodeContext.Provider>
  );
};

export const InviteCodeConsumer: React.FC<{
  action: (value: InviteCodeProps) => React.FC;
}> = ({ action }) => {
  return (
    <InviteCodeContext.Consumer>
      {(value) => action(value)}
    </InviteCodeContext.Consumer>
  );
};

export const useInviteCodeContext = ({
  defaultCode = '',
}: {
  defaultCode?: string;
}) => {
  const { code, setCode } = useContext(InviteCodeContext);
  useEffect(() => {
    if (defaultCode != '') {
      setCode(defaultCode);
    }
  }, [defaultCode]);

  return useContext(
    React.createContext({
      code,
      setCode,
    }),
  );
};
