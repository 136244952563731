import { getAddress } from '@ethersproject/address';
import * as lzuft8 from 'lzutf8';

export function isAddress(value: string): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function getShortAddress(address: string, trimNum: number): string {
  if (!address) return '';
  return `${address.slice(0, trimNum)}...${address.slice(trimNum * -1)}`;
}

export function getTruncatedValue(value: string, trimNum: number): string {
  if (!value) return '';
  return value.length >= trimNum ? value.slice(0, trimNum - 3) + '...' : value;
}

export function getNumberWithCommas(x: number | string): string {
  return (typeof x === 'string' ? x : x.toString()).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ',',
  );
}

export function stringify(obj: unknown): string {
  return JSON.stringify(obj);
}

export function isJson(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getOrdinalSuffixOf(i: number): string {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function stringToBytes(str: string) {
  const result = [];

  for (let i = 0; i < str.length; i += 2) {
    result.push(parseInt(str.substring(i, i + 2), 16));
  }
  return Uint8Array.from(result);
}

export function truncate(str: string, n: number): string {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
}

export function codePointsToString(codePoints: string[]): string {
  return codePoints
    .map((codePoint) => {
      return String.fromCodePoint(parseInt(codePoint.replace('U+', ''), 16));
    })
    .join('');
}

export function isObjectEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export const isMac = () => {
  return typeof window !== 'undefined'
    ? window.navigator.userAgent.indexOf('Mac') != -1
    : false;
};

// Time

const monthLabel = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const getDateString = (date: string) => {
  const d = new Date(date);
  return `${
    monthLabel[d.getUTCMonth()]
  } ${d.getUTCDate()}, ${d.getUTCFullYear()}`;
};

export const getLatestCheckTimeString = (date: string) => {
  const d = new Date(date);
  return `${getDateString(date)}  ${
    ('00' + d.getUTCHours()).slice(-2) +
    ':' +
    ('00' + d.getUTCMinutes()).slice(-2) +
    ':' +
    ('00' + d.getUTCSeconds()).slice(-2)
  } UTC`;
};

export const getMinDiff = (dateA: Date, dateB: Date): number => {
  let diff = (dateB.getTime() - dateA.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export function decompressToBase64(data: string): Record<string, string> {
  return JSON.parse(lzuft8.decompress(data, { inputEncoding: 'Base64' }));
}

export function parseQuery(queryString: string): Record<string, string> {
  const query: Record<any, any> = {};
  const pairs = (
    queryString[0] === '?' ? queryString.substr(1) : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function makeFirstLetterLowercase(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function delayPromise(duration: number) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(null);
    }, duration);
  });
}

export function getGradientBorder(
  fillColorA: string,
  fillColorB: string,
  strokeColorA: string,
  strokeColorB: string,
  thickness: number,
): string {
  return `
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-image: linear-gradient(${fillColorA}, ${fillColorB}), radial-gradient(
      circle at top left,
      ${strokeColorA},
      ${strokeColorB}
    );
    border: double ${thickness}px transparent;
  `;
}

export const isMobile = () => {
  if (typeof window === 'undefined') return false;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
};

export const isAvailableText = (str: string) => {
  return new RegExp('^[-A-Za-z0-9s!@#$%^&*’()_+=`~\\]["{}|\';:/.,?>< ]*$').test(
    str,
  );
};

export function debounce<Params extends any[]>(
  func: (...args: Params) => any,
  timeout: number,
): (...args: Params) => void {
  let timer: NodeJS.Timeout;
  return (...args: Params) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
}
