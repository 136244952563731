import React from 'react';
import ModalControl from './modal-control';

const ModalHeader: React.FC<{
  title: string | React.ReactNode;
  handleCancel: () => void;
  showCancel?: boolean;
  handleSubmit?: any | null;
  cancelText?: string;
  submitText?: string;
}> = ({
  title,
  handleCancel,
  handleSubmit,
  showCancel = false,
  cancelText,
  submitText,
}) => {
  return (
    <>
      <div className="header">
        <div className="h6-bold">{title}</div>
        <ModalControl
          handleCancel={handleCancel}
          showCancel={showCancel}
          handleSubmit={handleSubmit}
          cancelText={cancelText}
          submitText={submitText}
        />
      </div>
      <style jsx>
        {`
          div.header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-bottom: 24px;
          }
        `}
      </style>
    </>
  );
};

export default ModalHeader;
