interface Props {
  label: string;
  size?: 'small';
  bgColor?: 'white' | 'black';
}

const BadgeChip: React.FC<Props> = (props) => {
  const { label, size = '', bgColor = 'white' } = props;

  return (
    <>
      <div className="body">
        <label>
          <span className={`${size === 'small' ? 'cap1-bold' : 'b2-bold'}`}>
            {label}
          </span>
        </label>
      </div>
      <style jsx>{`
        div.body {
          display: inline-block;
          border-radius: 40px;
          padding: 1.6px 10px;
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(24px);
          background-color: ${bgColor === 'white'
            ? 'rgba(255, 255, 255, 0.1)'
            : 'rgba(0, 0, 0, 0.2)'};
          border: 1px solid rgba(255, 255, 255, 0.05);
        }
        label {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      `}</style>
    </>
  );
};

export default BadgeChip;
