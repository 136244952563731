export interface Interest {
  dataId: number;
  categoryId: number;
  categoryKey: string;
  name: string;
  emojiCodes: string[];
}

export const getCategorizedInterest = (
  interests: Interest[] | undefined,
): Interest[][] => {
  if (!interests) return [[]];
  const map: Record<number, Interest[]> = {};
  interests.map((interest) => {
    if (!map[interest.categoryId]) {
      map[interest.categoryId] = [interest];
    } else {
      map[interest.categoryId].push(interest);
    }
  });
  return Object.values(map);
};
