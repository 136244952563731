import React, { useCallback, useContext, useEffect, useState } from 'react';
import Icon from 'src/components/common/icon';
import { InputError } from 'src/components/common/input-v2';
import { MODAL_TYPE } from 'src/components/common/modal/modal';
import Select from 'src/components/common/select';
import { ABI } from 'src/hooks/use-create-badge-state/abi-state';
import { ActionType } from 'src/hooks/use-create-badge-state/action';
import {
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
} from 'src/hooks/use-create-badge-state/index';
import useModal from 'src/hooks/use-modal';

const CriteriaSelector: React.FC<{ index: number; isDisabled: boolean }> = ({
  index,
  isDisabled,
}) => {
  const [state, setState, error, setError] = useContext(
    CreateBadgeStateContext,
  );
  const currentAction = (state?.eligibilityRule.actionUnits)[index];
  const [renderAdvancedCriteria, handleOpenAdvancedCriteria] = useModal(
    MODAL_TYPE.AdvancedCriteria,
    async () => {},
  );

  const getOptions = useCallback(() => {
    return currentAction.action.name
      ? currentAction.action.inputs
          .filter((input) => {
            return input.type === 'address';
          })
          .map((input) => ({
            label: ABI.getParamLabelComp(input),
            value: input.name,
          }))
      : [];
  }, [currentAction.action.name, currentAction.action.inputs]);

  const [options, setOptions] = useState<
    {
      label: any;
      value: any;
    }[]
  >(getOptions());

  useEffect(() => {
    if (!currentAction.userEOAField) {
      setOptions([]);
      setTimeout(() => {
        if (currentAction.action.name) {
          setOptions(getOptions());
        }
      }, 0);
    }
  }, [
    currentAction.action.name,
    currentAction.action.inputs,
    currentAction.userEOAField,
    getOptions,
  ]);

  const _isSelectsDisabled = isDisabled || currentAction.type === ActionType.TX;

  return (
    <>
      {renderAdvancedCriteria}
      <div className={`wrap ${isDisabled && 'disabled'}`}>
        <div className="action">
          {currentAction.action.name && currentAction.type === ActionType.TX ? (
            <div className="input b1-regular">
              <span className="P200">address from</span>
              <span
                className="cap1-bold G100"
                style={{
                  padding: '4px 8px',
                  background: 'var(--WT15)',
                  borderRadius: 8,
                  marginLeft: 4,
                }}
              >
                TX Object
              </span>
            </div>
          ) : (
            <Select
              defaultValue={
                currentAction.userEOAField
                  ? (() => {
                      const input = currentAction.action.inputs.filter(
                        (input) => input.name === currentAction.userEOAField,
                      )[0];
                      return {
                        label: ABI.getParamLabelComp(input),
                        value: input.name,
                      };
                    })()
                  : undefined
              }
              disabled={_isSelectsDisabled}
              handleOnChange={(val) => {
                setState((state) => {
                  const newEligibilityRulesActionUnits = [
                    ...state?.eligibilityRule.actionUnits,
                  ];
                  newEligibilityRulesActionUnits[index].userEOAField = val;
                  return {
                    ...state,
                    eligibilityRule: {
                      ...state.eligibilityRule,
                      actionUnits: newEligibilityRulesActionUnits,
                    },
                  };
                });
              }}
              error={
                (error.eligibilityRule as any).actionUnits[index]
                  .userEOAField as InputError
              }
              errorArr={
                CreateBadgeStateValidation.eligibilityRule.actionUnits
                  .userEOAField
              }
              placeholder="Select who is an eligible user"
              options={options}
              // disabled={isViewPage}
              singleValueStyle={{
                maxWidth: 282,
              }}
              menuListStyle={{
                maxHeight: 164,
              }}
            />
          )}
        </div>
        <div
          className={`advanced G100 ${_isSelectsDisabled && 'disabled'}`}
          onClick={() => {
            if (!_isSelectsDisabled) {
              handleOpenAdvancedCriteria({
                index,
              });
            }
          }}
        >
          {currentAction.filters.length > 0 && (
            <div className="num b2-bold">+ {currentAction.filters.length}</div>
          )}

          <div className="label b2-regular">Advanced Criteria</div>
          <div className="arrow">
            <Icon iconKey="chevronDown" strokeWidth={4} />
          </div>
        </div>
      </div>
      <style jsx>{`
        .wrap {
          width: 337.33px;
          border: 2px solid var(--G400);
          border-radius: 16px;
          padding: 16px;
          background: var(--G900);
          min-height: 104px;
        }
        .action {
          margin-bottom: 12px;
        }
        .advanced {
          cursor: pointer;
          border: 1px solid var(--WT15);
          border-radius: 8px;
          width: fit-content;
          padding: 6px 10px 6px 8px;
          display: flex;
          gap: 10px;
        }
        .advanced.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        .arrow {
          width: 12px;
          display: flex;
        }
        .input {
          resize: none;
          background: var(--G800);
          border: none;
          border-radius: 8px;
          height: 24px;
          width: calc(100% - 26px);
          color: var(--WT100);
          padding: 12px 12px 12px 14px;
          cursor: text;
        }
      `}</style>
    </>
  );
};

export default CriteriaSelector;
