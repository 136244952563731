import React from 'react';
import { CollectionClaimedBadge } from 'src/entity/collection';

export enum PrimaryBadgeChipType {
  PRIMARY,
  NORMAL,
}

const PrimaryBadgeChip: React.FC<{
  badge: Pick<
    CollectionClaimedBadge,
    'nftName' | 'imageThumbnailUrl' | 'badgeId'
  >;
  type?: PrimaryBadgeChipType;
}> = ({ badge, type = PrimaryBadgeChipType.NORMAL }) => {
  return (
    <>
      <div className="primary-badge cap1-regular">
        <div
          className="primary-badge-thumbnail"
          style={{
            background: `url(${badge.imageThumbnailUrl}) left center no-repeat`,
            backgroundSize: '12px 12px',
          }}
        ></div>
        <div className="primary-badge-text ellipsis">{badge.nftName}</div>
      </div>

      <style jsx>
        {`
          .primary-badge {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 12px;
            border-radius: 22px;
            width: fit-content;

            ${type === PrimaryBadgeChipType.NORMAL
              ? ` background: var(--G700);
                  border: 1px solid var(--G600);
                  padding: 2px 7px;
                  color: var(--G200)`
              : ` background: var(--GR-PP);
                  border: 0;
                  padding: 2px 16px;
                  color: var(--WT100);
                  font-weight: var(--font-bold);`}
          }
          .primary-badge-thumbnail {
            width: 12px;
            height: 12px;
            border-radius: 100%;
          }
          .primary-badge-text {
            max-width: 194px;
          }
        `}
      </style>
    </>
  );
};

export default PrimaryBadgeChip;
