import 'cropperjs/dist/cropper.css';
import React, { useCallback, useEffect, useState } from 'react';
import { EligibilitiesAPI } from 'src/apis/eligibilities-api';
import supportedChains from 'src/const/chains';
import { Badge } from 'src/entity/badge';
import { getShortAddress } from 'src/utils';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';
import Loading from '../loading';

const ApprovedTxModal: React.FC<{
  // mutateEligibility
  badge: Badge;
  handleClose: () => void;
  handleSubmit: () => void;
}> = ({ badge, handleClose = () => {} }) => {
  const [showResult, setShowResult] = useState<boolean>(false);
  const pollEligibility = useCallback(() => {
    let seconds = 0;
    const cb = async () => {
      if (seconds > 1 && seconds % 60 === 0) {
        await new EligibilitiesAPI().updateEligibilityDetail(badge.id);
      }

      await new EligibilitiesAPI()
        .getEligibilityDetail(badge.id, '')
        .then((res) => {
          if (res.state === 1) {
            setShowResult(true);
            clearInterval(id);
          }
          if (res.state === 0) {
            throw new Error();
          }
        })
        .catch((e) => {
          console.debug(e);
          clearInterval(id);
        });
      seconds += 1;
    };
    const id = setInterval(cb, 2000);
  }, [badge]);

  useEffect(() => {
    new EligibilitiesAPI().updateEligibilityDetail(badge.id).then(() => {
      pollEligibility();
    });
  }, [badge, pollEligibility]);

  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h6-bold WT100">Eligible TXs</p>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <div className="body scroll">
          <p className="b2-regular G100 desc">
            Here is a list of your transactions that are eligible for this
            badge. The eligibility check takes 10+ mins to sync transactions. If
            you have eligible transactions but still not seeing them here,
            please try again 10 mins later.
          </p>
          <div className="wrap">
            <ResultSection badge={badge} showResult={showResult} />
          </div>
          <div className="button-wrap">
            <Button
              label="OK"
              buttonStyle={ButtonStyle.SECONDARY}
              buttonSize={ButtonSize.L}
              handleClick={() => {
                handleClose();
              }}
              style={{
                margin: 0,
              }}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 720px;
          background: var(--G900);
          margin: 0 auto;
        }
        .header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 24px;
        }

        p.desc {
          margin-bottom: 20px;
        }

        div.body {
        }

        .criteria {
          margin-bottom: 20px;
        }
        .criteria:last-of-tpye {
          margin-bottom: 24px;
        }

        .button {
          width: fit-content;
          background: var(--BT15);
          padding: 8px 12px;
          border: 1px solid var(--G700);
          border-radius: 8px;
          align-items: center;
          cursor: pointer;
        }

        .button.disabled {
          cursor: not-allowed;
          color: var(--B600);
        }
        .button-wrap {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 24px;
        }
      `}</style>
    </>
  );
};

const ResultSection: React.FC<{
  badge: Badge;
  showResult: boolean;
}> = ({ badge, showResult }) => {
  const [data, lastElementRef, isValidating] =
    new EligibilitiesAPI().useGetEligibilityDetail(badge.id, {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  const isLoading = !data || isValidating;
  const transactions = isLoading ? [] : [...data!.transactions];

  const isTotalUSDExist = transactions.length > 0 && !!transactions[0].totalUSD;
  const cellWidths = isTotalUSDExist
    ? [50, 223.33, 223.33, 223.33]
    : [50, 335, 335, 0];

  if (!showResult || isLoading) {
    return (
      <>
        <div className="loading">
          <div>
            <Loading />
          </div>
        </div>
        <style jsx>
          {`
            .loading {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 90px;
              background: var(--G800);
              border-radius: 8px;
            }
          `}
        </style>
      </>
    );
  }
  return (
    <>
      {transactions.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th
                style={{
                  width: cellWidths[0],
                  minWidth: cellWidths[0] - 32,
                  maxWidth: cellWidths[0] - 32,
                }}
                className={`b2-medium`}
              ></th>
              <th
                style={{
                  width: cellWidths[1],
                  minWidth: cellWidths[1] - 32,
                  maxWidth: cellWidths[1] - 32,
                  textAlign: 'center',
                }}
                className={`b2-medium G300`}
              >
                Transaction Hash
              </th>
              <th
                style={{
                  width: cellWidths[2],
                  minWidth: cellWidths[2] - 32,
                  maxWidth: cellWidths[2] - 32,
                  textAlign: 'center',
                }}
                className={`b2-medium G300`}
              >
                Block number
              </th>
              {isTotalUSDExist && (
                <th
                  style={{
                    width: cellWidths[3],
                    minWidth: cellWidths[3] - 32,
                    maxWidth: cellWidths[3] - 32,
                    textAlign: 'center',
                  }}
                  className={`b2-medium G300`}
                >
                  Traded $USD
                </th>
              )}
            </tr>
          </thead>
          <tbody className="">
            {transactions.map((row, i) => {
              const isLastItem = i === transactions.length - 1;
              return (
                <tr key={i} ref={isLastItem ? lastElementRef : null}>
                  <td
                    style={{
                      width: cellWidths[0],
                      minWidth: cellWidths[0] - 32,
                      maxWidth: cellWidths[0] - 32,
                    }}
                    className="b2-bold G50"
                  >
                    {row.index}
                  </td>
                  <td
                    style={{
                      width: cellWidths[1],
                      minWidth: cellWidths[1] - 32,
                      maxWidth: cellWidths[1] - 32,
                      textAlign: 'center',
                    }}
                    className="b2-regular G300"
                  >
                    <span
                      style={{
                        paddingRight: 26,
                        background:
                          'url(/image/external-link.png) center right no-repeat',
                        backgroundSize: '20px 20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        window
                          .open(
                            `${supportedChains[badge.chain].etherscan_url}/tx/${
                              row.hash
                            }`,
                            '_blank',
                          )
                          ?.focus();
                      }}
                    >
                      {getShortAddress(row.hash, 6)}
                    </span>
                  </td>
                  <td
                    style={{
                      width: cellWidths[2],
                      minWidth: cellWidths[2] - 32,
                      maxWidth: cellWidths[2] - 32,
                      textAlign: 'center',
                    }}
                    className="b2-regular G300"
                  >
                    {row.blockNumber}
                  </td>
                  {isTotalUSDExist && (
                    <td
                      style={{
                        width: cellWidths[3],
                        minWidth: cellWidths[3] - 32,
                        maxWidth: cellWidths[3] - 32,
                        textAlign: 'center',
                      }}
                      className="b2-regular G300"
                    >
                      ${row.totalUSD}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="empty">
          <p className="b2-regular G300">There is no eligible transaction.</p>
        </div>
      )}
      <style jsx>{`
        table {
          width: 100%;
        }
        thead {
          background: var(--G700);
          color: var(--G300);
          border-bottom: 3px solid var(--G600);
          display: block;
          border-radius: 8px 8px 0px 0px;
        }
        thead tr {
        }
        thead tr th {
          padding: 17px 16px 9px;
        }
        thead tr th:first-of-type {
          border-radius: 8px 0px 0px 0px;
        }
        thead tr th:last-of-type {
          border-radius: 0px 8px 0px 0px;
        }
        tbody {
          display: block;
          height: 340px;
          overflow-y: auto;
          overflow-x: hidden;
        }
        tbody tr {
          background: var(--G800);
          color: var(--G300);
        }
        tbody tr.clickable {
          cursor: pointer;
        }
        tbody tr:hover {
          background: var(--G700);
          color: var(--G300);
        }
        tbody tr td {
          padding: 11.5px 16px;
          border-bottom: 1px solid var(--G900);
        }
        tbody tr:last-of-type td:first-of-type {
          border-radius: 0px 0px 0px 8px;
        }
        tbody tr:last-of-type td:last-of-type {
          border-radius: 0px 0px 8px 0px;
        }
        .empty {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90px;
          background: var(--G800);
          border-radius: 8px;
        }
      `}</style>
    </>
  );
};

export default ApprovedTxModal;
