import React, { useEffect, useState } from 'react';
import { DISCOURSE_URL } from 'src/apis/const';
import { Button } from '..';
import { ButtonSize, ButtonStyle } from '../common/button';

const FeedbackSection: React.FC = () => {
  const [imgHeight, setImgHeight] = useState<number>(385);
  useEffect(() => {
    const curHeight =
      document?.getElementById('profile-container')?.offsetHeight || 0;
    if (curHeight < 297) {
      setImgHeight(0);
    } else {
      setImgHeight((110 * curHeight) / 385);
    }
  }, []);
  return (
    <>
      <div className="container" id="feedback-container">
        {imgHeight !== 0 && <div className="image"></div>}
        <p className="text b1-regular">
          <span className="b1-bold">Share your feedback</span>
          Noox is still in its infancy and needs your love and attention to grow
          up. <br />
          Please tell us about your experience with Noox.
        </p>
        <Button
          label="Feedback"
          buttonStyle={ButtonStyle.PRIMARY}
          buttonSize={ButtonSize.M}
          handleClick={() => {
            window.open(DISCOURSE_URL, '_blank')?.focus();
          }}
        />
      </div>
      <style jsx>{`
        .container {
          width: 100%;
          text-align: center;
          max-height: 465px;
        }
        .image {
          width: ${imgHeight}px;
          height: ${imgHeight}px;
          max-width: 111px;
          max-height: 111px;
          min-width: 55px;
          min-height: 55px;
          margin: 10px auto 20px;
          background: no-repeat center center url(/image/feedback-main.png);
          background-size: 100% 100%;
        }
        p {
          margin-bottom: 20px;
        }
        span {
          display: block;
          margin-bottom: 12px;
        }
      `}</style>
    </>
  );
};

export default FeedbackSection;
