import { SWRConfiguration, SWRResponse } from 'swr';
import { Badge, getBadgeTierInfo } from '../entity/badge';
import { API } from './api';
import { API_CONST } from './const';
import { _useSWR } from './swr';

export class BadgeGroupsAPI extends API {
  constructor(_url?: string) {
    super(API_CONST.ROOT_URL, _url || API_CONST.BADGE_GROUPS_API_URL);
  }

  static PATH = {
    GET_GROUP_BADGES: (groupId: string): string => `${groupId}`,
  };

  getSwrPath(): Record<string, any> {
    return {
      GET_GROUP_BADGES: (groupId: string) =>
        `${this.controllerUrl}${BadgeGroupsAPI.PATH.GET_GROUP_BADGES(groupId)}`,
    };
  }

  async getGroupBadges(
    groupId: string,
    excludeBadges: string[],
  ): Promise<Badge[]> {
    const _excludeBadgeIds = excludeBadges.join(',');
    const res = await this.get(
      `${BadgeGroupsAPI.PATH.GET_GROUP_BADGES(
        groupId,
      )}?excludeBadges=${_excludeBadgeIds}`,
    );

    const badges = res.badges.map((badge: Badge) => ({
      ...badge,
      ...getBadgeTierInfo(badge),
    }));

    return badges;
  }

  useGetGroupBadges(
    groupId: string,
    excludeBadges: string[],
    options: SWRConfiguration = {},
  ): SWRResponse<Badge[], Error> {
    return _useSWR<Badge[]>(
      groupId ? this.getSwrPath().GET_GROUP_BADGES(groupId) : null,
      async () => this.getGroupBadges(groupId, excludeBadges),
      options,
    );
  }
}
