import { BigNumber } from 'ethers';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { UserBadge, UserBadgeDto } from 'src/apis/user-badges-api';
import { defaultInputError } from 'src/components/common/input-v2';
import { ABI } from './abi-state';
import { Action } from './action';

export enum PageType {
  CREATE = 'Create',
  EDIT = 'Edit',
  VIEW = 'View',
}

export type ImageFile = File & { preview: string };

export interface CreateBadgeState {
  title: string;
  id?: string;
  descriptionEligibility?: string;
  description?: string;
  project?: string;
  projectCategory?: string;
  projectUrl?: string;
  discordHandle?: string;
  email?: string;

  badgeThumbnail?: ImageFile | null;
  backgroundImage?: ImageFile | null;
  projectLogo?: ImageFile | null;
  // bgColor?: string[];

  eligibilityRule: {
    actionUnits: Action[];
    required: string;
    // eligibleAddresses: string[];
  };
  validationLogic?: number;

  memo?: string;
  creatorAddress?: string;
}

export const defaultActionInputError = {
  contract: defaultInputError,
  action: defaultInputError,
  userEOAField: defaultInputError,
  aggregations: defaultInputError,
};

export const defaultState = {
  id: '',
  title: '',
  descriptionEligibility: '',
  description: '',
  project: '',
  projectCategory: '',
  projectUrl: '',

  badgeThumbnail: null,
  backgroundImage: null,
  projectLogo: null,
  // bgColor: ['#55409B', '#683889'],

  eligibilityRule: {
    actionUnits: [new Action()],
    required: '',
    // eligibleAddresses: [''],
  },
  discordHandle: '',
  email: '',
  memo: '',
  creatorAddress: '',
};

const defaultErrorState = (actionLength: number) => ({
  title: defaultInputError,
  descriptionEligibility: defaultInputError,
  description: defaultInputError,
  project: defaultInputError,
  projectCategory: defaultInputError,
  projectUrl: defaultInputError,
  badgeThumbnail: defaultInputError,
  projectLogo: defaultInputError,
  eligibilityRule: {
    actionUnits: Array(actionLength).fill({
      ...defaultActionInputError,
    }),
    required: defaultInputError,
  },
  discordHandle: defaultInputError,
});

export const CreateBadgeStateContext = React.createContext<
  [
    CreateBadgeState,
    Dispatch<SetStateAction<CreateBadgeState>>,
    Record<string, any>,
    Dispatch<SetStateAction<Record<string, any>>>,
    Record<
      string,
      {
        abi: ABI | null;
        isCustomABI: boolean;
      } | null
    >,
    Dispatch<
      SetStateAction<
        Record<
          string,
          {
            abi: ABI | null;
            isCustomABI: boolean;
          } | null
        >
      >
    >,
    PageType,
  ]
>([
  {
    title: '',
    eligibilityRule: {
      actionUnits: [],
      required: '',
      // eligibleAddresses: [],
    },
  },
  () => {},
  {
    ...defaultErrorState(1),
  },
  () => {},
  {},
  () => {},
  PageType.CREATE,
]);

const checkValueExistValidation = (label: string) => ({
  checkValueExist: {
    validate: (val: string | undefined): boolean => (val || '').trim() !== '',
    msg: `${label} can't be blank.`,
  },
});

const checkImageExistValidation = (label: string) => ({
  checkValueExist: {
    validate: (val: ImageFile | null | undefined): boolean =>
      val !== null && val !== undefined,
    msg: `${label} can't be blank.`,
  },
});

export const CreateBadgeStateValidation = {
  title: {
    ...checkValueExistValidation('Title'),
  },
  descriptionEligibility: {
    ...checkValueExistValidation('1-liner Eligibility Description'),
  },
  description: {
    ...checkValueExistValidation('Description'),
  },
  project: {
    ...checkValueExistValidation('Project'),
  },
  projectCategory: {
    ...checkValueExistValidation('Project Category'),
  },
  projectUrl: {
    ...checkValueExistValidation('URL of the project'),
  },
  badgeThumbnail: {
    ...checkImageExistValidation('Badge Thumbnail'),
  },
  eligibilityRule: {
    actionUnits: {
      contract: {
        ...checkValueExistValidation('Contract Address'),
      },
      action: {
        ...checkValueExistValidation('Action'),
      },
      userEOAField: {
        ...checkValueExistValidation('Eligible User'),
      },
      aggregations: {
        ...checkValueExistValidation('Aggregations'),
      },
    },
    required: {
      ...checkValueExistValidation('Threshold'),
    },
  },
  discordHandle: {
    ...checkValueExistValidation('Discord Handle'),
  },
};

export const CreateBadgeStateProvider: React.FC<{
  defaultValue?: CreateBadgeState;
  defaultAbiValue?: Record<
    string,
    {
      abi: ABI | null;
      isCustomABI: boolean;
    }
  >;
  pageType: PageType;
  children: any;
}> = ({ defaultValue, defaultAbiValue = {}, pageType, children }) => {
  const [abiState, setAbiState] = useState<
    Record<
      string,
      {
        abi: ABI | null;
        isCustomABI: boolean;
      }
    >
  >(defaultAbiValue);
  const [state, setState] = useState<CreateBadgeState>(
    defaultValue || defaultState,
  );

  const [error, setError] = useState<Record<string, any>>({
    ...defaultErrorState(defaultValue?.eligibilityRule.actionUnits.length || 1),
  });

  return (
    <CreateBadgeStateContext.Provider
      value={[
        state,
        setState,
        error,
        setError,
        abiState,
        setAbiState as any,
        pageType,
      ]}
    >
      {children}
    </CreateBadgeStateContext.Provider>
  );
};

export const convertToUserBadgeDto = (
  state: CreateBadgeState,
): UserBadgeDto => {
  return {
    title: state.title,
    eligibilityDescription: state?.descriptionEligibility || '',
    description: state?.description || '',
    project: {
      name: state?.project || '',
      categories: [state?.projectCategory || ''],
      url: state?.projectUrl || '',
    },
    image: {
      thumbnailUrl: (state?.badgeThumbnail as any) || '',
      backgroundUrl: (state?.backgroundImage as any) || '',
      logoUrl: (state?.projectLogo as any) || '',
    },
    // bgColor: [...(state?.bgColor || [])],
    eligibilityRule: {
      actionUnits:
        state?.eligibilityRule?.actionUnits
          .filter((actionUnit) => !!actionUnit.contract)
          .map((action) => action.convertToJSON()) || [],
      required: state?.eligibilityRule?.required
        ? BigNumber.from(state?.eligibilityRule?.required).toHexString()
        : '',
      // eligibleAddresses: [...(state?.eligibilityRule?.eligibleAddresses || [])],
    },
    discordHandle: state?.discordHandle || '',
    email: state?.email || '',
  };
};

export const convertToCreateBadgeState = (
  state: UserBadge,
  abi: Record<
    string,
    {
      abi: ABI | null;
      isCustomABI: boolean;
    } | null
  >,
): CreateBadgeState => {
  const _arr = state?.eligibilityRule?.actionUnits.map((action) =>
    Action.fromJSON(action, abi),
  );
  return {
    id: state?.id || defaultState.id,
    title: state.title,
    descriptionEligibility:
      state?.eligibilityDescription || defaultState.descriptionEligibility,
    description: state?.description || defaultState.description,
    project: state?.project.name || defaultState.project,
    projectCategory:
      state?.project.categories[0] || defaultState.projectCategory,
    projectUrl: state?.project.url || defaultState.projectUrl,
    badgeThumbnail: (state?.image.thumbnailUrl as any)
      ? ({
          preview: state?.image.thumbnailUrl,
        } as any)
      : defaultState.badgeThumbnail,
    backgroundImage: (state?.image.backgroundUrl as any)
      ? ({
          preview: state?.image.backgroundUrl,
        } as any)
      : defaultState.backgroundImage,
    projectLogo: (state?.image.logoUrl as any)
      ? ({
          preview: state?.image.logoUrl,
        } as any)
      : defaultState.projectLogo,
    eligibilityRule: {
      actionUnits:
        _arr.length > 0 ? _arr : defaultState.eligibilityRule.actionUnits,
      required: state?.eligibilityRule.required
        ? BigNumber.from(state?.eligibilityRule.required).toString()
        : defaultState.eligibilityRule.required,
      // eligibleAddresses: [
      //   ...(state?.eligibilityRule.eligibleAddresses ||
      //     defaultState.eligibilityRule.eligibleAddresses),
      // ],
    },
    // bgColor: [...state?.bgColor] || defaultState.bgColor,
    discordHandle: state?.discordHandle || defaultState.discordHandle,
    email: state?.email || defaultState.email,
    memo: state?.memo || defaultState.memo,
    creatorAddress: state?.creator?.address || defaultState.creatorAddress,
  };
};
