import React from 'react';
import Icon from './icon';

interface Props {
  id: number;
  isHovered: boolean;
  isSelected: boolean;
  handleSelected?: () => void;
}

const Checkbox: React.FC<Props> = ({
  id,
  isHovered,
  isSelected,
  handleSelected = () => {},
}) => {
  return (
    <>
      <div
        onClick={() => {
          if (handleSelected) {
            handleSelected();
          }
        }}
      >
        <input
          checked={isSelected}
          type="checkbox"
          id={`cb${id}`}
          className={isHovered ? 'hover' : ''}
        />
        <label htmlFor={`cb${id}`}>
          {isSelected && <Icon iconKey="check" />}
        </label>
      </div>

      <style jsx>{`
        input[id='cb${id}'] + label {
          display: inline-block;
          width: 28px;
          height: 28px;
          border-radius: 100%;
          cursor: pointer;
          background: var(--B600);
        }
        input[id='cb${id}'].hover + label {
          background: var(--B800);
        }
        input[id='cb${id}']:checked + label {
          display: inline-block;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;
          padding: 5px;
          background: var(--P500);
        }

        input[id='cb${id}'] {
          display: none;
        }
      `}</style>
    </>
  );
};

export default Checkbox;
