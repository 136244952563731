import React from 'react';
import ReactTooltip from 'react-tooltip';
import { BadgesAPI } from 'src/apis/badges-api';
import supportedChains from 'src/const/chains';
import { CriteriaReq, splitBadgeLogicDesc } from 'src/entity/badge';
import { codePointsToString } from 'src/utils';
import Chip, { ChipSize, ChipStyle } from '../chip';
import CloseIconButton from '../close-icon-button';
import Icon from '../icon';
import Loading from '../loading';

const BadgeVersionModal: React.FC<{
  badgeId: string;
  handleClose: () => void;
  handleSubmit: any;
}> = ({ badgeId, handleClose }) => {
  const { data: badge } = new BadgesAPI().useGetBadge(badgeId);
  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!badge) {
    return (
      <>
        <div className="container">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 620,
            }}
          >
            <Loading />
          </div>
        </div>
        <style jsx>
          {`
            .container {
              width: 670px;
              min-height: 420px;
            }
          `}
        </style>
      </>
    );
  }

  const { targets } = badge.badgeLogicDesc.logicArgs.args;

  return (
    <>
      <div className="container">
        <>
          <div className="header">
            <p className="h7-medium P200">Version History</p>
            <div className="close-button">
              <CloseIconButton handleClose={handleClose} />
            </div>
          </div>
          <div className="body scroll">
            <div className="info1">
              {/* {badge.serieses.length > 0 && (
                <div className="collectionName yellow b2-medium pre-wrap">
                  <span data-tip="Series" className="collections-icon"></span>
                  <span style={{ paddingTop: 1 }}>
                    {badge.serieses[0].name}
                  </span>
                </div>
              )} */}
              <p className="badgeTitle h5-bold pre-wrap">{badge.nftName}</p>
              <div className="chips">
                <Chip
                  label={`Version ${badge.revision}`}
                  chipStyle={ChipStyle.WHITE}
                  chipSize={ChipSize.S}
                />
                <Chip
                  label={badge._bhLabel}
                  handleClick={() => {
                    window
                      .open(
                        `${
                          supportedChains[badge.chain]
                            .etherscan_url
                        }/block/${badge.publishBH}`,
                        '_blank',
                      )
                      ?.focus();
                  }}
                  chipStyle={ChipStyle.WHITE}
                  chipSize={ChipSize.S}
                />
              </div>
            </div>
            <div className="info2 b2-regular P200 pre-wrap">
              {badge.description}
            </div>
            <div className="howToClaim">
              <p className="title h7-bold">
                {codePointsToString(['U+1F525'])} How to claim?
              </p>
              <ul>
                <li>
                  <p className="b1-regular ellipsis">
                    <span className="P300">
                      <span className="icon">{<Icon iconKey="check" />}</span>
                      Action
                    </span>
                    {badge.attributes.required_action}
                  </p>
                </li>
                <li>
                  <p className="b1-regular ellipsis">
                    <span className="P300">
                      <span className="icon">{<Icon iconKey="check" />}</span>
                      Project
                    </span>
                    {badge.attributes.project}
                  </p>
                </li>
                <li>
                  <p className="b1-regular ellipsis">
                    <span className="P300">
                      <span className="icon">{<Icon iconKey="check" />}</span>
                      Criterion
                    </span>
                    {badge.attributes.criterion}
                  </p>
                </li>
              </ul>
            </div>
            <div className="howToValidate">
              <p className="title h6-bold">
                {codePointsToString(['U+1F440'])} How do we validate?
              </p>
              <div className="validate-body">
                {targets && (
                  <div className="logic1">
                    <p className="b1-bold">Target contracts and actions</p>
                    <ul className="scroll">
                      {targets.map((item: CriteriaReq, i: number) => (
                        <li className="b2-regular" key={i}>
                          <span className="b2-bold">{i + 1}</span>
                          <span
                            className="chip b-address"
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              window
                                .open(
                                  `${
                                    supportedChains[
                                      badge.chain
                                    ].etherscan_url
                                  }/address/${item.address}`,
                                  '_blank',
                                )
                                ?.focus();
                            }}
                          >
                            {item.address}
                          </span>
                          {item.method && (
                            <span className="chip">{item.method}</span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="logic2">
                  <p className="b1-bold">Validation logic</p>
                  <ul>
                    <li>
                      {splitBadgeLogicDesc(badge.badgeLogicDesc).map(
                        (item, i) => (
                          <span key={i} className="chip b2-regular">
                            {item}
                          </span>
                        ),
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <style jsx>{`
        .container {
          width: 670px;
        }
        .header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 25px;
        }
        .body {
          overflow-y: auto;
          overflow-x: hidden;
          min-height: 420px;
          max-height: 670px;
          height: calc(100vh - 120px);
          margin-bottom: -20px;
          margin-right: -24px;
          padding-right: 12px;
        }

        .body .buttons {
          display: flex;
          gap: 8px;
          margin-bottom: 24px;
        }
        .body .collectionName {
          margin-bottom: 4px;
          display: flex;
          align-items: center;
        }
        .body .collections-icon {
          width: 16px;
          height: 16px;
          background: url(/image/series-icon.png) center center no-repeat;
          background-size: 16px 16px;
          margin-right: 4px;
        }
        .body .badgeTitle {
          margin-bottom: 24px;
        }
        .body .chips {
          margin-bottom: 24px;
          display: flex;
          gap: 6px;
        }
        .body .info2 {
          padding-bottom: 24px;
          margin-bottom: 24px;
          border-bottom: 1px solid var(--WT15);
        }
        .body > .howToClaim {
          border-radius: 24px;
          margin-bottom: 24px;
        }
        .body > .howToClaim .title,
        .body > .howToValidate .title {
          margin-bottom: 12px;
        }
        .body > .howToClaim > ul {
          background: var(--BT15);
          border-radius: 24px;
          padding: 24px 28px;
        }
        .body > .howToClaim > ul li {
          margin-bottom: 4px;
        }
        .body > .howToClaim > ul li:last-of-type {
          margin-bottom: 0px;
        }
        .body > .howToClaim > ul li span {
          width: 140px;
          display: inline-block;
          align-items: center;
        }
        .body > .howToClaim > ul li span span.icon {
          width: 16px;
          margin-right: 8px;
        }

        .body > .howToValidate .validate-body {
          background: var(--BT15);
          border-radius: 24px;
          padding: 24px;
        }
        .body > .howToValidate .validate-body .logic1 {
          margin-bottom: 32px;
          overflow: auto;
        }

        .body > .howToValidate .validate-body .logic1 p,
        .logic2 p {
          margin-bottom: 16px;
        }
        .body > .howToValidate .validate-body .logic1 ul {
          max-height: 200px;
          overflow-y: scroll;
        }
        .body > .howToValidate .validate-body .logic1 li {
          margin-bottom: 8px;
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .badgeValidation
          > .left
          > .howToValidate
          .validate-body
          .logic1
          li
          span:first-of-type {
          color: var(--WT60);
          display: block;
          margin-right: 13.5px;
        }
        .body > .howToValidate .validate-body .logic1 span.chip {
          background: var(--WT10);
          display: block;
          border-radius: 100px;
          padding: 5.5px 16px;
          margin-right: 8px;
        }
        .badgeValidation
          > .left
          > .howToValidate
          .validate-body
          .logic1
          span.chip.contract-address:hover {
          opacity: 0.8;
        }
        .body > .howToValidate .validate-body .logic2 span.chip {
          background: var(--WT10);
          display: inline-block;
          border-radius: 100px;
          padding: 5.5px 16px;
          margin-right: 8px;
        }
      `}</style>
    </>
  );
};

export default BadgeVersionModal;
