// /notices/?OnlyLatest=true

import { API } from './api';
import { API_CONST } from './const';

export class NoticesAPI extends API {
  constructor() {
    super(API_CONST.ROOT_URL, API_CONST.NOTICES_API_URL);
  }

  static PATH = {
    GET_NOTICES: ``,
  };

  async getNotices(): Promise<{
    id: string;
    content: string;
    link: string;
    isVisible: boolean;
  }> {
    const res = await this.get(
      `${NoticesAPI.PATH.GET_NOTICES}?OnlyLatest=true`,
    );
    return res;

    // return {
    //   id: '1',
    //   content: '123',
    //   link: 'https://naver.com',
    //   isVisible: true,
    // };
  }
}
