import { Box, Modal as MUIModal } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Badge } from 'src/entity/badge';
import { ImageFile } from 'src/hooks/use-create-badge-state';
import AdvancedCriteriaModal from './advanced-criteria-modal';
import ApprovedTxModal from './approved-tx-modal';
import BadgeDetailModal from './badge-detail-modal';
import BadgeVersionModal from './badge-version-modal';
import ContactModal from './contact-modal';
import EditProfileModal from './edit-profile-modal';
import HideBadgeModal from './hide-badge-modal';
import ImageCropModal from './image-crop-modal';
import JoinWaitlistModal from './join-waitlist-modal';
import SelectInterestModal from './select-interest-modal';
import SelectPfpModal from './select-pfp-modal';
import SelectPrimaryBadgeModal from './select-primary-badge-modal';

export enum MODAL_TYPE {
  ConnectWallet,
  EditProfile,
  SelectInterest,
  SelectPfp,
  SelectPrimaryBadge,
  BadgeDetail,
  HideBadge,
  BadgeVersion,
  AdvancedCriteria,
  ImageCrop,
  ApprovedTx,
  Contact,
  JoinWaitlist,
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'var(--G800)',
  boxShadow: 24,
  borderRadius: 32,
  padding: 40,
  outline: 'none',
  maxHeight: 'calc(100vh - 80px)',
  minHeight: 420,
};

const Modal: React.FC<{
  modalType: MODAL_TYPE;
  params: unknown;
  isOpen: boolean;
  handleClose: any;
  handleSubmit: any;
}> = ({ isOpen, params, modalType, handleClose, handleSubmit }) => {
  const renderStyle = useCallback((modalType: MODAL_TYPE) => {
    switch (modalType) {
      case MODAL_TYPE.Contact:
      case MODAL_TYPE.JoinWaitlist:
      case MODAL_TYPE.AdvancedCriteria:
      case MODAL_TYPE.ApprovedTx: {
        return {
          ...style,
          bgcolor: 'var(--G900)',
          minHeight: 0,
        };
      }
      case MODAL_TYPE.EditProfile:
      case MODAL_TYPE.SelectInterest:
      case MODAL_TYPE.SelectPfp:
      case MODAL_TYPE.SelectPrimaryBadge:
      case MODAL_TYPE.BadgeDetail:
      case MODAL_TYPE.HideBadge:
      case MODAL_TYPE.BadgeVersion:
        return style;
    }
  }, []);

  const renderBody = useCallback(
    (modalType: MODAL_TYPE) => {
      switch (modalType) {
        case MODAL_TYPE.EditProfile: {
          return (
            <EditProfileModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.SelectInterest: {
          return (
            <SelectInterestModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.SelectPfp: {
          const _param = params as {
            isInit: boolean;
          };
          return (
            <SelectPfpModal
              isInit={_param.isInit}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.SelectPrimaryBadge: {
          return (
            <SelectPrimaryBadgeModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.BadgeDetail: {
          const _param = params as {
            isMine: boolean;
            address: string;
            tokenId: string;
          };
          return (
            <BadgeDetailModal
              tokenId={_param.tokenId}
              isMine={_param.isMine}
              address={_param.address}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.HideBadge: {
          return (
            <HideBadgeModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.BadgeVersion: {
          const _param = params as {
            badgeId: string;
          };
          return (
            <BadgeVersionModal
              badgeId={_param.badgeId}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.AdvancedCriteria: {
          const _param = params as {
            index: number;
          };
          return (
            <AdvancedCriteriaModal
              index={_param.index}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.ApprovedTx: {
          const _param = params as {
            badge: Badge;
          };
          return (
            <ApprovedTxModal
              badge={_param.badge}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.Contact: {
          return (
            <ContactModal
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.ImageCrop: {
          const _param = params as {
            file: ImageFile | null;
            setFile: (value: ImageFile | null) => void;
            cropBoxWidthRatio: number;
            cropBoxHeightRatio: number;
          };
          return (
            <ImageCropModal
              file={_param.file}
              setFile={_param.setFile}
              cropBoxWidthRatio={_param.cropBoxWidthRatio}
              cropBoxHeightRatio={_param.cropBoxHeightRatio}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
        case MODAL_TYPE.JoinWaitlist: {
          const _param = params as {
            waitlistQuestions: any[];
          };
          return (
            <JoinWaitlistModal
              waitlistQuestions={_param.waitlistQuestions}
              handleClose={handleClose}
              handleSubmit={handleSubmit}
            />
          );
        }
      }
    },
    [params, handleClose, handleSubmit],
  );
  return (
    <>
      <MUIModal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="no-scroll" sx={renderStyle(modalType) as any}>
          {renderBody(modalType)}
        </Box>
      </MUIModal>
      <style jsx>{`
        div {
        }
      `}</style>
    </>
  );
};

export default Modal;
