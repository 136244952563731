import { useRouter } from 'next/router';
import React, { CSSProperties } from 'react';
import { ROUTE } from 'src/apis/const';
import { Badge } from 'src/entity/badge';
import useDisplayName from 'src/hooks/use-display-name';
import CertifiedIcon from './certified-icon';

const BadgeTitleSection: React.FC<{
  badge: Badge;
  titleFontSize?: string;
  style?: CSSProperties;
}> = ({ badge, titleFontSize = 'h4-bold', style = {} }) => {
  const router = useRouter();
  const creator = badge.proposer || badge.publisher;
  const [publisherName] = useDisplayName(creator.nickname, creator.address);

  return (
    <div className="wrap" style={style}>
      {badge.collections.length > 0 && (
        <p className="collectionName P200 h7-regular pre-wrap">
          <span
            onClick={() => {
              // router.push(
              //   `${ROUTE.COLLECTION}/${badge.collections[0].slugName}`,
              // );
            }}
          >
            {badge.collections[0].name}
          </span>
        </p>
      )}
      <p className={`badgeTitle ${titleFontSize} pre-wrap`}>{badge.name}</p>
      <div className="publisher">
        <span className="address G400 b2-regular">
          Created by{' '}
          <span
            className="publisher-name b2-bold G100"
            onClick={() => {
              router.push(`${ROUTE.PROFILE}/${creator.address}`);
            }}
          >
            {publisherName}
          </span>
        </span>
        {creator.isCertified && <CertifiedIcon />}
      </div>
      <style jsx>{`
        .wrap {
          margin-bottom: 20px;
        }
        .collectionName {
          margin-bottom: 4px;
          display: flex;
          align-items: center;
        }

        .badgeTitle {
          margin-bottom: 4px;
        }

        .publisher {
          display: flex;
          gap: 6px;
          margin-bottom: 4px;
        }
        .publisher-name:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};
export default BadgeTitleSection;
