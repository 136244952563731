import React, { useContext } from 'react';
import { defaultInputError, InputError } from 'src/components/common/input-v2';
import {
  CreateBadgeStateContext,
  CreateBadgeStateValidation,
  ImageFile,
  PageType,
} from 'src/hooks/use-create-badge-state/index';
import BadgeImageUploader from './badge-image-uploader';

const BadgeImageSection: React.FC = () => {
  const [state, setState, error, setError, , , pageType] = useContext(
    CreateBadgeStateContext,
  );
  const isViewPage = pageType === PageType.VIEW;
  return (
    <>
      <div>
        <div className="flex">
          <div className="input">
            <BadgeImageUploader
              title="Badge Thumbnail"
              desc={
                <>
                  PNG or JPEG format (Max size: 5MB) <br />
                  400x400 size is recommended
                </>
              }
              file={state.badgeThumbnail || null}
              setFile={(value: ImageFile | null) => {
                setState((_state) => ({
                  ..._state,
                  badgeThumbnail: value,
                }));
              }}
              error={error.badgeThumbnail as InputError}
              setError={(val: string) => {
                setError((state) => ({
                  ...state,
                  badgeThumbnail: val
                    ? (CreateBadgeStateValidation.badgeThumbnail as any)[val]
                    : defaultInputError,
                }));
              }}
              isRequired={true}
              tooltip={
                'Badge thumbnail represents the achievement. Please make sure to select a thumbnail image that best represents the achievements (e.g. project logo, illustration of the achievement)'
              }
              disabled={isViewPage}
            />
          </div>
          <div className="input">
            <BadgeImageUploader
              title="Background Image"
              desc={
                <>
                  PNG or JPEG format (Max size: 5MB) <br />
                  1100x1100 size is recommended
                </>
              }
              file={state.backgroundImage || null}
              setFile={(value: ImageFile | null) => {
                setState((_state) => ({
                  ..._state,
                  backgroundImage: value,
                }));
              }}
              isToggleView={true}
              disabled={isViewPage}
            />
          </div>

          <div className="input">
            <BadgeImageUploader
              title="Logo Image"
              desc={
                <>
                  PNG or JPEG format (Max size: 5MB) <br />
                  Transparent PNG, 480x128 size is recommended
                </>
              }
              file={state.projectLogo || null}
              setFile={(value: ImageFile | null) => {
                setState((_state) => ({
                  ..._state,
                  projectLogo: value,
                }));
              }}
              isToggleView={true}
              disabled={isViewPage}
              size={'small'}
            />
          </div>
          {/* <div className="input">
            <ColorPicker />
          </div> */}
        </div>
      </div>
      <style jsx>
        {`
          .input {
          }
          .desc {
            margin-bottom: 20px;
          }
          .desc .underline {
            text-decoration: underline;
            cursor: pointer;
          }
          .desc .underline:hover {
            opacity: 0.7;
          }
          .flex {
            display: flex;
            flex-direction: column;
            gap: 24px;
          }
          .flex .input {
            max-width: 244px;
          }
        `}
      </style>
    </>
  );
};

export default BadgeImageSection;
