import { EmotionCache } from '@emotion/react';
import type { AppProps } from 'next/app';
import * as React from 'react';
import { ToastContainer } from 'src/components/common/toast';
import Tooltip from 'src/components/common/tooltip';
import Layout from 'src/components/layout/layout';
import { Provider } from 'src/modules/web3_modal';
import '../styles/global.css';
import '../styles/reset.css';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

declare global {
  interface Window {
    nooxProvider: any;
    uauth: any;
  }
}

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const MyApp: React.FC<MyAppProps> = (props: MyAppProps) => {
  const { Component, pageProps } = props;
  React.useEffect(() => {
    Provider.getConnectCachedProvider().then(() => {
      console.debug("Cached Provider: ", window.nooxProvider)
    })
  }, []);

  return (
    <Layout>
      <>
        <Component {...pageProps} />
        <Tooltip direction="top" />
        {ToastContainer}
      </>
    </Layout>
  );
};
export default MyApp;
