import { ABI } from 'src/hooks/use-create-badge-state/abi-state';
import { Badge } from '../entity/badge';
import { API } from './api';
import { API_CONST } from './const';

export interface AbiResponse {
  contractName: string;
  contractAddress: string;
  abi: Record<string, any>[];
}

export class AbisAPI extends API {
  constructor(_url?: string) {
    super(API_CONST.ROOT_URL, _url || API_CONST.ABIS_API_URL);
  }

  static PATH = {
    GET_ABI: (address: string): string => `${address}`,
  };

  async getABI(address: string): Promise<ABI> {
    const res = await this.get(`${AbisAPI.PATH.GET_ABI(address)}`);
    return new ABI(res.abi);
  }

  async createABI(
    address: string,
    abiJson: Record<string, any>[],
  ): Promise<Badge[]> {
    return await this.post(`${AbisAPI.PATH.GET_ABI(address)}`, {
      abi: abiJson,
    });
  }
}
