import Link from 'next/link';
import React from 'react';
import { DISCOURSE_URL, ROUTE } from 'src/apis/const';
import { BearerTokenSession, ClaimTxHashSession } from 'src/apis/local-storage';
import { User } from 'src/entity/user';
import useDisplayName from 'src/hooks/use-display-name';
import { Provider } from 'src/modules/web3_modal';
import { Pfp } from '..';
import useDropdownMenu from '../../hooks/use-dropdown';
import { PfpBgType } from './pfp';

interface Props {
  user: User;
  logOut: () => void;
}

const NavbarProfileTab: React.FC<Props> = ({ user, logOut }) => {
  const { buttonProps, itemProps, isOpen } = useDropdownMenu(2);
  const [displayName] = useDisplayName(user.nickname, user.address);

  return (
    <>
      <div className="wrap">
        <Link
          passHref
          href={`${ROUTE.PROFILE}/${user.address}`}
          as={`${ROUTE.PROFILE}/${user.address}`}
        >
          <div className="link">
            <Pfp
              pfp={user.primaryPfp}
              width={46}
              address={user.address}
              pfpBgType={PfpBgType.NONE}
            />
            <p className="b1-regular">{displayName}</p>
          </div>
        </Link>
        <button {...buttonProps}>
          <div className="dropdown-button"></div>
        </button>
        <div id="dropbox-menu" className={isOpen ? 'visible' : ''} role="menu">
          <a
            {...itemProps[0]}
            href="#"
            onClick={() => {
              window.open(DISCOURSE_URL, '_blank')?.focus();
            }}
            className="b1-medium"
          >
            Give feedback
          </a>
          <a
            {...itemProps[1]}
            onClick={() => {
              Provider.removeConnectCachedProvider();
              BearerTokenSession.remove();
              ClaimTxHashSession.remove();
              logOut();
            }}
            className="b1-medium"
          >
            Sign Out
          </a>
        </div>
      </div>

      <style jsx>
        {`
          div {
            display: flex;
            align-items: center;
          }
          div.wrap {
            position: relative;
          }
          div.link {
            cursor: pointer;
            margin-right: 28px;
            border-radius: 30px;
          }
          div.link:hover {
            background: var(--P500);
            color: var(--WT100);
          }
          p {
            margin-left: 16px;
            margin-right: 20px;
          }
          button {
            background: none;
            padding: 0;
            margin: 0;
          }
          div.dropdown-button {
            width: 45px;
            height: 45px;
            background: no-repeat left center url(/image/dropdown.png);
            background-size: 45px 45px;
          }
          div.dropdown-button:hover {
            width: 45px;
            height: 45px;
            background: no-repeat left center url(/image/dropdown-hover.png);
            background-size: 45px 45px;
          }
          #dropbox-menu {
            background: #1e1d2e;
            border: 1px solid var(--N700);
            padding: 18px;
            border-radius: 5px;
            box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 10%);
            opacity: 0;
            visibility: hidden;
            transition: transform 0.2s, opacity 0.2s, visibility 0s linear 0.2s;
            will-change: transform;
            position: absolute;
            top: 55px;
            right: 0;
            z-index: 2;
            display: flex;
            flex-direction: column;
            border: 1px solid var(--B400);
          }
          #dropbox-menu.visible {
            transform: translateY(-0.3rem);
            transition: transform 0.2s, opacity 0.2s, visibility linear;
            visibility: visible;
            opacity: 1;
          }
          #dropbox-menu > a {
            display: inline-block;
            color: var(--WT100);
            width: 118px;
            height: 45px;
            text-decoration: none;
            cursor: pointer;
            outline: 0;
            text-align: left;
            padding-left: 60px;
            line-height: 45px;
          }
          #dropbox-menu > a:hover {
            color: var(--P500);
          }
          #dropbox-menu > a:nth-of-type(1) {
            background: no-repeat left center url(/image/feedback.png);
            background-size: 46px 46px;
            margin-bottom: 20px;
          }
          #dropbox-menu > a:nth-of-type(1):hover {
            background: no-repeat left center url(/image/feedback-hover.png);
            background-size: 46px 46px;
          }
          #dropbox-menu > a:nth-of-type(2) {
            background: no-repeat left center url(/image/signout.png);
            background-size: 46px 46px;
          }
          #dropbox-menu > a:nth-of-type(2):hover {
            background: no-repeat left center url(/image/signout-hover.png);
            background-size: 46px 46px;
          }
        `}
      </style>
    </>
  );
};

export default NavbarProfileTab;
