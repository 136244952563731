import React from 'react';
import Button, { ButtonSize, ButtonStyle } from '../button';
import ModalHeader from '../modal/modal-header';

const TxErrorModal: React.FC<{
  errorMessage: string;
  handleClose: () => void;
  handleSubmit: any;
}> = ({ errorMessage, handleClose }) => {
  return (
    <>
      <div className="container">
        <ModalHeader title={`Transaction failed`} handleCancel={handleClose} />
        <div className="body">
          <p className="b1-regular">Sorry, your transaction has failed.</p>
          <div className="code b2-regular WT80 scroll">{errorMessage}</div>
          <div className="button-wrap">
            <Button
              label="Confirmed"
              buttonStyle={ButtonStyle.PRIMARY}
              buttonSize={ButtonSize.L}
              handleClick={handleClose}
              style={{
                margin: 0,
              }}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
        }
        p {
          margin-bottom: 24px;
        }
        .code {
          width: 488px;
          background: var(--BT15);
          border-radius: 16px;
          border: 1px solid var(--WT30);
          padding: 16px;
          margin-bottom: 30px;
          overflow: auto;
          max-height: 150px;
        }
        .button-wrap {
          display: flex;
          gap: 0px;
          justify-content: center;
        }
      `}</style>
    </>
  );
};

export default TxErrorModal;
