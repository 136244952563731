import { CSSProperties } from 'react';

export enum ChipStyle {
  GRADIENT,
  BLACK,
  WHITE,
  DASH_LITE,
  DASH_GRAD,
}

export enum ChipSize {
  L,
  S,
}

const colorStyles = {
  [ChipStyle.GRADIENT]: {
    normal: `background: var(--GR-PP);color: var(--WT100);`,
    hover: `background: var(--GR-PP);color: var(--WT100);`,
    active: `background: var(--GR-PP);color: var(--WT100);`,
  },
  [ChipStyle.BLACK]: {
    normal: `background: rgba(10, 14, 46, 0.3);color: var(--P200);`,
    hover: `background: var(--P300);color: var(--WT100);`,
    active: `background: var(--P500);color: var(--WT100);`,
  },
  [ChipStyle.WHITE]: {
    normal: `background: var(--WT15);color: var(--WT100);`,
    hover: `background: rgba(255, 255, 255, 0.08);color: rgba(255, 255, 255, 0.7);`,
    active: `background: var(--WT15);color: var(--WT100);`,
  },
  [ChipStyle.DASH_LITE]: {
    normal: `background: none;color: var(--P100);border: 1px dashed var(--P100);`,
    hover: `background: none;color: var(--P300);border: 1px dashed var(--P300);`,
    active: `background: none;color: var(--P500);border: 1px dashed var(--P500);`,
  },
  [ChipStyle.DASH_GRAD]: {
    normal: `background: var(--GR-PP);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px dashed var(--P500);
    `,
    hover: `background: var(--GR-PP);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px dashed var(--P500);
    opacity: 0.7`,
    active: `background: var(--P500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; border: 1px dashed var(--P500);`,
  },
};

const paddingStyles = {
  [ChipSize.L]: {
    padding: '7.5px 16px',
  },
  [ChipSize.S]: {
    padding: '3px 16px',
  },
};

const fontStyles = {
  [ChipSize.L]: 'b2-bold',
  [ChipSize.S]: 'cap1-bold',
};

interface Props {
  label: string;
  chipStyle: ChipStyle;
  chipSize: ChipSize;
  icon?: string;
  style?: CSSProperties;
  handleClick?: () => void;
}

const Chip: React.FC<Props> = (props) => {
  const {
    chipStyle,
    chipSize,
    label,
    handleClick = null,
    icon = null,
    style = {},
  } = props;

  return (
    <>
      <div
        style={{
          ...style,
          cursor: handleClick ? 'pointer' : 'default',
        }}
        className={chipStyle === ChipStyle.GRADIENT ? 'gradient' : ''}
        onClick={handleClick || (() => {})}
      >
        <label
          style={{
            cursor: handleClick ? 'pointer' : 'default',
          }}
          className={fontStyles[chipSize]}
        >
          {icon && <span>{icon}</span>}
          <span>{label}</span>
        </label>
      </div>

      <style jsx>{`
        div {
          display: inline-block;
          border-radius: 30px;
          cursor: pointer;
          padding: ${paddingStyles[chipSize].padding};
          ${colorStyles[chipStyle].normal}
        }
        div:hover {
          ${handleClick && colorStyles[chipStyle].hover}
        }

        div.active {
          ${colorStyles[chipStyle].active}
        }

        label {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      `}</style>
    </>
  );
};

export default Chip;
