import React, { useCallback, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { isAvailableText } from 'src/utils';

export interface InputError {
  validate: (val: string) => boolean;
  msg: string;
}

export const defaultInputError: InputError = {
  validate: () => false,
  msg: '',
};

const InputV2: React.FC<{
  name?: string;
  value: string;
  desc?: any;
  setValue?: (value: string) => void;
  error?: InputError;
  setError?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  isRequired?: boolean;
  isTextarea?: boolean;
  errorArr?: Record<string, InputError>;
  style?: React.CSSProperties;
  additionalRegex?: RegExp;
  disableErrorStyle?: boolean;
  tooltip?: string;
  maxLength?: number;
}> = ({
  name = '',
  placeholder,
  value,
  setValue = () => {
    //
  },
  error = defaultInputError,
  setError = () => {},
  desc = '',
  additionalRegex = '',
  isRequired = false,
  disabled = false,
  isTextarea = false,
  errorArr = {},
  style = {},
  disableErrorStyle = false,
  tooltip = '',
  maxLength = Number.MAX_SAFE_INTEGER,
}) => {
  const ref = useRef<any>(null);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [_error, _setError] = React.useState<string | boolean>(error.msg);
  const handleSetValue = useCallback(
    (e) => {
      setError('');
      _setError(false);
      const _value = e.target.value;

      if (_value.length >= maxLength) {
        return;
      }

      if (!isAvailableText(_value)) {
        return;
      }

      if (additionalRegex && !new RegExp(additionalRegex).test(_value)) {
        return;
      }

      for (const [errorKey, errValue] of Object.entries(errorArr)) {
        if (!errValue.validate(_value)) {
          setError(errorKey);
          _setError(errValue.msg);
          break;
        }
      }

      setValue(e.target.value);
    },
    [errorArr, setValue, setError],
  );

  useEffect(() => {
    _setError(error.msg);
  }, [error.msg]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      {name && (
        <div className={`name b1-medium ${isFocused && 'focus'}`}>
          <span
            style={{
              marginRight: 1,
            }}
          >
            {name}
          </span>
          {isRequired && <span className="LG b1-medium">*</span>}
          {tooltip && <div data-tip={tooltip} className="tooltip"></div>}
        </div>
      )}

      <div
        className={`${!disableErrorStyle && _error ? 'error' : ''} ${
          isFocused && 'focus'
        }`}
      >
        {isTextarea ? (
          <>
            <div className="textarea">
              <textarea
                disabled={disabled}
                value={value}
                onChange={handleSetValue}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                placeholder={placeholder}
                ref={(el) => (ref.current = el)}
                className={'no-scroll b1-regular'}
                spellCheck="false"
                style={style}
              />
            </div>
          </>
        ) : (
          <>
            <input
              disabled={disabled}
              value={value}
              onChange={handleSetValue}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              placeholder={placeholder}
              ref={(el) => (ref.current = el)}
              className="b1-regular"
              spellCheck="false"
              style={style}
            />
          </>
        )}
      </div>
      {desc && (
        <p
          className={`cap1-light ${
            _error && typeof _error === 'string' ? 'error' : 'G300'
          } `}
        >
          {desc}
        </p>
      )}
      {/* {_error && typeof _error === 'string' && (
        <p className="cap1-regular error">{_error}</p>
      )} */}
      <style jsx>{`
        .name {
          margin-bottom: 4px;
          display: flex;
          align-items: center;
        }

        input,
        textarea {
          resize: none;
          background: var(--G800);
          border: none;
          border-radius: 8px;
          height: 24px;
          width: calc(100% - 26px);
          color: var(--WT100);
          padding: 12px 12px 12px 14px;
          cursor: text;
          margin-bottom: ${desc ? '4px' : '0px'};
        }
        textarea {
          height: 72px;
          margin-bottom: -4px;
        }

        input:hover:not(:disabled),
        input:focus {
          box-shadow: 0px 0px 0px 2px var(--P400) inset;
          height: 24px;
          width: calc(100% - 26px);
        }

        input:focus {
          outline: none;
        }

        .focus textarea {
          outline: none;
          box-shadow: 0px 0px 0px 2px var(--P400) inset;

          width: calc(100% - 26px);
        }

        div.error input,
        div.error textarea {
          box-shadow: 0px 0px 0px 2px var(--R) inset;
        }
        p.error {
          color: var(--R);
        }

        .tooltip {
          display: inline-block;
          width: 20.5px;
          height: 23.5px;
          margin-right: 5.8px;
          background: url(/image/information-icon.png) left 3px no-repeat;
          background-size: 18.5px 18.5px;
          margin-top: -3px;
          margin-left: 1px;
        }
      `}</style>
    </>
  );
};

export default React.memo(InputV2);
