import Image from 'next/image';
import React, { CSSProperties, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import { Badge, Chain } from 'src/entity/badge';
import { getDateString } from 'src/utils';
import BadgeLogo from '../../../../public/image/badge-logo.png';
import BadgeThumbnail from './badge-thumbnail';

interface Props {
  badge: Badge;
  isSelected?: boolean;
  handleSelected?: () => void;
  handleClick?: () => void;
  style?: CSSProperties;
}

const BadgeComponent: React.FC<Props> = ({
  badge,
  style = {},
  // select mode
  isSelected = false,
  handleSelected,
  // click mode
  handleClick,
}) => {
  const { id, isClaimed, claimedDate, _isPrimaryBadge } = badge;

  const handleOnClick = useCallback(() => {
    if (handleClick) {
      handleClick();
    } else if (handleSelected) {
      handleSelected();
    }
  }, [handleClick, handleSelected]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      {_isPrimaryBadge && <span className="badge-primary-check"></span>}
      <div
        style={{ ...style }}
        className={`border ${isClaimed ? 'claimed' : 'unclaimable'} ${
          _isPrimaryBadge ? 'primary' : ''
        } ${handleClick || handleSelected ? 'hover' : ''}`}
        onClick={handleOnClick}
      >
        <div className={`wrap ${isSelected ? 'selected' : ''}`} data-id={id}>
          <div className="part1">
            <div className="container">
              <div className="header">
                {isClaimed && claimedDate ? (
                  <div className="claimed cap1-regular P200">
                    {getDateString(claimedDate)}
                  </div>
                ) : (
                  <div className="unclaimable"></div>
                )}
                <div className="logo">
                  <span className="logo">
                    <Image
                      src={BadgeLogo}
                      alt={'badge_logo'}
                      width="60px"
                      height="15.42px"
                    />
                  </span>
                </div>
              </div>
              <BadgeThumbnail
                size="lg"
                thumbnailUrl={badge.imageThumbnailUrl}
                isClaimed={isClaimed || false}
                category={badge.categories[0]}
              />
              <div className="description">
                {/* {badge.serieses.length > 0 && (
                  <div className="collections yellow cap1-medium">
                    <span className={'line'}></span>
                    <span className={'collections-text ellipsis'}>
                      {badge.serieses[0].name}
                    </span>
                    <span className={'line'}></span>
                  </div>
                )} */}
                <p className="name h6-bold WT100 pre-wrap line-3">
                  {badge.name}
                </p>
                {badge._tierLabel && (
                  <div className="chip b2-bold P200">{badge._tierLabel}</div>
                )}
                <p className="description WT60 pre-wrap line-3">
                  {badge.descriptionEligibility}
                </p>
                <div className={`chain`}>
                  <div
                    className={`${badge.chain}`}
                    data-tip="Deployed network"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .badge-primary-check {
          display: block;
          width: 62px;
          height: 48px;
          background: url(/image/primary-badge-check.png) center center
            no-repeat;
          background-size: 62px 62px;
          margin: 0 auto;
          margin-bottom: -26px;
          position: relative;
          z-index: 2;
        }
        div.border {
          width: 264px;
          border-radius: 24px;
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: ${handleClick || handleSelected ? 'pointer' : 'default'};
        }
        div.border::before {
          content: '';
          position: absolute;
          background: url(/image/badge-border.png) center center no-repeat;
          background-size: 264px 445px;
          width: 264px;
          height: 445px;
          z-index: 1;
        }
        div.unclaimable.border::before {
          opacity: 0.3;
        }
        div.claimed.border::before {
          opacity: 0.7;
        }
        div.wrap {
          width: 258px;
          height: 423px;
          background: var(--P800);
          border-radius: 24px;
          position: relative;
          background: rgba(68, 66, 98, 1);
          padding: 10px;
        }
        div.wrap.selected {
          border: 2.5px solid var(--S100);
        }

        div.header {
          padding: 3px 4px 5px;
          display: flex;
          justify-content: space-between;
        }
        div.header > .logo {
          width: 84px;
          height: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        div.header > .logo > .logo {
          width: 52px;
          height: 13.36px;
        }

        div.header > div.unclaimable {
          width: 20px;
          background: url(/image/crown-unclaimable.png) left center no-repeat;
          background-size: 20px 20px;
          box-shadow: none;
        }
        div.header > div.claimed {
          background: url(/image/crown-claimed.png) left center no-repeat;
          background-size: 20px 20px;
          padding-left: 25px;
          color: var(--Y);
          box-shadow: none;
        }
        div.description {
          max-width: 246px;
          padding: 12px 14px 10px 14px;
          background: var(--BT10);
          border-radius: 16px;
          font-size: 1.5rem;
          line-height: 2.2rem;
          text-align: center;
          min-height: 203px;
          position: relative;
        }

        div.chain {
          width: 216px;
          height: 20px;
          position: absolute;
          bottom: 10px;
          display: flex;
          justify-content: center;
        }
        div.chain div {
          width: 20px;
          height: 20px;
          z-index: 1;
        }
        div.chain div.${Chain.Ethereum} {
          background: url(/image/ethereum-icon-white.png) center center
            no-repeat;
          background-size: 20px 20px;
        }
        div.chain div.${Chain.Polygon} {
          background: url(/image/polygon-icon-white.png) center center no-repeat;
          background-size: 20px 20px;
        }
        div.chain div.${Chain.Optimism} {
          background: url(/image/optimism-icon-white.png) center center no-repeat;
          background-size: 20px 20px;
        }

        div.collections {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 6px;
        }
        div.collections > span.collections-text {
          padding: 0px 8px;
          max-width: 216px;
        }
        div.collections > span.line {
          min-width: 7px;
          border-bottom: 1px solid var(--Y);
          flex: auto;
        }

        .chip {
          padding: 3px 12px;
          background: var(--BT30);
          width: auto;
          display: inline-block;
          border-radius: 30px;
          margin-bottom: 10px;
        }

        p.name {
          margin-bottom: 10px;
          line-height: 2.7rem !important;
        }

        .border.unclaimable.hover:hover::before {
          opacity: 0.5;
        }
        .unclaimable {
          box-shadow: 0px 12px 20px 8px rgba(0, 0, 0, 0.15);
        }
        .hover.unclaimable:hover {
          box-shadow: 0px 12px 28px 8px rgba(0, 0, 0, 0.4);
        }
        .border.claimed.hover:hover::before {
          opacity: 0.7;
        }
        .claimed {
          box-shadow: 0px 4px 20px 6px rgba(255, 124, 242, 0.3),
            0px 12px 20px 8px rgba(0, 0, 0, 0.15);
        }
        .hover.claimed:hover {
          box-shadow: 0px 4px 28px 6px rgba(255, 124, 242, 0.5),
            0px 12px 20px 8px rgba(0, 0, 0, 0.15);
        }
        .claimed div.wrap {
          background: var(--P800);
          background: linear-gradient(
              157.15deg,
              rgba(91, 62, 180, 0.7) 0%,
              rgba(119, 50, 152, 0.7) 100%
            ),
            rgba(68, 66, 98, 1);
        }
        .hover.claimed:hover div.wrap {
          background: linear-gradient(
              157.15deg,
              rgba(91, 62, 180, 0.7) 0%,
              rgba(119, 50, 152, 0.7) 100%
            ),
            rgba(13, 13, 20, 1);
        }
        .claimed.primary::before {
        }
        .claimed.primary {
          box-shadow: 0px 4px 28px 6px rgba(255, 124, 242, 0.6),
            0px 12px 20px 8px rgba(0, 0, 0, 0.15);
        }
        .claimed.primary div.wrap {
          background: linear-gradient(
              157.15deg,
              rgba(78, 75, 208, 1) 0%,
              rgba(152, 50, 142, 1) 100%
            ),
            rgba(13, 13, 20, 1);
        }
        .hover.primary.claimed:hover div.wrap {
          background: linear-gradient(
              157.15deg,
              rgba(78, 75, 208, 0.7) 0%,
              rgba(152, 50, 142, 0.7) 100%
            ),
            #0d0d14;
        }
        .claimed div.wrap div.thumbnail > div.clip {
        }
      `}</style>
    </>
  );
};

export default React.memo(BadgeComponent);
