import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { ClaimSigAPI } from 'src/apis/claim-sig-api';
import { EligibilitiesAPI } from 'src/apis/eligibilities-api';
import { ClaimTxHashSession } from 'src/apis/local-storage';
import { UsersAPI } from 'src/apis/users-api';
import { Button } from 'src/components';
import { ALERT_MODAL_TYPE } from 'src/components/common/alert-modal/alert-modal';
import { ButtonSize, ButtonStyle } from 'src/components/common/button';
import { MODAL_TYPE } from 'src/components/common/modal/modal';
import { TxSucceedToast } from 'src/components/common/toast';
import supportedChains from 'src/const/chains';
import { Badge, BadgeDetailed, Chain } from 'src/entity/badge';
import { User } from 'src/entity/user';
import { nooxContract } from 'src/modules/noox-contract';
import useAlertModal from './use-alert-modal';
import useConnectWallet from './use-connect-wallet';
import useModal from './use-modal';

enum ClaimStatusType {
  PRE_LOADING,
  CHECK,
  CHECK_LOADING,
  CLAIM,
  CLAIM_LOADING,
  CLAIMED,
  MIGRATE,
  UPGRADE,
}

const useInitClaimStatus = (
  profile: User | undefined,
  initCb: any,
  claimedCb: any,
  checkCb: any,
  claimCb: any,
  migrateCb: any,
  upgradeCb: any,
): [(badge?: Badge) => void] => {
  const router = useRouter();

  const pollDB = useCallback(
    (tokenId: string) => {
      const cb = () => {
        new UsersAPI()
          .getBadge(tokenId)
          .then((res) => {
            if (res.isClaimed) {
              TxSucceedToast(profile?.address || '', router);
              claimedCb();
              clearInterval(id);
              ClaimTxHashSession.remove();
            }
          })
          .catch((error) => {
            console.error(error.message);
            clearInterval(id);
          });
      };
      const id = setInterval(cb, 2500);
    },
    [profile, router, claimedCb],
  );

  const checkClaimStatus = useCallback(
    async (curBadge?: Badge) => {
      initCb();

      if (curBadge?.eligibilityCheck?.result) {
        claimCb();
        return;
      }

      const { txHash, tokenId } = JSON.parse(ClaimTxHashSession.get() || `{}`);
      if (curBadge?.id && tokenId !== curBadge?.id) {
        checkCb();
        return;
      }

      if (txHash) {
        const receipt = await window.nooxProvider.waitForTransaction(txHash);
        if (receipt.status === 1) {
          pollDB(tokenId);
        } else {
          checkCb();
          ClaimTxHashSession.remove();
        }
      } else {
        checkCb();
      }
    },
    [initCb, checkCb, pollDB, claimCb],
  );

  return [checkClaimStatus];
};

const useClaimBadge = (
  badge: BadgeDetailed,
  profile: User | undefined,
  highestClaimedIndex: number,
): [any, () => JSX.Element, () => JSX.Element] => {
  const [renderConnectWalletModals, handleConnectWalletOpen] =
    useConnectWallet();

  const [claimStatusType, setClaimStatusType] = useState<ClaimStatusType>(
    ClaimStatusType.PRE_LOADING,
  );
  const [renderNotEligible, handleNotEligibleModalOpen] = useAlertModal(
    ALERT_MODAL_TYPE.NotEligible,
    async () => {},
  );
  const [renderCheckEligibility, handleCheckEligibilityModalOpen] =
    useAlertModal(ALERT_MODAL_TYPE.CheckEligibility, async () => {
      try {
        if (profile) {
          await new EligibilitiesAPI().updateEligibility(badge.id);
          setClaimStatusType(ClaimStatusType.CHECK_LOADING);
          pollEligibility();
        } else {
          setClaimStatusType(ClaimStatusType.CHECK);
          handleConnectWalletOpen(null);
        }
      } catch (e) {
        console.error(e);
      }
    });

  const setClaimStatusClaimOrUpgrade = useCallback(() => {
    if (highestClaimedIndex >= 0 && badge.group.index > highestClaimedIndex) {
      setClaimStatusType(ClaimStatusType.UPGRADE);
    } else {
      setClaimStatusType(ClaimStatusType.CLAIM);
    }
  }, [badge?.group?.index, highestClaimedIndex]);

  const [checkClaimStatus] = useInitClaimStatus(
    profile,
    () => {
      setClaimStatusType(ClaimStatusType.CLAIM_LOADING);
    },
    () => {
      setClaimStatusType(ClaimStatusType.CLAIMED);
    },
    () => {
      setClaimStatusType(ClaimStatusType.CHECK);
    },
    () => {
      setClaimStatusClaimOrUpgrade();
    },
    () => {
      setClaimStatusType(ClaimStatusType.MIGRATE);
    },
    () => {
      setClaimStatusType(ClaimStatusType.UPGRADE);
    },
  );

  const [renderTxSucceed, handleTxSuccessModalOpen] = useAlertModal(
    ALERT_MODAL_TYPE.TxSucceed,
    () => {
      window
        .open(
          `${supportedChains[badge.chain].etherscan_url}/tx/${
            JSON.parse(ClaimTxHashSession.get() || `{}`).txHash
          }`,
          '_blank',
        )!
        .focus();
    },
  );

  const [renderTxFeeError, handleTxFeeErrorModalOpen] = useAlertModal(
    ALERT_MODAL_TYPE.TxFeeError,
    () => {},
  );

  const [renderTxError, handleTxErrorModalOpen] = useAlertModal(
    ALERT_MODAL_TYPE.TxError,
    () => {},
  );

  const [renderUpgrade, handleUpgradeModalOpen] = useAlertModal(
    ALERT_MODAL_TYPE.Upgrade,
    () => {
      setClaimStatusType(ClaimStatusType.CLAIMED);
    },
  );

  const [renderApprovedTx, handleApprovedTxModal] = useModal(
    MODAL_TYPE.ApprovedTx,
    async () => {},
  );

  const [renderMigrate, handleMigrateModalOpen] = useAlertModal(
    ALERT_MODAL_TYPE.Migrate,
    () => {
      setClaimStatusType(ClaimStatusType.CLAIMED);
    },
  );
  const { data: isSupportingDetail } =
    new EligibilitiesAPI().useGetIsSupportingDetail(badge.id, {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  console.log(isSupportingDetail);

  const pollEligibility = useCallback(() => {
    let seconds = 0;
    const cb = async () => {
      if (seconds > 1 && seconds % 60 === 0) {
        await new EligibilitiesAPI().updateEligibility(badge.id);
      }

      await new EligibilitiesAPI()
        .getEligibility(badge.id)
        .then((res) => {
          if (res.state === 1) {
            setClaimStatusClaimOrUpgrade();
            clearInterval(id);
          }
          if (res.state === 0) {
            throw new Error();
          }
        })
        .catch((e) => {
          console.debug(e);
          setClaimStatusType(ClaimStatusType.CHECK);
          handleNotEligibleModalOpen(null);
          clearInterval(id);
        });
      seconds += 1;
    };
    const id = setInterval(cb, 2000);
  }, [badge, handleNotEligibleModalOpen, setClaimStatusClaimOrUpgrade]);

  const claim = useCallback(async () => {
    try {
      setClaimStatusType(ClaimStatusType.CLAIM_LOADING);
      const { chainId } = await window.nooxProvider.getNetwork();
      const currentNetwork = supportedChains[badge.chain];
      if (chainId !== currentNetwork.chain_id) {
        alert(
          `You are currently on the wrong network. Please switch to ${currentNetwork.network} network.`,
        );
        setClaimStatusClaimOrUpgrade();
        return;
      }

      const res = await new ClaimSigAPI().claimSig({
        badgeIds: [badge.id],
      });
      const { badgeId, signature } = res.badges[0];
      const txHash = await nooxContract(
        window.nooxProvider.getSigner(),
        badge.chain,
      ).claim(badgeId, signature, badge.chain);
      ClaimTxHashSession.set(
        JSON.stringify({
          txHash,
          tokenId: badgeId,
        }),
      );
      handleTxSuccessModalOpen(null);
      await checkClaimStatus();
    } catch (e: any) {
      console.error(e);
      const isEthereumChain = badge.chain === Chain.Ethereum;
      const isFeeError = isEthereumChain
        ? e.message &&
          e.message.startsWith(
            'insufficient funds for intrinsic transaction cost',
          )
        : e.message && e.data.message.startsWith('err: insufficient funds');
      if (isFeeError) {
        handleTxFeeErrorModalOpen(null);
      } else {
        handleTxErrorModalOpen({
          errorMessage: isEthereumChain ? e.message : e.data?.message,
        });
      }
      setClaimStatusClaimOrUpgrade();
    }
  }, [
    badge,
    checkClaimStatus,
    handleTxErrorModalOpen,
    handleTxSuccessModalOpen,
    handleTxFeeErrorModalOpen,
    setClaimStatusClaimOrUpgrade,
  ]);

  const renderClaimButton = useCallback(() => {
    const style = {
      width: isSupportingDetail ? '314px' : '100%',
      margin: 0,
    };

    switch (claimStatusType) {
      case ClaimStatusType.CHECK: {
        return (
          <Button
            label="Check eligibility"
            buttonStyle={ButtonStyle.PRIMARY}
            buttonSize={ButtonSize.L}
            isDisabled={true}
            handleClick={() => {
              handleCheckEligibilityModalOpen({
                badge,
              });
            }}
            style={style}
          />
        );
      }
      case ClaimStatusType.PRE_LOADING:
      case ClaimStatusType.CHECK_LOADING:
      case ClaimStatusType.CLAIM_LOADING: {
        return (
          <Button
            label="loading"
            buttonStyle={ButtonStyle.PRIMARY}
            buttonSize={ButtonSize.L}
            handleClick={() => {}}
            loading={true}
            style={style}
          />
        );
      }
      case ClaimStatusType.CLAIM: {
        return (
          <Button
            label="Claim"
            buttonStyle={ButtonStyle.GRADIENT}
            buttonSize={ButtonSize.L}
            handleClick={claim}
            isDisabled={true}
            style={style}
          />
        );
      }
      case ClaimStatusType.CLAIMED: {
        return (
          <Button
            label="Claimed"
            buttonStyle={ButtonStyle.CUSTOM}
            buttonSize={ButtonSize.L}
            handleClick={() => {}}
            style={{
              background: 'var(--SG)',
              width: '100%',
              cursor: 'default',
            }}
          />
        );
      }
      case ClaimStatusType.MIGRATE: {
        return (
          <Button
            label="Migrate"
            buttonStyle={ButtonStyle.GRADIENT}
            buttonSize={ButtonSize.L}
            isDisabled={true}
            handleClick={() => {
              handleMigrateModalOpen({
                badge,
              });
            }}
            style={style}
            tooltip={`A new version of your badge has been released. You can start the version upgrade process by clicking “Migrate”. Once completed, your old badge will be burn and replaced with a new version of the badge.`}
          />
        );
      }
      case ClaimStatusType.UPGRADE: {
        return (
          <Button
            label="Upgrade"
            buttonStyle={ButtonStyle.GRADIENT}
            buttonSize={ButtonSize.L}
            isDisabled={true}
            handleClick={() => {
              handleUpgradeModalOpen({
                badge,
              });
            }}
            style={style}
            tooltip={`A higher level of badge in this series is now claimable. By clicking “Upgrade” button, you can upgrade your badge to a higher level badge. Once completed, the current level of your badge will be burned and replaced with a higher level badge.`}
          />
        );
      }
    }
  }, [claimStatusType, badge, isSupportingDetail]);

  const renderDetailButton = useCallback(() => {
    switch (claimStatusType) {
      case ClaimStatusType.CHECK:
      case ClaimStatusType.PRE_LOADING:
      case ClaimStatusType.CHECK_LOADING:
      case ClaimStatusType.CLAIM_LOADING:
      case ClaimStatusType.CLAIM:
      case ClaimStatusType.MIGRATE:
      case ClaimStatusType.UPGRADE: {
        if (isSupportingDetail) {
          return (
            <div>
              <Button
                isDisabled={true}
                label="My eligible TXs"
                buttonStyle={ButtonStyle.SECONDARY}
                buttonSize={ButtonSize.L}
                handleClick={async () => {
                  // if (profile) {
                  //   handleApprovedTxModal({
                  //     badge,
                  //   });
                  // } else {
                  //   handleConnectWalletOpen(null);
                  // }
                }}
                style={{
                  width: '166px',
                  padding: '16px',
                }}
                fontStyle={{
                  fontSize: '1.6rem',
                }}
              />
            </div>
          );
        } else {
          return <></>;
        }
      }
      case ClaimStatusType.CLAIMED: {
        return <></>;
      }
    }
  }, [
    claimStatusType,
    badge,
    handleApprovedTxModal,
    handleConnectWalletOpen,
    isSupportingDetail,
    profile,
  ]);

  // initial logic
  useEffect(() => {
    setClaimStatusType(ClaimStatusType.PRE_LOADING);
    if (profile) {
      initCheck();
    } else {
      setClaimStatusType(ClaimStatusType.CHECK);
    }
  }, [badge, profile]);

  const initCheck = useCallback(async () => {
    if (badge.claimedRevision) {
      if (badge.revision === badge.claimedRevision) {
        setClaimStatusType(ClaimStatusType.CLAIMED);
      } else {
        setClaimStatusType(ClaimStatusType.MIGRATE);
      }
    } else {
      await checkClaimStatus(badge);
    }
  }, [badge, profile]);

  const renderModal = useCallback(() => {
    return (
      <>
        {renderConnectWalletModals()}
        {renderApprovedTx}
        {renderCheckEligibility}
        {renderNotEligible}
        {renderTxSucceed}
        {renderTxFeeError}
        {renderTxError}
        {renderMigrate}
        {renderUpgrade}
      </>
    );
  }, [
    renderConnectWalletModals,
    renderApprovedTx,
    renderCheckEligibility,
    renderNotEligible,
    renderTxSucceed,
    renderTxFeeError,
    renderTxError,
    renderMigrate,
    renderUpgrade,
  ]);

  return [renderModal, renderClaimButton, renderDetailButton];
};

export default useClaimBadge;
export { useInitClaimStatus };
