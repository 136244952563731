import { ABI } from 'src/hooks/use-create-badge-state/abi-state';
import { IActionJSON } from 'src/hooks/use-create-badge-state/action';
import { KeyedMutator, SWRConfiguration, SWRResponse } from 'swr';
import { AbisAPI } from './abis-api';
import { API } from './api';
import { API_CONST } from './const';
import { _useSWR, _useSWRInfinite } from './swr';

export enum UserBadgeStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  ACCEPTED = 'ACCEPTED',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
  REQUEST_FOR_EDITS = 'REQUEST_FOR_EDITS',
}

export interface UserBadge extends UserBadgeDto {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: UserBadgeStatus;
  creator: {
    nickname: string;
    address: string;
    isCertified: boolean;
  };
  memo?: string;
}

export interface UserBadgeDto {
  title: string;
  description: string;
  eligibilityDescription: string;
  project: {
    name: string;
    categories: string[];
    url: string;
  };
  image: {
    thumbnailUrl: string;
    backgroundUrl: string;
    logoUrl: string;
  };
  // bgColor: string[];
  eligibilityRule: {
    actionUnits: IActionJSON[];
    required: string;
    // eligibleAddresses: string[]
  };
  discordHandle: string;
  email: string;
}

export interface GetUserBadgesResponse {
  userBadges: Pick<
    UserBadge,
    'id' | 'title' | 'status' | 'createdAt' | 'updatedAt' | 'creator' | 'memo'
  >[];
}

export class UserBadgesAPI extends API {
  constructor(_url?: string) {
    super(API_CONST.ROOT_URL, _url || API_CONST.USER_BADGES_API_URL);
  }

  static PATH = {
    USER_BADGES: (): string => ``,
    USER_BADGE: (userBadgeId: string): string => `${userBadgeId}`,
    SUBMIT_USER_BADGE: (userBadgeId: string): string => `${userBadgeId}/submit`,
    STORE_USER_BADGE_IMAGE: (): string => 'images',
  };

  static SWR_PATH = {
    GET_USER_BADGES: (): string => `${API_CONST.USER_BADGES_API_URL}`,
    GET_USER_BADGE: (userBadgeId: string): string =>
      `${API_CONST.USER_BADGES_API_URL}${UserBadgesAPI.PATH.USER_BADGE(
        userBadgeId,
      )}`,
  };

  async getUserBadges(query: string): Promise<{
    id: string;
  }> {
    const res = await this.get(`${UserBadgesAPI.PATH.USER_BADGES()}`, query);
    return res;
  }

  async getUserBadge(id: string): Promise<{
    userBadge: any;
    abi: any;
  }> {
    const res = await this.get(`${UserBadgesAPI.PATH.USER_BADGE(id)}`);
    const abi: any = {};
    for (const action of res.eligibilityRule.actionUnits) {
      abi[action.contract] = {
        abi: new ABI((await new AbisAPI().getABI(action.contract)).abi),
        isCustomABI: false,
      };
    }
    return {
      userBadge: res,
      abi,
    };
  }

  async createUserBadge(params: UserBadgeDto): Promise<string> {
    const res = await this.post(`${UserBadgesAPI.PATH.USER_BADGES()}`, params);
    return res;
  }

  async updateUserBadge(id: string, params: UserBadgeDto): Promise<string> {
    const res = await this.patch(
      `${UserBadgesAPI.PATH.USER_BADGE(id)}`,
      params,
    );
    return id;
  }

  async submitUserBadge(id: string): Promise<{
    id: string;
  }> {
    const res = await this.patch(
      `${UserBadgesAPI.PATH.SUBMIT_USER_BADGE(id)}`,
      {},
    );
    return res;
  }

  async storeUserBadgeImage(params: File): Promise<string> {
    try {
      const formData = new FormData();
      formData.append('image', params);

      const res = await this.post(
        `${UserBadgesAPI.PATH.STORE_USER_BADGE_IMAGE()}`,
        formData,
        {
          isFormData: true,
        },
      );
      return res;
    } catch (e: any) {
      if (e.message.startsWith('Exceed')) {
        throw new Error('Sorry, You can upload image file up to 5MB size.');
      }
      throw e;
    }
  }

  useGetUserBadges(
    options: SWRConfiguration = {},
  ): [
    GetUserBadgesResponse | null,
    (node: any) => void,
    boolean,
    KeyedMutator<any[]>,
  ] {
    return _useSWRInfinite<GetUserBadgesResponse, UserBadge>({
      key: UserBadgesAPI.SWR_PATH.GET_USER_BADGES(),
      size: 25,
      func: async (query: string) => {
        return await this.getUserBadges(query);
      },
      dataKey: 'userBadges',
      options,
    });
  }

  useGetUserBadge(
    id: string,
    options: SWRConfiguration = {},
  ): SWRResponse<
    {
      userBadge: UserBadge;
      abi: any;
    },
    Error
  > {
    return _useSWR<{
      userBadge: UserBadge;
      abi: any;
    }>(
      UserBadgesAPI.SWR_PATH.GET_USER_BADGE(id),
      async () => this.getUserBadge(id),
      options,
    );
  }
}
