import React from 'react';
import { Badge, getVisibleBadge } from 'src/entity/badge';
import { User } from 'src/entity/user';
import useModal from 'src/hooks/use-modal';
import { BadgeList, FeedbackSection } from '..';
import Loading from '../common/loading';
import { MODAL_TYPE } from '../common/modal/modal';
import BioSection from './bio-section';

const ProfileContainer: React.FC<{
  profile: User | undefined;
  badges: Badge[] | undefined;
  totalClaimed: number;
  isMine: boolean;
  lastElementRef?: (node: any) => void;
}> = ({ profile, badges: _badges, isMine, totalClaimed, lastElementRef }) => {
  const [renderModal, handleOpenBadgeDetailModal] = useModal(
    MODAL_TYPE.BadgeDetail,
    async () => {},
  );

  if (!profile || !_badges)
    return (
      <div>
        <Loading />
      </div>
    );

  const badges = getVisibleBadge(_badges)
    .map((badge) => {
      return profile.primaryBadge && profile.primaryBadge.id === badge.id
        ? {
            ...badge,
            _isPrimaryBadge: true,
          }
        : badge;
    })
    .sort((x, y) => {
      return x._isPrimaryBadge === true
        ? -1
        : y._isPrimaryBadge == true
        ? 1
        : 0;
    });
  const _link = window.location.href;
  const _tweetText = encodeURI(
    `Collected ${totalClaimed} badges on @noox_world\n\nCheck it out here: ${_link}`,
  );

  return (
    <div>
      {renderModal}
      <main className="container">
        <div className="blocks">
          <section className="profileBlock">
            <BioSection
              profile={{
                ...profile,
                link: _link,
                tweetText: _tweetText,
              }}
              isMine={isMine}
            />
          </section>
          <section className="feedbackBlock">
            <FeedbackSection />
          </section>
        </div>
        <section className="achievementsBlock">
          <BadgeList
            badges={badges}
            isMine={isMine}
            address={profile.address}
            title={'Achievements'}
            isProfilePage={true}
            handleBadgeClick={(tokenId) => {
              handleOpenBadgeDetailModal({
                isMine,
                address: profile.address,
                tokenId,
              });
            }}
            lastElementRef={lastElementRef}
          />
        </section>
      </main>
      <style jsx>
        {`
          main {
            width: 1200px;
            margin: 0 auto;
            height: auto;
          }
          .blocks {
            display: flex;
            margin-bottom: 32px;
            height: auto;
          }
          .blocks > section {
            padding: 40px;
          }
          .profileBlock {
            width: 793px;
            margin-right: 20px;
          }
          .feedbackBlock {
            width: 387px;
            display: flex;
            align-items: center;
          }
          section {
            background: var(--G800);
            border-radius: 44px;
            box-sizing: border-box;
          }
          .container {
            width: 1200px;
          }
        `}
      </style>
    </div>
  );
};

export default ProfileContainer;
