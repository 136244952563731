import React, { Dispatch, SetStateAction } from 'react';

const Toggle: React.FC<{
  flag: boolean;
  toggle: Dispatch<SetStateAction<boolean>>;
}> = ({ flag, toggle }) => {
  return (
    <>
      <div
        onClick={() => {
          toggle(!flag);
        }}
        className={`wrap ${flag ? 'on' : 'off'}`}
      >
        <div></div>
      </div>
      <style jsx>{`
        .wrap {
          width: 26px;
          border-radius: 20px;
          padding: 4px;
          background: var(--G700);
          display: flex;
        }
        .wrap.on {
          background: var(--P500);
          justify-content: flex-end;
        }
        .wrap div {
          width: 12px;
          height: 12px;
          border-radius: 30px;
          background: var(--G100);
        }
      `}</style>
    </>
  );
};
export default Toggle;
