import React, { useCallback, useRef } from 'react';
import Icon from './icon';

const Input: React.FC<{
  name: string;
  value: string;
  setValue?: (value: string) => void;
  placeholder?: string;
  style?: any;
  onClick?: () => void;
  disabled?: boolean;
  isTextarea?: boolean;
  maxLength?: number;
  isWarning?: boolean;
  errorArr?: {
    validate: (val: string) => boolean;
    msg: string;
  }[];
}> = ({
  name,
  placeholder,
  value,
  setValue = () => {
    //
  },
  onClick,
  style,
  disabled = false,
  isTextarea = false,
  maxLength,
  isWarning,
  errorArr = [],
}) => {
  const ref = useRef<any>(null);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | boolean>('');
  const handleSetValue = useCallback(
    (e) => {
      setError(false);
      const _value = e.target.value;

      for (const err of errorArr) {
        if (!err.validate(_value)) {
          setError(err.msg);
          break;
        }
      }

      setValue(e.target.value);
    },
    [errorArr, setValue],
  );

  return (
    <>
      <span
        className={`name cap1-regular ${isWarning ? 'warning' : ''} ${
          isFocused && 'focus'
        } ${error && 'error'}`}
      >
        {name}
      </span>
      <div
        onClick={onClick}
        className={`${error ? 'error' : ''} ${isWarning ? 'warning' : ''} ${
          isFocused && 'focus'
        }`}
      >
        {maxLength && (
          <span className={`cap1-regular length ${isFocused && 'focus'}`}>
            {value.length}/{maxLength}
          </span>
        )}
        {isTextarea ? (
          <>
            <div className="textarea">
              <textarea
                disabled={disabled}
                value={value}
                onChange={handleSetValue}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                style={style}
                placeholder={placeholder}
                ref={(el) => (ref.current = el)}
                className={'no-scroll'}
                spellCheck="false"
              />
            </div>
          </>
        ) : (
          <>
            <input
              disabled={disabled}
              value={value}
              onChange={handleSetValue}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              style={style}
              placeholder={placeholder}
              ref={(el) => (ref.current = el)}
              className="b1-regular"
              spellCheck="false"
            />
            {onClick && <span>{<Icon iconKey="chevronDown" />}</span>}
          </>
        )}
      </div>
      {error && typeof error === 'string' && (
        <p className="cap1-regular error">{error}</p>
      )}
      <style jsx>{`
        ::placeholder {
          //  color: var(--WT100);
        }

        span.name {
          position: absolute;
          left: 26px;
          top: 9px;
          color: var(--WT30);
        }
        span.length {
          position: absolute;
          top: 8px;
          right: 24px;
          color: var(--WT30);
        }

        input,
        .textarea {
          resize: none;
          background: none;
          border: 1px solid var(--WT30);
          border-radius: 30px;
          height: 36px;
          width: calc(100% - 50px);
          color: var(--WT100);
          padding-left: 26px;
          padding-top: 20px;
          cursor: ${onClick ? 'pointer' : 'text'};
          padding-right: 26px;
        }
        .textarea {
          height: auto;
        }

        input + span {
          width: 20px;
          position: absolute;
          top: 20px;
          right: 24px;
        }

        input:hover {
          background: var(--BT30);
        }

        span.focus {
          color: var(--P500);
        }

        input:focus,
        .focus .textarea {
          border: 1px solid var(--P500);
        }

        input:focus,
        .focus textarea {
          background: none;
          outline: none;
        }

        textarea {
          padding: 0;
          width: 100%;
          height: 64px;
          margin: 10px 0px;
          resize: none;
          background: none;
          border: none;
          width: calc(100% - 0px);
          color: var(--WT100);
        }
        span.error,
        div.error > span {
          color: var(--R) !important;
        }
        p.error {
          color: var(--R) !important;
          padding: 4px 0px 0px 28px;
        }

        span.warning,
        div.warning > span {
          color: var(--Y) !important;
        }
        div.warning > input,
        div.warning > .textarea {
          border: 1px solid var(--Y) !important;
        }

        div.error > input,
        div.error > .textarea {
          border: 1px solid var(--R) !important;
        }
      `}</style>
    </>
  );
};

export default Input;
