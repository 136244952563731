import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import Icon from 'src/components/common/icon';
import { defaultInputError, InputError } from 'src/components/common/input-v2';
import { MODAL_TYPE } from 'src/components/common/modal/modal';
import { errorToast } from 'src/components/common/toast';
import Toggle from 'src/components/common/Toggle';
import { ImageFile } from 'src/hooks/use-create-badge-state/index';
import useModal from 'src/hooks/use-modal';

const BadgeImageUploader: React.FC<{
  file: ImageFile | null;
  setFile: (value: ImageFile | null) => void;
  error?: InputError;
  setError?: (value: string) => void;
  title: string;
  desc: any;
  isRequired?: boolean;
  isToggleView?: boolean;
  tooltip?: string;
  disabled?: boolean;
  size?: 'big' | 'small';
}> = ({
  title,
  desc,
  file,
  setFile,
  isRequired = false,
  error = defaultInputError,
  isToggleView = false,
  tooltip = '',
  disabled = false,
  size = 'big',
}) => {
  const [show, toggleShow] = React.useState<boolean>(
    isToggleView ? (file ? true : false) : true,
  );
  const [_error, _setError] = React.useState<string | boolean>(error.msg);
  const [renderImageCrop, handleOpenImageCrop] = useModal(
    MODAL_TYPE.ImageCrop,
    async () => {},
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
      'image/png': ['.png'],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )[0];
      if (file.size / 1024 / 1024 > 5) {
        errorToast('File size cannot exceed 5MB.');
        return;
      }
      const isBigSize = size === "big"
      handleOpenImageCrop({
        file: file,
        setFile,
        cropBoxWidthRatio: 480,
        cropBoxHeightRatio: isBigSize ? 480 : 128
      });
    },
  });

  // useEffect(() => {
  //   return () => URL.revokeObjectURL(file?.preview || '');
  // }, []);

  useEffect(() => {
    _setError(error.msg);
  }, [error.msg]);

  const _toggleShow = useCallback(() => {
    if (show) {
      setFile(null);
    }
    toggleShow(!show);
  }, [setFile, show]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      {renderImageCrop}
      <div className="wrap">
        <div className="title b1-medium G25">
          {title}
          {isRequired && (
            <span className="LG b1-medium" style={{ paddingLeft: 1 }}>
              *
            </span>
          )}
          {tooltip && <div data-tip={tooltip} className="tooltip"></div>}
          {isToggleView && (
            <div className="toggle">
              <Toggle flag={show} toggle={!disabled ? _toggleShow : () => {}} />
            </div>
          )}
        </div>
        {show && (
          <>
            <div className={`desc cap2-light G300 ${_error && 'error'}`}>
              {desc}
            </div>
            <div {...(!disabled && getRootProps({ className: 'dropzone' }))}>
              <input {...getInputProps()} />
              <div>
                {file ? (
                  <div className={`image-thumb ${size}`}>
                    <div></div>
                  </div>
                ) : (
                  <div
                    className={`uploader ${size} ${_error && 'error'} ${
                      disabled && 'disabled'
                    }`}
                  ></div>
                )}
              </div>
            </div>
          </>
        )}

        {file && (
          <div className="filename cap2-medium G25">
            <span>{file.name}</span>
            {!disabled && (
              <span
                className="close"
                onClick={() => {
                  setFile(null);
                }}
              >
                <Icon iconKey="x" strokeWidth={2} />
              </span>
            )}
          </div>
        )}
      </div>
      <style jsx>
        {`
          .wrap {
          }
          .title,
          .desc {
            margin-bottom: 4px;
            position: relative;
          }
          .title {
            display: flex;
            align-items: center;
          }
          .desc.error {
            color: var(--R);
          }
          .toggle {
            position: absolute;
            right: 0px;
            top: 2px;
          }
          .uploader.big {
            width: 70px;
            height: 70px;
            background: url(/image/badge-image-input-empty.png) center center
              no-repeat;
            background-size: 70px 70px;
            margin-bottom: 4px;
          }
          .uploader.big:hover:not(.disabled) {
            cursor: pointer;
            width: 70px;
            height: 70px;
            background: url(/image/badge-image-input-hover.png) center center
              no-repeat;
            background-size: 70px 70px;
          }
          .uploader.big.error {
            cursor: pointer;
            width: 70px;
            height: 70px;
            background: url(/image/badge-image-input-error.png) center center
              no-repeat;
            background-size: 70px 70px;
          }
          .uploader.small {
            width: 92px;
            height: 24px;
            background: url(/image/badge-image-input-small-empty.png) center
              center no-repeat;
            background-size: 92px 24px;
            margin-bottom: 4px;
          }
          .uploader.small:hover:not(.disabled) {
            cursor: pointer;
            width: 92px;
            height: 24px;
            background: url(/image/badge-image-input-small-hover.png) center
              center no-repeat;
            background-size: 92px 24px;
          }
          .uploader.small.error {
            cursor: pointer;
            width: 92px;
            height: 24px;
            background: url(/image/badge-image-input-small-error.png) center
              center no-repeat;
            background-size: 92px 24px;
          }
          .filename {
            display: flex;
            align-items: center;
            margin-top: 4px;
          }
          .filename .close {
            cursor: pointer;
            margin-left: 4px;
            width: 14px;
            height: 14px;
            display: inline-block;
          }
          .image-thumb.big {
            width: 66px;
            height: 66px;
            border: 2px solid var(--P400);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
          }
          .image-thumb.big div {
            background: url(${file?.preview || ''}) center center no-repeat;
            background-size: 66px 66px;
            width: 66px;
            height: 66px;
            border-radius: 8px;
          }
          .image-thumb.small {
            width: 88px;
            height: 20px;
            border: 2px solid var(--P400);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            border-radius: 8px;
            overflow: hidden;
          }
          .image-thumb.small div {
            background: url(${file?.preview || ''}) center center no-repeat;
            background-size: 88px 20px;
            width: 88px;
            height: 20px;
            border-radius: 8px;
          }
          p.error {
            color: var(--R);
          }

          .tooltip {
            display: inline-block;
            width: 20.5px;
            height: 23.5px;
            margin-right: 5.8px;
            background: url(/image/information-icon.png) left 3px no-repeat;
            background-size: 18.5px 18.5px;
            margin-top: -3px;
            margin-left: 1px;
          }
        `}
      </style>
    </>
  );
};

export default BadgeImageUploader;
