import 'cropperjs/dist/cropper.css';
import React, { useContext, useRef } from 'react';
import { Cropper } from 'react-cropper';
import {
  CreateBadgeStateContext,
  ImageFile,
  PageType,
} from 'src/hooks/use-create-badge-state';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';

const ImageCropModal: React.FC<{
  file: ImageFile | null;
  setFile: (value: ImageFile | null) => void;
  cropBoxWidthRatio: number;
  cropBoxHeightRatio: number;
  handleClose: () => void;
  handleSubmit: () => void;
}> = ({
  file,
  setFile,
  cropBoxWidthRatio,
  cropBoxHeightRatio,
  handleClose = () => {},
  handleSubmit,
}) => {
  const [state, setState, , , , , pageType] = useContext(
    CreateBadgeStateContext,
  );

  // console.log(src);

  const isViewPage = pageType === PageType.VIEW;
  const cropperRef = useRef<HTMLImageElement>(null);
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    const dataUrl = cropper
      .getCroppedCanvas({
        width: 400,
        height: 400,
      })
      .toDataURL();
    cropper.getCroppedCanvas().toBlob((blob: any) => {
      const newFile = new File([blob], file!.name, {
        type: 'image/png',
      }) as ImageFile;
      console.log(newFile);
      newFile.preview = dataUrl;
      setFile(newFile);
      handleClose();
    });
  };

  return (
    <>
      <div className="container">
        <div className="header">
          <p className="h6-bold WT100">Crop image</p>
          <div className="close-button">
            <CloseIconButton handleClose={handleClose} />
          </div>
        </div>
        <div className="body scroll">
          <Cropper
            src={file!.preview}
            dragMode={'move'}
            style={{ height: 520, width: 520 }}
            initialAspectRatio={cropBoxWidthRatio / cropBoxHeightRatio}
            guides={true}
            cropBoxResizable={false}
            cropBoxMovable={false}
            viewMode={1}
            ref={cropperRef}
          />
        </div>
        {!isViewPage && (
          <div className="button-wrap">
            <Button
              label="Save"
              buttonStyle={ButtonStyle.PRIMARY}
              buttonSize={ButtonSize.M}
              handleClick={() => {
                onCrop();
              }}
              style={{
                margin: 0,
              }}
            />
          </div>
        )}
      </div>
      <style jsx>{`
        .container {
          width: 520px;
          background: var(--G900);
          padding: 40px;
          margin: 0 auto;
        }
        .header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 24px;
        }

        p.desc {
          margin-bottom: 20px;
        }

        div.body {
        }

        .criteria {
          margin-bottom: 20px;
        }
        .criteria:last-of-tpye {
          margin-bottom: 24px;
        }

        .button {
          width: fit-content;
          background: var(--BT15);
          padding: 8px 12px;
          border: 1px solid var(--G700);
          border-radius: 8px;
          align-items: center;
          cursor: pointer;
        }

        .button.disabled {
          cursor: not-allowed;
          color: var(--B600);
        }
        .button-wrap {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 24px;
        }
      `}</style>
    </>
  );
};

export default ImageCropModal;
