import { useState } from 'react';
import { CryptoAsset } from 'src/entity/asset';
import Checkbox from '../checkbox';
import Pfp, { PfpBgType } from '../pfp';

interface Props {
  selectedId: number;
  setSelectedId: (num: number) => void;
  item: CryptoAsset;
}

const PfpItem: React.FC<Props> = (props: Props) => {
  const { selectedId, setSelectedId, item } = props;
  const [isMouseEnter, setMouseEnter] = useState<boolean>(false);

  return (
    <>
      <div
        onMouseEnter={() => {
          setMouseEnter(true);
        }}
        onMouseLeave={() => {
          setMouseEnter(false);
        }}
        className="item"
        onClick={() => setSelectedId(item._idx)}
      >
        <div className="pfp">
          <Pfp
            key={item._idx}
            pfp={item}
            isSelected={selectedId === item._idx}
            isHovered={isMouseEnter}
            pfpBgType={PfpBgType.WHITE}
          />
        </div>
        <Checkbox
          id={item._idx}
          isHovered={isMouseEnter}
          isSelected={selectedId === item._idx}
        />
      </div>
      <style jsx>
        {`
          div.item {
            text-align: center;
            height: 244px;
            cursor: pointer;
          }
          div.item .pfp {
            margin-bottom: 16px;
          }
        `}
      </style>
    </>
  );
};

export default PfpItem;
