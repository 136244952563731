import { CSSProperties } from '@emotion/serialize';
import React, { useEffect } from 'react';
import _Select from 'react-select';
import { defaultInputError, InputError } from './input-v2';

const Select: React.FC<{
  handleOnChange: (value: string) => void;
  name?: string;
  placeholder: string;

  options: any[];
  defaultValue?: {
    value: any;
    label: any;
  };
  isRequired?: boolean;

  error?: InputError;
  setError?: (value: string) => void;
  errorArr?: Record<string, InputError>;
  disabled?: boolean;

  singleValueStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  menuListStyle?: CSSProperties;
  components?: any;
}> = ({
  handleOnChange,
  name,
  placeholder,
  options,
  defaultValue = null,
  isRequired = false,
  error = defaultInputError,
  setError = () => {},
  errorArr = {},
  disabled = false,
  singleValueStyle = {},
  menuStyle = {},
  menuListStyle = {},
  components = {},
}) => {
  const [_value, _setValue] = React.useState<any>(defaultValue);
  const [_error, _setError] = React.useState<string | boolean>(error.msg);
  const customStyles: any = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: '100%',
      background: 'var(--G800)',
      border: 'none',
      boxShadow: _error
        ? '0px 0px 0px 2px var(--R) inset'
        : state.isSelected
        ? '0px 0px 0px 2px var(--P400) inset'
        : 'none',
      borderRadius: 8,
      height: 24,
      padding: '12px 12px 12px 14px',
      cursor: 'pointer',
      minHeight: 48,
      alignItems: 'center',
      flexWrap: 'inherit',
    }),
    placeholder: () => ({
      fontWeight: 'var(--font-regular)',
      color: 'var(--G500)',
    }),
    input: () => ({
      position: 'absolute',
    }),
    valueContainer: () => ({
      padding: 0,
      height: 24,
    }),
    singleValue: () => ({
      color: 'var(--G25)',
      maxWidth: 282,
      overflow: 'hidden',
      ...singleValueStyle,
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      border: 0,
      padding: '8px 0px 8px 8px',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: 0,
      color: 'var(--G400)',
    }),
    group: (provided: any) => ({
      ...provided,
      marginTop: '-8px',
    }),
    groupHeading: (provided: any) => ({
      width: '100%',
      background: 'var(--G800)',
      color: 'var(--G100)',
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
      paddingLeft: 16,
      marginTop: '0px',
    }),
    menu: (provided: any) => ({
      ...provided,
      background: 'var(--G700)',
      border: '1px solid var(--G500)',
      borderRadius: 12,
      ...menuStyle,
    }),
    menuList: () => ({
      maxHeight: 370,
      minHeight: 42,
      overflow: 'auto',
      borderRadius: 12,
      overflowX: 'auto',

      '::-webkit-scrollbar:horizontal': {
        display: 'none',
      },

      '::-webkit-scrollbar': {
        width: 8,
      },
      '::-webkit-scrollbar-thumb': {
        background: 'var(--WT30)',
        borderRadius: 10,
      },
      '::-webkit-scrollbar-track': {
        background: 'var(--BT10)',
      },
      ...menuListStyle,
    }),
    option: (provided: any) => ({
      ...provided,
      background: 'var(--G700)',
      padding: '10px 16px',
      fontWeight: 'var(--font-regular)',
      fontSize: '1.4rem',
      lineHeight: '2.1rem',

      color: 'var(--G25)',
      '&:hover': {
        background: 'var(--G800)',
      },
      '&:first-of-type': {
        // paddingTop: 0,
      },
      '&:last-of-type': {
        marginBottom: 0,
      },
    }),
  };

  useEffect(() => {
    _setError(error.msg);
  }, [error.msg]);

  useEffect(() => {
    if (options.length < 1) {
      _setValue(null);
    }
  }, [options]);

  return (
    <>
      {name && (
        <div className={`name b1-medium`}>
          <span
            style={{
              marginRight: 1,
            }}
          >
            {name}
          </span>
          {isRequired && <span className="LG b1-medium">*</span>}
        </div>
      )}
      <_Select
        key={`key_${JSON.stringify(_value?.value)}`}
        classNamePrefix="react-select"
        defaultValue={defaultValue}
        isSearchable={false}
        options={options}
        styles={customStyles}
        className="b1-medium"
        onChange={(option: any) => {
          if (_value?.label !== option.label) {
            setError('');
            _setError(false);
            _setValue(option);
            handleOnChange(option.value);
          }
        }}
        value={_value || ''}
        placeholder={placeholder}
        isDisabled={disabled}
        components={components}
      />
      {/* {_error && typeof _error === 'string' && (
        <p className="cap1-regular error">{_error}</p>
      )} */}
      <style jsx>
        {`
          .name {
            margin-bottom: 4px;
          }
          p.error {
            color: var(--R);
          }
        `}
      </style>
    </>
  );
};

export default Select;
