import React, { useCallback, useEffect, useState } from 'react';
import { CheckedNoticeIdSession } from 'src/apis/local-storage';
import { NoticesAPI } from 'src/apis/notices-api';
import Icon from './icon';

const MessageNoticeBar: React.FC = () => {
  const defaultNotice = {
    id: '',
    content: '',
    link: '',
  };
  const [notice, setNotice] = useState<{
    id: string;
    content: string;
    link: string;
  }>(defaultNotice);
  const getNotice = useCallback(async () => {
    try {
      const { content, link, id } = await new NoticesAPI().getNotices();
      const checkedNoticeId = CheckedNoticeIdSession.get();
      if (`${id}` !== checkedNoticeId) {
        setNotice({
          id,
          content,
          link,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    getNotice();
  }, []);

  return notice.content ? (
    <>
      <div
        className={`wrap ${notice.link && 'clickable'}`}
        onClick={() => {
          window.open(notice.link, '_blank')?.focus();
        }}
      >
        <div />
        <p className="WT100 b2-regular">{notice.content}</p>
        <div
          className="close"
          onClick={(e) => {
            e.stopPropagation();
            console.log(notice);
            CheckedNoticeIdSession.set(notice.id);
            setNotice(defaultNotice);
          }}
        >
          <Icon iconKey="x" strokeWidth={2.5} />
        </div>
      </div>
      <style jsx>{`
        .wrap {
          background: var(--GR-PG);
          width: 100%;
          padding: 9.5px 0px;
          text-align: center;
          display: flex;
          justify-content: space-between;
        }
        .wrap:hover {
          background: linear-gradient(270deg, #218b68 0%, #543cad 100%);
        }
        .wrap.clickable {
          cursor: pointer;
        }
        p {
          width: fit-content;
          margin: 0 auto;
          background: url(/image/speaker-icon.png) left center no-repeat;
          background-size: 12.8px 12.8px;
          padding-left: 22.4px;
        }
        .close {
          width: 20px;
          height: 20px;
          display: inline-block;
          padding-right: 10px;
        }
      `}</style>
    </>
  ) : (
    <></>
  );
};

export default MessageNoticeBar;
