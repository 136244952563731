import React, { useContext } from 'react';
import BadgeChip from 'src/components/common/badge/badge-chip';
import { CreateBadgeStateContext } from 'src/hooks/use-create-badge-state/index';

enum BgType {
  NONE = 'none',
  BLURRY_THUMBNAIL = 'blurry-thumbnail',
  BG_IMAGE = 'bg-image',
}

const BadgePreview: React.FC = () => {
  const [state] = useContext(CreateBadgeStateContext);

  const bgType = state.backgroundImage
    ? BgType.BG_IMAGE
    : state.badgeThumbnail
    ? BgType.BLURRY_THUMBNAIL
    : BgType.NONE;

  return (
    <>
      <>
        <div className="wrap">
          {bgType === BgType.BLURRY_THUMBNAIL && <div className="bubble"></div>}
          <div className={`bg ${bgType}`}></div>
          <div className="badge">
            <div className="stroke"></div>
            <div className="logo"></div>
            <div className="thumb">
              <div className="glass-effect"></div>
              <div className="thumbnail"></div>
              <div className="inner-shadow"></div>
              <div className="blur"></div>
            </div>
            <div className="noox-logo"></div>
            <div className="category">
              <BadgeChip
                bgColor="black"
                label={state.projectCategory || 'Category'}
              />
            </div>
            <div className="text">
              <p className="title pre-wrap line-3 WT100">
                {state.title || 'Title'}
              </p>
              <p className="description pre-wrap line-3 WT80">
                {state.descriptionEligibility || '1-liner Eligibility Description'}
              </p>
            </div>
          </div>
        </div>
        <p className="label b1-medium G300">Image Preview</p>
      </>
      <style jsx>
        {`
          .wrap {
            width: calc(100% - 12px);
            height: 375px;
            border: 6px solid var(--WT60);
            border-radius: 24px;
            position: relative;
            background: #e3d1ff;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .wrap .bg {
            position: absolute;
            width: 100%;
            height: 375px;
            border-radius: 18px;
          }
          .wrap .bg.${BgType.BG_IMAGE} {
            background: url(${state.backgroundImage?.preview || ''}) center
              center no-repeat;
            background-size: 105% 105%;
          }
          .wrap .bg.${BgType.BLURRY_THUMBNAIL} {
            background: url(${state.badgeThumbnail?.preview || ''}) center
              center no-repeat;
            background-size: 105% 105%;
            filter: blur(40px);
            z-index: 0;
            width: 160%;
            height: 160%;
            margin-left: calc(30% * -1);
            margin-top: calc(30% * -1);
          }
          .wrap .bg.${BgType.NONE} {
            background: url(/image/badge-preview-bg.png) center center no-repeat;
            background-size: 105% 105%;
          }
          .bubble {
            position: absolute;
            width: 100%;
            height: 375px;
            border-radius: 18px;
            background: url(/image/glass-effect.png) center center no-repeat;
            background-size: 105% 105%;
            mix-blend-mode: screen;
            opacity: 0.9;
            z-index: 1;
          }
          .label {
            padding-top: 8px;
            text-align: center;
          }
          .badge {
            position: relative;
            width: 208px;
            height: 348px;
            border-radius: 20px;
            background: linear-gradient(
              157.15deg,
              #55409B 0%,
              #683889 100%
            );
            z-index: 2;
            box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.25);
          }
          .badge > div {
          }
          .badge .stroke {
            width: 208px;
            height: 348px;
            border-radius: 0px;
            background: url(/image/badge-card-stroke.png) center center
              no-repeat;
            background-size: 208px 348px;
            position: absolute;
          }
          .logo {
            position: absolute;
            left: 13px;
            top: 17px;
            width: 60px;
            height: 16px;
            ${state.projectLogo?.preview
              ? `background: url(${state.projectLogo?.preview})
            center center no-repeat;`
              : ``}
            background-size: 60px 16px;
            z-index: 2;
          }
          .noox-logo {
            position: absolute;
            top: 20px;
            right: 13.3px;
            width: 46.7px;
            height: 12px;
            background: url(/image/logo-white.png) center center no-repeat;
            background-size: 46.7px 12px;
          }
          .thumb {
            width: 200px;
            height: 153px;
            margin-top: 32px;
            margin-left: 4px;
          }
          .thumb .blur {
            position: absolute;
            width: 200px;
            height: 153px;
            background: url(/image/badge-effect-blur.png) center center
              no-repeat;
            background-size: 200px 153px;
            mix-blend-mode: color-dodge;
            opacity: 0.9;
          }
          .thumb .inner-shadow {
            position: absolute;
            width: 200px;
            height: 153px;
            background: url(/image/badge-effect-inner-shadow.png) center center
              no-repeat;
            background-size: 200px 153px;
            opacity: 0.7;
          }
          .thumb .thumbnail {
            position: absolute;
            ${state.badgeThumbnail?.preview
              ? `
              width: 92px;
              height: 92px;
              background: url(${state.badgeThumbnail?.preview}) center center no-repeat, var(--WT100);
              border-radius: 100%;
              background-size: 92px 92px;
              left: 58px;
              top: 62.5px;
              `
              : `
              width: 200px;
              height: 153px;
              background: url(/image/badge-effect-thumbnail.png) center center no-repeat;
              background-size: 200px 153px;
              `}
          }
          .thumb .glass-effect {
            position: absolute;
            width: 200px;
            height: 153px;
            background: url(/image/badge-effect-glass-effect.png) center center
              no-repeat;
            background-size: 200px 153px;
            mix-blend-mode: screen;
            opacity: 0.7;
            z-index: 1;
          }
          .category {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            top: 148px;
            z-index: 2;
          }
          .text {
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            width: 186px;
            height: 150px;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 13px;
            box-sizing: border-box;
            bottom: 13px;
            left: 11px;
            z-index: 2;
          }
          .title {
            font-size: 1.8rem;
            line-height: 2.2rem;
            font-weight: var(--font-bold);
            width: 100%;
            text-align: center;
            margin: 0 auto 6px;
          }
          .description {
            width: 100%;
            text-align: center;
            font-size: 1.2rem;
            line-height: 1.8rem;
            font-weight: var(--font-regular);
            margin: 0 auto;
          }
        `}
      </style>
    </>
  );
};

export default BadgePreview;
