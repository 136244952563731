import { useCallback, useMemo, useState } from 'react';
import { AlertModal } from 'src/components';
import { ALERT_MODAL_TYPE } from 'src/components/common/alert-modal/alert-modal';

export default function useAlertModal(
  alertModalType: ALERT_MODAL_TYPE,
  handleSubmit?: any,
): [React.ReactElement<any, any>, (params: unknown) => void, () => void] {
  const [params, setParams] = useState<unknown>(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback((params?: unknown) => {
    if (params) {
      setParams(params);
    }
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setParams(null);
  }, []);

  const renderAlertModal = useMemo(
    () => (
      <AlertModal
        params={params || {}}
        modalType={alertModalType}
        isOpen={isOpen}
        handleClose={handleClose}
        handleSubmit={handleSubmit || null}
      />
    ),
    [isOpen, alertModalType, params, handleClose, handleSubmit],
  );

  return [renderAlertModal, handleOpen, handleClose];
}
