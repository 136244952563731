import { useCallback, useMemo, useState } from 'react';
import { Modal } from 'src/components/';
import { MODAL_TYPE } from 'src/components/common/modal/modal';

export default function useModal(
  modalType: MODAL_TYPE,
  handleSubmit?: any,
  handleCancel?: any,
): [React.ReactElement<any, any>, (params: unknown) => void, () => void] {
  const [params, setParams] = useState<unknown>(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback((params?: unknown) => {
    if (params) {
      console.log('params', params);
      setParams(params);
    }
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    setParams(null);
    if (handleCancel) {
      handleCancel();
    }
  }, []);

  const renderModal = useMemo(
    () => (
      <Modal
        params={params || {}}
        modalType={modalType}
        isOpen={isOpen}
        handleClose={handleClose}
        handleSubmit={handleSubmit || null}
      />
    ),
    [isOpen, modalType, params, handleClose, handleSubmit],
  );

  return [renderModal, handleOpen, handleClose];
}
