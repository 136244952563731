import Image from 'next/image';
import React, { useCallback } from 'react';
import { CryptoAsset } from 'src/entity/asset';
import { Badge } from 'src/entity/badge';

export enum PfpBgType {
  GRADIENT = 'gradient',
  WHITE = 'white',
  NONE = 'none',
}
interface Props {
  pfp: CryptoAsset;
  pfpBgType?: PfpBgType;
  width?: number;
  primaryBadge?: Badge;
  address?: string;
  isSelected?: boolean;
  isHovered?: boolean;
  handleSelected?: () => void;
}

const Pfp: React.FC<Props> = ({
  pfp,
  pfpBgType = PfpBgType.NONE,
  width = 200,
  isSelected = false,
  isHovered = false,
  handleSelected,
}) => {
  const handleOnClick = useCallback(async () => {
    if (handleSelected) {
      await handleSelected();
    }
  }, [handleSelected]);

  const isImgNotExist = pfp ? !pfp.imgUrl : true;
  const pfpImgUrlSplitArr = isImgNotExist ? [] : pfp.imgUrl.split('.');

  // video file
  const fileFormat: string = isImgNotExist
    ? 'png'
    : pfpImgUrlSplitArr[pfpImgUrlSplitArr.length - 1];
  const isVideo = fileFormat === 'mp4' || fileFormat === 'webm';

  return (
    <>
      <div className="pfp-area">
        <div className={pfpBgType}></div>

        <div className="content">
          <div onClick={handleOnClick} className={`clip`}>
            <>
              <div></div>
              {isImgNotExist ? (
                <div
                  style={{
                    background: 'var(--P400)',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <p className="b1-medium">
                    {pfp ? pfp.name || 'unknown' : ''}
                  </p>
                </div>
              ) : (
                <>
                  {isVideo ? (
                    <video autoPlay loop className="video">
                      <source src={pfp.imgUrl} type={`video/${fileFormat}`} />
                    </video>
                  ) : (
                    <Image
                      onClick={() => {
                        //
                      }}
                      src={pfp.imgUrl}
                      alt={pfp.name}
                      objectFit="cover"
                      className="image"
                      layout="fill"
                    />
                  )}
                  {isSelected && <div className="shadow"></div>}
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <style jsx>{`
        
    div.pfp-area {
      display: flex;
      width:${width}px;
      height: ${width}px;
      align-items: center;
      justify-content: center;
      position: relative;
      opacity: ${isHovered ? 0.8 : 1};
    }
    div.gradient {
      width: ${width}px;
      height:${width}px;
      position: absolute;
      border-radius: 100%;
      background: linear-gradient(90deg, #7e75ec 0%, #b961ec 100%);
    }
    div.white {
      width: ${width}px;
      height:${width}px;
      position: absolute;
      border-radius: 100%;
      background: var(--WT100);
    }
    div.none {
      width: ${width}px;
      height:${width}px;
      position: absolute;
      border-radius: 100%;
      background: none;
    }
    
    div.content {
      width: ${width - 14}px;
      border-radius: 100%;
      position: relative !important;
    }
    div.clip {
      width: ${width - 14}px;
      height: ${width - 14}px;
      position: relative;
    }
    div.none + div.content {
      width: ${width}px;
    }
    div.none + div > div.clip {
      width: ${width}px;
      height: ${width}px;
    }
    
    div.primary-badge {
      width: 116px;
      border-radius: 100px;
      background: #ffb527;
      font-size: 1.5rem;
      line-height: 2.2rem;
      min-height: 24px;
      font-weight: var(--font-bold);
      padding: 7px 20px;
      text-align: center;
      margin: -27px auto 0px;
    }
    .video {
      width: ${width - 14}px;
      position: absolute;
      min-width: ${width - 14}px;
      min-height: auto;
      z-index: 0;
    }
    .image {
      min-width: ${width - 14}px;
      min-height: auto;
    }
    div.shadow {
      width: ${width - 10}px;
      height: ${width - 10}px;
    
      box-sizing: border-box;
      position: absolute;
      top 0;
      left: 0;
      margin-left: -166px;
    }

    span.check {
      background: no-repeat center center url(/image/check.png);
      background-size: 30px 30px;
      position: absolute;
      width: 30px;
      height: 30px;
      top: -7px;
      right: -7px;
      z-index: 1;
    }
  `}</style>
    </>
  );
};

export default Pfp;
