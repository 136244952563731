import { default as React, useEffect, useState } from 'react';
import { UsersAPI } from 'src/apis/users-api';
import useUser from 'src/hooks/use-user';
import Loading from '../loading';
import BadgeItem from './badge-item';
import ModalHeader from './modal-header';

const SelectPrimaryBadgeModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose, handleSubmit }) => {
  const [initialId, setInitialId] = useState<number>(-1);
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [profile] = useUser();
  const [data, lastElementRef] = new UsersAPI().useGetMyProfileBadges({
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (data && profile && selectedId < 0) {
      for (const badge of data.badges) {
        if (profile.primaryBadge && profile?.primaryBadge.id === badge.id) {
          setInitialId(badge._idx);
        }
      }
    }
  }, [data, profile, selectedId]);

  return (
    <>
      <div className="container">
        <div className="header">
          <ModalHeader
            title="Select Primary Badge"
            handleCancel={handleClose}
            handleSubmit={
              data && data.badges.length > 0
                ? async () => {
                    await handleSubmit(data.badges[selectedId].id);
                    handleClose();
                  }
                : null
            }
            showCancel={true}
          />
        </div>
        <div className="body scroll">
          {!data || !profile ? (
            <div className="loader-wrap">
              <Loading />
            </div>
          ) : (
            data.badges.map((item, i) => {
              const isLastElement = data.badges.length === i + 1;
              const _selectedId = selectedId >= 0 ? selectedId : initialId;
              return (
                <div key={item.id} ref={isLastElement ? lastElementRef : null}>
                  <BadgeItem
                    isSelected={_selectedId === item._idx}
                    setSelectedId={(badge) => {
                      setSelectedId(badge._idx);
                    }}
                    item={item}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 1160px; /* 1220 - 40 * 2 */
          margin-right: -20px;
        }
        div.body {
          width: 1140px;
          display: flex;
          gap: 24px 20px;
          flex-wrap: wrap;
          overflow: auto;
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */
          justify-content: flex-start;
          cursor: pointer;
          min-height: 304px;
          height: calc(100vh - 120px);
          max-height: 660px;
        }

        .header {
          padding-right: 20px;
        }

        .loader-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      `}</style>
    </>
  );
};

export default SelectPrimaryBadgeModal;
