import React, { useCallback, useState } from 'react';
import { TEST_ADDRESS } from 'src/apis/const';
import { UsersAPI } from 'src/apis/users-api';
import { Input, Pfp } from 'src/components';
import useModal from 'src/hooks/use-modal';
import { mutate } from 'swr';
import Button, { ButtonSize, ButtonStyle } from '../button';
import Icon from '../icon';
import Loading from '../loading';
import { PfpBgType } from '../pfp';
import { noBadgesForPrimaryToast, noPfpExistsToast } from '../toast';
import { MODAL_TYPE } from './modal';
import ModalHeader from './modal-header';

const nicknameErrorArr = [
  {
    validate: (val: string) => val.trim() !== '',
    msg: "Name can't be blank.",
  },
  {
    validate: (val: string) => !/\s\s/.test(val),
    msg: 'Two or more consecutive spaces cannot be used.',
  },
  {
    validate: (val: string) => /^.{0,30}$/i.test(val),
    msg: 'Name should not exceed 30 characters.',
  },
];

const bioErrorArr = [
  {
    validate: (val: string) => /^(?=[\S\s]{0,160}$)[\S\s]*/i.test(val),
    msg: 'Bio should not exceed 160 characters.',
  },
];

const EditProfileModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose, handleSubmit }) => {
  const { data: profile } = new UsersAPI().useGetProfile();
  const [_badges] = new UsersAPI().useGetMyProfileBadges();
  const loading = !profile || !_badges;
  console.log(profile);
  const [nickname, setNickname] = React.useState<string>(
    profile?.nickname || '',
  );
  const [bio, setBio] = React.useState<string>(profile?.bio || '');
  const [choosePfpLoading, setChoosePfpLoading] = useState<boolean>(false);

  const [renderInterestModal, handleOpenInterestModal] = useModal(
    MODAL_TYPE.SelectInterest,
    async (interests: number[]) => {
      await new UsersAPI().updateProfile({
        interests,
      });
      mutate(UsersAPI.SWR_PATH.GET_PROFILE());
    },
  );

  const [renderPrimaryBadgeModal, handleOpenPrimaryBadgeModal] = useModal(
    MODAL_TYPE.SelectPrimaryBadge,
    async (primaryBadge: string) => {
      await new UsersAPI().updateProfile({
        primaryBadge,
      });
      mutate(UsersAPI.SWR_PATH.GET_PROFILE());
    },
  );

  const [renderPfpModal, handleOpenPfpModal] = useModal(
    MODAL_TYPE.SelectPfp,
    async (contract: string, tokenId: string) => {
      await new UsersAPI().updateProfile({
        primaryPfp: {
          contract,
          tokenId,
        },
      });
      mutate(UsersAPI.SWR_PATH.GET_PROFILE());
    },
  );

  const handleOnSubmit = useCallback(async () => {
    if (!profile) return;
    const dataChanged: Record<string, any> = {};
    if (profile.nickname !== nickname) {
      for (const err of nicknameErrorArr) {
        if (!err.validate(nickname)) {
          return;
        }
      }
      dataChanged['nickname'] = nickname.trim();
    }

    if (profile.bio !== bio) {
      for (const err of bioErrorArr) {
        if (!err.validate(bio)) {
          return;
        }
      }
      dataChanged['bio'] = bio.trim();
    }
    await handleSubmit(dataChanged);
    handleClose();
  }, [nickname, bio, profile, handleClose, handleSubmit]);

  return (
    <>
      <ModalHeader
        title="Edit Profile"
        handleCancel={handleClose}
        showCancel={true}
        handleSubmit={handleOnSubmit}
      />
      {renderInterestModal}
      {renderPrimaryBadgeModal}
      {renderPfpModal}
      <div className="body scroll">
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex">
              <div className="pfp">
                <Pfp pfp={profile.primaryPfp} pfpBgType={PfpBgType.WHITE} />
              </div>
              <div className="edit-button">
                <Button
                  label="Choose Profile Picture"
                  buttonStyle={ButtonStyle.LINEAR}
                  buttonSize={ButtonSize.S}
                  loading={choosePfpLoading}
                  handleClick={async () => {
                    try {
                      setChoosePfpLoading(true);
                      const getPfpAssetsRes = await new UsersAPI().getPfpAssets(
                        `?${TEST_ADDRESS}size=1`,
                      );
                      if (getPfpAssetsRes.assets.length !== 0) {
                        handleOpenPfpModal(null);
                      } else {
                        noPfpExistsToast();
                      }
                    } catch (e: any) {
                      console.error(e.message);
                    } finally {
                      setChoosePfpLoading(false);
                    }
                  }}
                  icon={<Icon iconKey="pencil" />}
                  iconWidth={18}
                />
              </div>

              <div className="input">
                <Input
                  name={'Display Name'}
                  value={nickname}
                  setValue={setNickname}
                  maxLength={30}
                  errorArr={nicknameErrorArr}
                />
              </div>

              <div className="input">
                <Input
                  name={'Primary Badge'}
                  value={
                    profile.primaryBadge
                      ? profile.primaryBadge.name
                      : _badges.badges.length === 0
                      ? 'You don’t have any badge'
                      : 'Select your primary badge'
                  }
                  disabled={true}
                  onClick={() => {
                    if (_badges.badges.length === 0) {
                      noBadgesForPrimaryToast();
                    } else {
                      handleOpenPrimaryBadgeModal(null);
                    }
                  }}
                  isWarning={!profile.primaryBadge}
                />
              </div>
              <div className="input textarea">
                <Input
                  name={'Bio'}
                  isTextarea={true}
                  value={bio}
                  placeholder={'Introduce yourself to web3 world!'}
                  setValue={setBio}
                  maxLength={160}
                  errorArr={bioErrorArr}
                  isWarning={!bio}
                />
              </div>
              <div className="input">
                <Input
                  name={'Interest'}
                  value={
                    profile.interests.length > 0
                      ? profile.interests
                          .map((interest) => interest.name)
                          .join(', ')
                      : 'Select your interest'
                  }
                  disabled={true}
                  onClick={() => {
                    handleOpenInterestModal(null);
                  }}
                  isWarning={profile.interests.length === 0}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <style jsx>{`
        div.body {
          width: 714px;
          max-height: 616px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: -20px;
          overflow-y: auto;
          overflow-x: hidden;
          min-height: 420px;
          height: calc(100vh - 120px);
          padding-right: 28px;
          margin-right: -24px;
        }
        div.flex {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        div.pfp,
        div.edit-button {
          margin: 0 auto 16px;
          width: 714px;
          display: flex;
          justify-content: center;
        }
        div.pfp {
          margin: 0 auto 12px;
        }
        div.input {
          width: 710px;
          margin-bottom: 16px;
          position: relative;
        }
        div.input > span {
          position: absolute;
          left: 26px;
          top: 12px;
          color: var(--WT30);
        }
        div.textarea > div input {
          height: 120px !important;
        }
        div.submit {
          margin: 0 auto;
        }
      `}</style>
    </>
  );
};

export default EditProfileModal;
