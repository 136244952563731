import { default as React, useCallback, useEffect, useState } from 'react';
import { UpdateVisibleForProfileParam, UsersAPI } from 'src/apis/users-api';
import { Badge, GetBadgesResponse } from 'src/entity/badge';
import { isObjectEmpty } from 'src/utils';
import { KeyedMutator } from 'swr';
import Loading from '../loading';
import { oneBadgeShouldExist } from '../toast';
import BadgeItem from './badge-item';
import ModalHeader from './modal-header';

type ChangedIdMap = Record<string, boolean>;

const HideBadgeModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose, handleSubmit }) => {
  const [data, lastElementRef, , mutate] = new UsersAPI().useGetMyProfileBadges(
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  return (
    <_HideBadgeModal
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      data={data}
      lastElementRef={lastElementRef}
      mutate={mutate}
    />
  );
};

const _HideBadgeModal: React.FC<{
  data: GetBadgesResponse | null;
  lastElementRef: (node: any) => void;
  handleClose: () => void;
  handleSubmit: any;
  mutate: KeyedMutator<any[]>;
}> = ({ data, lastElementRef, handleClose, handleSubmit, mutate }) => {
  const [initialChangedIdMap, setInitialChangedIdMap] = useState<ChangedIdMap>(
    {},
  );
  const [changedIdMap, setChangedIdMap] = useState<ChangedIdMap>({});

  useEffect(() => {
    const _changedIdMap: ChangedIdMap = {};
    if (data) {
      data.badges.map((badge) => {
        _changedIdMap[badge.id] = badge.isVisible ? true : false;
      });
      setInitialChangedIdMap((map: any) => ({ ...map, ..._changedIdMap }));
      setChangedIdMap((map: any) => {
        return { ..._changedIdMap, ...map };
      });
    }
  }, [data, setInitialChangedIdMap, setChangedIdMap]);

  const setSelectedId = useCallback(
    (badge: Badge) => {
      const { id } = badge;
      setChangedIdMap((changedIdMap) => {
        const newState = {
          ...changedIdMap,
          [id]: !changedIdMap[id],
        };
        if (!Object.values(newState).includes(true)) {
          oneBadgeShouldExist();
          return changedIdMap;
        }
        return newState;
      });
    },
    [setChangedIdMap],
  );

  const _handleSubmit = useCallback(async () => {
    const _finalChangedIdArr: UpdateVisibleForProfileParam[] = [];
    for (const [key, value] of Object.entries(changedIdMap)) {
      if (value !== initialChangedIdMap[key]) {
        _finalChangedIdArr.push({
          badgeId: key,
          isVisible: value,
        });
      }
    }

    await handleSubmit(_finalChangedIdArr);
    await mutate();
  }, [changedIdMap, initialChangedIdMap, handleSubmit, mutate]);

  return (
    <>
      <div className="container">
        <div className="header">
          <ModalHeader
            title="Show/Hide"
            handleCancel={handleClose}
            handleSubmit={
              data && data.badges.length > 0
                ? async () => {
                    await _handleSubmit();
                    handleClose();
                  }
                : null
            }
            showCancel={true}
          />
        </div>
        <div className="body scroll">
          {!data ? (
            <div className="loader-wrap">
              <Loading />
            </div>
          ) : (
            data.badges.map((item, i) => {
              const isLastElement = data.badges.length === i + 1;
              return (
                <div key={item.id} ref={isLastElement ? lastElementRef : null}>
                  <BadgeItem
                    isSelected={
                      !isObjectEmpty(changedIdMap)
                        ? changedIdMap[item.id]
                        : false
                    }
                    setSelectedId={setSelectedId}
                    item={item}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
      <style jsx>{`
        .container {
          width: 1140px; /* 1200 - 40 * 2 */
          margin-right: -20px;
        }
        div.body {
          display: flex;
          gap: 24px 20px;
          flex-wrap: wrap;
          overflow: auto;
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */
          justify-content: flex-start;
          cursor: pointer;
          min-height: 304px;
          max-height: 660px;
          height: calc(100vh - 120px);
        }

        .header {
          padding-right: 20px;
        }

        .loader-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      `}</style>
    </>
  );
};

export default HideBadgeModal;
