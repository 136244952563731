import React, { useCallback, useState } from 'react';
import { Button } from 'src/components';
import { ButtonSize, ButtonStyle } from '../button';

const ModalControl: React.FC<{
  handleCancel: () => void;
  showCancel?: boolean;
  handleSubmit?: (() => Promise<void>) | null;
  cancelText?: string;
  submitText?: string;
}> = ({
  handleCancel,
  handleSubmit,
  cancelText,
  submitText,
  showCancel = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const _handleSubmit = useCallback(async () => {
    try {
      setLoading(true);
      if (handleSubmit) {
        await handleSubmit();
      }
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setLoading(false);
    }
  }, [handleSubmit]);
  return (
    <>
      <div className="wrap">
        {showCancel && (
          <Button
            label={cancelText || 'Go back'}
            buttonStyle={ButtonStyle.NEUTRAL}
            buttonSize={ButtonSize.S}
            handleClick={handleCancel}
          />
        )}

        {handleSubmit && (
          <Button
            label={submitText || 'Save'}
            buttonStyle={ButtonStyle.PRIMARY}
            buttonSize={ButtonSize.S}
            handleClick={_handleSubmit}
            loading={loading}
          />
        )}
      </div>
      <style jsx>
        {`
          div.wrap {
            display: flex;
            gap: 12px;
          }
          div.close {
            cursor: pointer;
            margin-right: 10px;
            width: 36px;
            height: 36px;
            background: url(/image/close.png) center center no-repeat;
            background-size: 36px 36px;
          }
          div.close:hover {
            background: url(/image/close-hover.png) center center no-repeat;
            background-size: 36px 36px;
          }
        `}
      </style>
    </>
  );
};

export default ModalControl;
