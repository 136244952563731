import { BearerTokenSession } from 'src/apis/local-storage';
import { _useSWR } from 'src/apis/swr';
import { UsersAPI } from 'src/apis/users-api';
import { User } from 'src/entity/user';

const useUser = (): [User | undefined, boolean, any] => {
  const isTokenExist: any =
    typeof window !== 'undefined' ? BearerTokenSession.get() : false;

  const { data, mutate, error } = _useSWR<User>(
    UsersAPI.SWR_PATH.GET_PROFILE(),
    async () => new UsersAPI().getProfile(),
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false
    },
  );

  const loading = isTokenExist ? !data && !error : false;
  return [!error ? data || undefined : undefined, loading, mutate];
};
export default useUser;
