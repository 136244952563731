import React from 'react';
import useCopyClipboard from 'src/hooks/use-copy-clipboard';
import Icon from './icon';
import OvalButton, { OvalButtonSize, OvalButtonType } from './oval-button';

const CopyLinkButton: React.FC<{
  url: string;
  ovalButtonType?: OvalButtonType;
}> = ({ url, ovalButtonType = OvalButtonType.NORMAL }) => {
  const [, copy] = useCopyClipboard();
  return (
    <OvalButton
      icon={<Icon iconKey="link" />}
      ovalButtonSize={OvalButtonSize.L}
      ovalButtonType={ovalButtonType}
      handleClick={() => {
        copy(url);
      }}
    />
  );
};
export default CopyLinkButton;
