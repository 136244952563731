import Image from 'next/image';
import React from 'react';
import { BlocksAPI } from 'src/apis/blocks-api';
import { DISCORD_URL, DOCS_URL } from 'src/apis/const';
import useAlertModal from 'src/hooks/use-alert-modal';
import Logo from '../../../public/image/logo.png';
import { ALERT_MODAL_TYPE } from '../common/alert-modal/alert-modal';

const Footer: React.FC = () => {
  const { data } = new BlocksAPI().useGetLastSyncedBlock({
    refreshInterval: 1000 * 60,
  });
  const [renderDownloadAssetModal, handleDownloadAssetModalOpen] =
    useAlertModal(ALERT_MODAL_TYPE.DownloadAsset, () => {});

  return (
    <>
      {renderDownloadAssetModal}
      <footer>
        <div className="wrap">
          <div className="item">
            <div className="logo">
              <Image
                src={Logo}
                alt="Noox logo"
                width={99}
                height={25}
                className="logo"
              />
            </div>

            <p className="copyright b2-regular">
              Noox © 2022 All rights reserved
            </p>
            <div className="block b2-regular G200">
              Last Synced Block
              <span
                className="b2-bold G25"
                style={{
                  display: 'inline-block',
                  marginLeft: 12,
                }}
              >
                {data ? data[0].latestSyncedBH : 'Loading...'}
              </span>
            </div>
          </div>
          <div className="item">
            <div className="list">
              <div className="b2-bold">Noox</div>
              <ul className="b2-regular">
                <li>
                  <a
                    rel="noopener noreferrer"
                    href={`https://mirror.xyz/noox.eth/bhI8re02JtQQ78UbXi-4dLpmiuNtCVEfDymSd1XAYt4`}
                    target="_blank"
                  >
                    Introduction
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    href={`https://noox.notion.site/Noox-Open-Roles-762cd119930340b4bde59fac1efec981`}
                    target="_blank"
                  >
                    Open Roles
                  </a>
                </li>
                <li>
                  <a rel="noopener noreferrer" href={DOCS_URL} target="_blank">
                    Docs
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    href={`https://docs.noox.world/faqs/faq`}
                    target="_blank"
                  >
                    FAQ
                  </a>
                </li>
                <li
                  onClick={() => {
                    handleDownloadAssetModalOpen(null);
                  }}
                >
                  <a rel="noopener noreferrer" target="_blank">
                    Brand Asset
                  </a>
                </li>
                {/* <li>
                  <Link href={''} as={''}>
                    Terms
                  </Link>
                </li>
                <li>
                  <Link href={''} as={''}>
                    Privacy policy
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="list">
              <div className="b2-bold">Community</div>
              <ul className="b2-regular">
                <li>
                  <a
                    target="_blank"
                    href={DISCORD_URL}
                    rel="noopener noreferrer"
                  >
                    Discord
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://twitter.com/noox_world"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://mirror.xyz/noox.eth"
                    rel="noopener noreferrer"
                  >
                    Mirror
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://discuss.noox.world"
                    rel="noopener noreferrer"
                  >
                    Forum
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <script
          dangerouslySetInnerHTML={{
            __html: `const BADGE_ID = '8d4a00c065484051';`,
          }}
        ></script>
        <script
          async
          type="text/javascript"
          src="https://static.alchemyapi.io/scripts/analytics/badge-analytics.js"
        ></script>

        <img
          onClick={() => {
            (window as any).logBadgeClick();

            console.log('hi');
          }}
          id="badge-button"
          style={{
            marginTop: 10,
            width: 160,
            height: 35.3333,
            cursor: 'pointer',
            pointerEvents: 'auto',
          }}
          src="https://static.alchemyapi.io/images/marketing/badge.png"
          alt="Alchemy Supercharged"
        />
      </footer>
      <style jsx>
        {`
          footer {
            width: 1200px;
            font-family: var(--font);
            margin: 50px auto 0px;
            padding-bottom: 114px;
            color: var(--WT60);
          }
          .wrap {
            display: flex;
            justify-content: space-between;
          }
          .item {
            width: 525px;
          }
          .logo {
            margin-bottom: 20px;
          }
          .desc {
            margin-bottom: 22px;
          }
          .item .block {
            padding: 6px 12px 6px 34px;
            width: fit-content;
            border-radius: 8px;
            margin-top: 20px;
            background: url(/image/block-eth-icon.png) 14.68px center no-repeat,
              var(--WT15);
            background-size: 12.37px 20px;
          }
          .item:nth-of-type(2) {
            display: flex;
            justify-content: space-between;
          }
          .list {
            width: 240px;
            display: flex;
          }
          .list > div {
          }
          .list > ul {
            margin-left: 30px;
          }
          .list > ul > li {
            margin-bottom: 5px;
          }
          @media (min-width: 992px) and (max-width: 1339px) {
            footer {
              width: calc(100% - 120px);
              padding: 0px 60px 114px;
            }
          }
        `}
      </style>
    </>
  );
};

export default Footer;
