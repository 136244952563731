import React, { useCallback, useEffect, useState } from 'react';
import { UsersAPI } from 'src/apis/users-api';
import { getCategorizedInterest, Interest } from 'src/entity/interest';
import InterestComponent from '../interest';
import Loading from '../loading';
import ModalHeader from './modal-header';

const SelectInterestModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose, handleSubmit }) => {
  const [selectedMap, setSelectedMap] = useState<Record<number, boolean>>({});
  const { data: interests } = new UsersAPI().useGetInterests();
  const { data: profile } = new UsersAPI().useGetProfile();
  const categorizedData = getCategorizedInterest(interests);
  const loading = !profile || !interests;

  useEffect(() => {
    if (!loading) {
      const _map: Record<number, boolean> = {};
      profile.interests.map((interest: any) => {
        _map[interest.dataId] = true;
      });
      setSelectedMap(_map);
    }
  }, [profile, interests, loading, setSelectedMap]);

  const getSelectedInterestLength = (map: Record<number, boolean>) => {
    return Object.values(map).filter((item) => item).length;
  };

  const handleOnSubmit = useCallback(async () => {
    const arr = Object.entries(selectedMap)
      .filter(([, value]) => {
        return value;
      })
      .map(([key]) => Number(key));

    await handleSubmit(arr);
    handleClose();
  }, [selectedMap, handleSubmit, handleClose]);

  const handleOnSelected = useCallback((item: Interest) => {
    setSelectedMap((state: any) => {
      const newMap = {
        ...state,
        [item.dataId]: !state[item.dataId],
      };

      if (getSelectedInterestLength(newMap) > 10) {
        return state;
      }

      return {
        ...state,
        [item.dataId]: !state[item.dataId],
      };
    });
  }, []);

  return (
    <>
      <div className="container">
        <ModalHeader
          title={
            <p>
              Edit Interest
              <span
                className="yellow h7-medium"
                style={{
                  paddingLeft: 12,
                }}
              >
                ({getSelectedInterestLength(selectedMap)}/10)
              </span>
            </p>
          }
          handleCancel={handleClose}
          handleSubmit={handleOnSubmit}
          showCancel={true}
        />

        {loading ? (
          <div className="loader-wrap">
            <Loading />
          </div>
        ) : (
          <div className="body scroll">
            {categorizedData.map((category) => (
              <div className="category" key={category[0].categoryId}>
                <p className="h7-medium">{category[0].categoryKey}</p>
                <div className="interest-list">
                  {category.map((interest) => {
                    const isSelected = selectedMap[interest.dataId];
                    return (
                      <span key={interest.dataId}>
                        <InterestComponent
                          interest={interest}
                          isSelected={isSelected}
                          handleSelected={() => handleOnSelected(interest)}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <style jsx>{`
        .container {
          width: 742px;
        }

        .body {
          max-width: 744px;
          width: 100%;
          flex-wrap: wrap;
          max-height: 686px;
          overflow-y: auto;
          overflow-x: hidden;
          justify-content: space-evenly;
          height: calc(100vh - 120px);
          min-height: 420px;
        }

        .category > p {
          margin-bottom: 20px;
        }
        .category > p > span {
          margin-right: 8px;
        }
        .interest-list {
          margin-bottom: 32px;
        }
        .interest-list > span {
          display: inline-block;
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .category:last-of-type .interest-list {
          margin-bottom: 0px;
        }
        .loader-wrap {
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 350px);
          max-height: calc(100vh - 200px);
        }
      `}</style>
    </>
  );
};

export default SelectInterestModal;
