/** @format */
import { BigNumber, ethers } from 'ethers';
import { mintingFee } from 'src/const/chains';
import { Chain } from '../../entity/badge';
import BadgeAbi from './abi/badge-abi.json';
import BadgeMinterAbi from './abi/badge-minter-abi.json';

interface ContractParam {
  contractAddress: string;
  abi: any;
}

class NooxContract {
  badgeContract: ethers.Contract;
  minterContract: ethers.Contract;

  constructor(
    badgeContract: ContractParam,
    minterContract: ContractParam,
    _provider: ethers.providers.Provider | ethers.Signer | undefined,
  ) {
    this.badgeContract = new ethers.Contract(
      badgeContract.contractAddress,
      badgeContract.abi,
      _provider,
    );
    this.minterContract = new ethers.Contract(
      minterContract.contractAddress,
      minterContract.abi,
      _provider,
    );
  }

  async isClaimed(address: string, tokenId: string): Promise<boolean> {
    try {
      const val = await this.badgeContract.balanceOf(address, tokenId);
      return BigNumber.from(val).gte(1);
    } catch (e: any) {
      console.error(e.message);
      return false;
    }
  }

  async claim(
    tokenId: string,
    signature: string,
    chain: Chain,
  ): Promise<string> {
    const obj = {
      value: ethers.utils.parseEther(mintingFee[chain])._hex,
    };
    const gasLimit = await this.minterContract.estimateGas.claim(
      tokenId,
      signature,
      { ...obj },
    );
    const tx = await this.minterContract.claim(tokenId, signature, {
      ...obj,
      gasLimit,
    });

    return tx.hash;
  }

  async migrate(
    burnBadgeIds: string[],
    claimBadgeId: string,
    signature: string,
  ): Promise<string> {
    const gasLimit = await this.minterContract.estimateGas.migrate(
      burnBadgeIds,
      claimBadgeId,
      signature,
    );
    const tx = await this.minterContract.migrate(
      burnBadgeIds,
      claimBadgeId,
      signature,
      {
        gasLimit,
      },
    );
    return tx.hash;
  }
}

const nooxContract = (provider: any, chain: Chain) => {
  let badgeAddress;
  let minterAddress;

  switch (chain) {
    case Chain.Optimism: {
      badgeAddress = process.env.OPTIMISM_BADGE_CONTRACT_ADDRESS!;
      minterAddress = process.env.OPTIMISM_MINTER_CONTRACT_ADDRESS!;
      break;
    }
    case Chain.Polygon: {
      badgeAddress = process.env.POLYGON_BADGE_CONTRACT_ADDRESS!;
      minterAddress = process.env.POLYGON_MINTER_CONTRACT_ADDRESS!;
      break;
    }
    case Chain.Ethereum:
    default: {
      badgeAddress = process.env.ETHEREUM_BADGE_CONTRACT_ADDRESS!;
      minterAddress = process.env.ETHEREUM_MINTER_CONTRACT_ADDRESS!;
      break;
    }
  }

  return new NooxContract(
    {
      contractAddress: badgeAddress,
      abi: BadgeAbi,
    },
    {
      contractAddress: minterAddress,
      abi: BadgeMinterAbi,
    },
    provider,
  );
};

export { nooxContract };
