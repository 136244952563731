import React, { useCallback } from 'react';
import { codePointsToString } from 'src/utils';
import { Interest } from '../../entity/interest';

interface Props {
  interest: Interest;
  isSelected?: boolean;
  handleSelected?: () => void;
}

const InterestComponent: React.FC<Props> = ({
  interest,
  isSelected,
  handleSelected,
}) => {
  const handleOnClick = useCallback(() => {
    if (handleSelected) handleSelected();
  }, [handleSelected]);

  return (
    <>
      <div
        onClick={handleOnClick}
        className={`wrap b2-bold ${isSelected && 'selected'}`}
        data-id={interest.dataId}
      >
        <p>
          <span className="emoji">
            {codePointsToString(interest.emojiCodes)}
          </span>
          {interest.name}
        </p>
      </div>
      <style jsx>{`
        div.wrap {
          display: inline-block;
          border-radius: 30px;

          padding: 7.5px 16px 7.5px 14px;
          font-weight: var(--font-medium) !important;
          color: #c2b6f3;
          background: var(--B900);
          cursor: ${handleSelected ? 'pointer' : 'default'};
        }
        div.wrap:hover {
          color: ${handleSelected ? 'var(--WT100) !important' : '#c2b6f3'};
          background: ${handleSelected
            ? 'var(--P300) !important'
            : 'var(--B900)'};
        }
        div.wrap.selected {
          color: var(--WT100) !important;
          background: var(--P500) !important;
        }
        span {
          padding-right: 8px;
          vertical-align: middle;
        }
        p {
          text-align: center;
        }
      `}</style>
    </>
  );
};

export default InterestComponent;
