import React from 'react';
import { DISCORD_URL } from 'src/apis/const';
import Button, { ButtonSize, ButtonStyle } from '../button';
import CloseIconButton from '../close-icon-button';
import ModalHeader from '../modal/modal-header';

const DownloadAssetModal: React.FC<{
  handleClose: () => void;
  handleSubmit: any;
}> = ({ handleClose }) => {
  return (
    <>
      <div className="container">
        <ModalHeader
          title={'Download logo assets'}
          handleCancel={handleClose}
        />
        <div className="close-button">
          <CloseIconButton handleClose={handleClose} />
        </div>
        <div className="body">
          <p className="b1-regular">
            <>
              Get our full brand asset kit for your media needs.
              <br />
              Email at{` `}
              <span
                className="link"
                onClick={() => {
                  window.open(DISCORD_URL, '_blank')?.focus();
                }}
              >
                Discord Channel
              </span>
              {` `}if you need anything else and we{`'`}ll look after you!
            </>
          </p>
          <div className="button-wrap">
            <a
              href={'https://prod-web-res.noox.world/BrandAsset_Noox.zip'}
              download="BrandAsset_Noox.zip"
            >
              <Button
                label="Download assets"
                buttonStyle={ButtonStyle.PRIMARY}
                buttonSize={ButtonSize.L}
                handleClick={() => {}}
                style={{
                  margin: 0,
                }}
              />
            </a>
          </div>
        </div>
      </div>
      <style jsx>{`
        .container {
        }
        p {
          margin-bottom: 24px;
        }
        .close-button {
          position: absolute;
          top: 40px;
          right: 40px;
        }
        .code {
          width: 488px;
          background: var(--BT15);
          border-radius: 16px;
          border: 1px solid var(--WT30);
          padding: 16px;
          margin-bottom: 30px;
          overflow: auto;
          max-height: 150px;
        }
        .button-wrap {
          display: flex;
          gap: 0px;
          justify-content: center;
        }
        span.link {
          text-decoration: underline;
          cursor: pointer;
        }
      `}</style>
    </>
  );
};

export default DownloadAssetModal;
