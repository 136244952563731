import { isObjectEmpty } from 'src/utils';
import { CollectionInfo } from './collection';
import { Pagination } from './common';

export enum Chain {
  Ethereum = 'Ethereum',
  Polygon = 'Polygon',
  Optimism = 'Optimism'
}

export enum SupportedNetworksChain {
  Ethereum = 'ETHEREUM',
  Polygon = 'POLYGON',
  Optimism = 'OPTIMISM',
  Arbitrum = 'ARBITRUM',
  Bnb = 'BNB',
}

export const ChainInfo = {
  [Chain.Ethereum]: {
    listIcon: 'ethereum-icon-white.png',
  },
  [Chain.Polygon]: {
    listIcon: 'polygon-icon-white.png',
  },
  [Chain.Optimism]: {
    listIcon: 'optimism-icon-white.png',
  },
};

export const SupportedNetworksChainInfo = {
  [SupportedNetworksChain.Ethereum]: {
    listIcon: 'ethereum-icon-white.png',
  },
  [SupportedNetworksChain.Polygon]: {
    listIcon: 'polygon-icon-white.png',
  },
  [SupportedNetworksChain.Optimism]: {
    listIcon: 'optimism-icon-white.png',
  },
  [SupportedNetworksChain.Arbitrum]: {
    listIcon: 'arbitrum-icon-white.png',
  },
  [SupportedNetworksChain.Bnb]: {
    listIcon: 'bnb-icon-white.png',
  },
};

export interface Badge {
  _idx: number;
  _tierLabel?: string;
  _id: string;
  id: string;
  name: string;
  nftName: string;
  categories: string[];
  chain: Chain;
  descriptionEligibility: string;
  imageUrl: string;
  imageThumbnailUrl: string;
  collections: CollectionInfo[];
  totalClaimed: number;
  publisher: {
    address: string;
    nickname: string;
    isCertified: boolean;
  };
  proposer?: {
    address: string;
    nickname: string;
    isCertified: boolean;
  };
  group:
    | {
        groupId: string;
        index: number;
        count: number;
        isTiered: boolean;
        isPrevLevelClaimable: boolean;
      }
    | Record<string, never>;
  claimedDate?: string;
  isVisible?: boolean;
  isClaimed?: boolean;
  _isPrimaryBadge?: boolean;
  claimedRevision?: number;
  revision: number;
  isLatestRevision: boolean;
  eligibilityCheck?: EligibilityCheck;
  supportedNetworks?: SupportedNetworksChain[];
}

export interface EligibilityCheck {
  result: boolean;
  actualActionValue: string;
  requiredActionValue: string;
}
export interface Criteria {
  category: string;
  criterion: string;
  eligibility_rules_url: string;
  project: string;
  project_url: string;
  req: CriteriaReq[];
  required_action: string;
}
export interface CriteriaReq {
  method: string;
  address: string;
}

export interface BadgeLogic {
  desc: string;
  descParams: string[];
  logicArgs: Record<string, any>;
}

export interface BadgeDetailed extends Badge {
  projects: string[];
  totalClaimed: number;
  attributes: Criteria;
  badgeLogicDesc: BadgeLogic;
  description: string;
  contractAddress: string;
  tokenStandard: string;
  eligibilityRulesUrl: string;
  uncles: Badge[];
  publishBH: number;
  deprecateBH?: number;
  _bhLabel: string;
  revisionHistory: {
    revision: number;
    badgeId: string;
    isLatestRevison: boolean;
  }[];
}

export const getVisibleBadge = (badges: Badge[]): Badge[] => {
  return badges.filter((badge) => badge.isVisible);
};

export const getBadgeTierInfo = (
  badge: Badge,
): Partial<Pick<Badge, '_tierLabel'>> => {
  if (!badge.group || isObjectEmpty(badge.group) || !badge.group.isTiered) {
    return {
      _tierLabel: undefined,
    };
  }

  return {
    _tierLabel: `Lvl ${badge.group.index + 1} of ${badge.group.count}`,
  };
};

export const changeIpfsUrl = (url: string) => {
  return url.replace('ipfs://', 'https://ipfs.io/ipfs/');
};

export const splitBadgeLogicDesc = (badgeLogicDesc: BadgeLogic) => {
  const splitDesc = badgeLogicDesc.desc?.split(' ') || [];
  return [
    splitDesc.slice(0, splitDesc.length - 2).join(' '),
    splitDesc[splitDesc.length - 2],
    badgeLogicDesc.descParams[0],
  ];
};

export const getBHLabel = (publishBH: number, deprecateBH?: number): string => {
  return `Block Height: #${publishBH}${
    deprecateBH ? ` ~ #${deprecateBH}` : ''
  }`;
};

export interface GetBadgesResponse extends Pagination {
  badges: Badge[];
}
