import React from 'react';
import ReactTooltip from 'react-tooltip';

export type TooltipDir = 'left' | 'top' | 'right' | 'bottom';

const Tooltip: React.FC<{
  direction: TooltipDir;
}> = ({ direction }) => {
  return (
    <>
      <ReactTooltip
        className="tooltip"
        place={direction}
        html={true}
        type="dark"
        effect="solid"
        backgroundColor="var(--P500)"
        textColor="white"
      />
      <style jsx global>{`
        .__react_component_tooltip {
          box-shadow: none !important;
          border: none;
          border-radius: 8px;
          max-width: 340px;
          line-height: 18px;
        }
        .__react_component_tooltip.show {
          opacity: 1 !important;
        }
      `}</style>
    </>
  );
};

export default Tooltip;
