export const API_CONST = {
  ROOT_URL:
    typeof window !== 'undefined'
      ? `${
          window.localStorage.getItem('temp_url') || null || process.env.API_URL
        }/`
      : `${process.env.API_URL}/`,
  ROOT_URL_V2:
    // typeof window !== 'undefined'
    //   ? `${
    //       window.localStorage.getItem('temp_url') ||
    //       null ||
    //       process.env.API_V2_URL
    //     }/`
    //   :
    `${process.env.API_URL_V2}/`,
  AUTH_API_URL: 'auth',
  PROFILE_API_URL: 'profile',
  COLLECTIONS_API_URL: 'collections',
  COLLECTION_ACHIEVEMENTS_API_URL: 'collection-achievements',
  BADGES_API_URL: 'badges',
  BADGE_GROUPS_API_URL: 'badge-groups',
  NOTABLE_BADGES_API_URL: 'notable-badges',
  USERS_API_URL: 'users',
  ELIGIBILITIES_API_URL: 'eligibilities',
  CLAIM_SIG_API_URL: 'claim-sig',
  USER_BADGES_API_URL: 'user-badges',
  NOTICES_API_URL: 'notices',
  BLOCKS_API_URL: 'blocks',
  ABIS_API_URL: 'abis',
  REPUTATIONS_API_URL: 'rep',
};

export const ROUTE = {
  HOME: '/',
  PROFILE: '/profile',
  PROJECTS: '/noox-for-projects',
  USER: '/user',
  BADGE: '/badge',
  USER_BADGE: '/', //'/user-badge',
  USER_BADGE_CREATE: 'create',
  USER_BADGE_EDIT: 'edit',
  COLLECTION: '/collection',
  COLLECTION_HASH_USERS: 'users',
  CLAIM: '/claim',
  SHARE: '/share',
  ERROR: '/error',
  DEBUG: '/debug',
  DEBUG_CHECK_ELIGIBLE_TX: 'check-eligible-tx',
  BROADCASTERS: '/broadcasters',
};

// export const TEST_ADDRESS =
//   'address=0x37c9a75a10d8d6d92f18bb85f25432b090971e5d&';
export const TEST_ADDRESS = '';

export const DISCOURSE_URL = 'https://discuss.noox.world/c/feedback-question/';
export const DISCORD_URL = 'https://discord.gg/noox';
export const MIRROR_URL =
  'https://mirror.xyz/noox.eth/bDBQnTDSk4_GFGO-a2w5iNZ74IuAmSi0YxolCKOaLO8';
export const DOCS_URL = 'https://docs.noox.world/';
export const INTRODUCING_NOOX_URL =
  'https://mirror.xyz/noox.eth/bhI8re02JtQQ78UbXi-4dLpmiuNtCVEfDymSd1XAYt4';

export const GA_TRACKING_ID = 'G-5NDN9MC5Y2';

export const SAMPLE_APPLICATION_ID = ['1', '2', '3'];
